import { Box, Stack, SxProps, Theme, Typography } from '@mui/material';
import CustomButton from 'Components/General/CustomButton';
import { BUTTON_TITLE, LESSON_TEXTS } from 'Constants/keys';
import BoxedContainer from 'Containers/BoxedContainer';
import { ReactComponent as GoldMascot1 } from 'Assets/Mascots/Lesson/GoldMascot1.svg';
import { ReactComponent as GoldMascot2 } from 'Assets/Mascots/Lesson/GoldMascot2.svg';
import { ReactComponent as GoldMascot3 } from 'Assets/Mascots/Lesson/GoldMascot3.svg';
import { ReactComponent as CompletionMascot1 } from 'Assets/Mascots/Lesson/CompletionMascot1.svg';
import { ReactComponent as CompletionMascot2 } from 'Assets/Mascots/Lesson/CompletionMascot2.svg';
import { ReactComponent as CompletionMascot3 } from 'Assets/Mascots/Lesson/CompletionMascot3.svg';
import CompletionSound from 'Assets/Sounds/Complete.wav';
import useSound from 'use-sound';
import { useEffect, useState } from 'react';

interface LessonResultProps {
  text?: string;
  result?: string;
}

const goldMascots = [<GoldMascot1 width={'100%'} />, <GoldMascot2 width={'100%'} />, <GoldMascot3 width={'100%'} />];
const mascots = [
  <CompletionMascot1 width={'100%'} />,
  <CompletionMascot2 width={'100%'} />,
  <CompletionMascot3 width={'100%'} />,
];

// This shows a result on the LessonEnd page, such as "Accuracy: 100%".
const LessonResult = ({ text, result }: LessonResultProps) => {
  return (
    <Box display="flex" width={1}>
      <Typography variant="lessonEndTitle" color="text.primary" textAlign={'left'}>
        {text}
      </Typography>
      <Typography variant="lessonEndTitle" color="success.light" sx={{ ml: 'auto' }}>
        {result}
      </Typography>
    </Box>
  );
};

export interface LessonEndProps {
  accuracy: number;
  xp: number;
  onAcknowledged: () => void;
  style?: SxProps<Theme>;
}

interface LessonEndParams {
  accuracy: string;
  xp: string;
  congratsTitle: string;
  congratulatoryMessage: string;
  mascot: JSX.Element;
}

const LessonEnd = ({ accuracy, xp, onAcknowledged, style }: LessonEndProps) => {
  const [playCompletionSound] = useSound(CompletionSound);

  const params = {} as LessonEndParams;
  const [lessonEndParams, setLessonEndParams] = useState<LessonEndParams>();

  useEffect(() => {
    playCompletionSound();
  });

  useEffect(() => {
    if (!xp) return;
    if (!accuracy) return;
    params.accuracy = Math.round(accuracy * 100) + LESSON_TEXTS.ACCURACY_SUFFIX;
    params.xp = '+' + xp + LESSON_TEXTS.XP_SUFFIX;
    params.congratsTitle = LESSON_TEXTS.LESSON_END_CONGRATULATIONS;
    params.congratulatoryMessage =
      LESSON_TEXTS.CONGRATULATORY_COMPLETION_TEXTS[
        Math.floor(Math.random() * LESSON_TEXTS.CONGRATULATORY_COMPLETION_TEXTS.length)
      ];
    params.mascot = getMascotForAccuracy(accuracy);
    setLessonEndParams(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accuracy, xp]);

  const getMascotForAccuracy = (accuracy: number) => {
    return accuracy === 1
      ? goldMascots[Math.floor(Math.random() * goldMascots.length)]
      : mascots[Math.floor(Math.random() * mascots.length)];
  };

  return (
    <BoxedContainer style={style}>
      <Stack spacing={{ xs: 4, mob: 10 }} alignItems="center" mx={{ xs: 5, mob: 10 }} height={'80vh'}>
        <Typography variant="h1" fontWeight="bold">
          {lessonEndParams && lessonEndParams?.congratsTitle}
        </Typography>

        <Typography variant="body1" color={'text.primary'}>
          {lessonEndParams && lessonEndParams?.congratulatoryMessage}
        </Typography>

        <Box sx={{ width: { xs: 200, mob: 260 } }}>{lessonEndParams && lessonEndParams.mascot}</Box>

        <LessonResult text={LESSON_TEXTS.ACCURACY} result={lessonEndParams && lessonEndParams.accuracy} />
        <LessonResult text={LESSON_TEXTS.EXPERIENCE_EARNED} result={lessonEndParams && lessonEndParams.xp} />

        <CustomButton
          title={BUTTON_TITLE.CONTINUE}
          onPress={onAcknowledged}
          style={{
            position: 'absolute',
            bottom: 24,
          }}
        ></CustomButton>
      </Stack>
    </BoxedContainer>
  );
};

export default LessonEnd;
