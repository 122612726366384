import { FormControl, FormControlLabel, Radio, RadioGroup, Stack, styled } from '@mui/material';
import { ExerciseState } from 'Enums/ExerciseEnums';
import LabelSelectionRadioIcon from './LabelSelectionRadioIcon';
import { ExerciseImage, ExerciseImageView } from '../ExerciseImageView';
import { useState } from 'react';

export interface LabelSelectionExerciseViewProps {
  exerciseImage: ExerciseImage;
  labels: string[];
  exerciseState: ExerciseState;
  correctChoiceIndex?: number;
  onLabelSelected: (selectedIndex: number) => void;
}

const StyledFormControlLabel = styled(FormControlLabel)(() => ({
  '.MuiFormControlLabel-label.Mui-disabled': {
    color: 'white',
  },
}));

const LabelSelectionExerciseView = ({
  exerciseImage,
  exerciseState,
  labels,
  correctChoiceIndex,
  onLabelSelected,
}: LabelSelectionExerciseViewProps) => {
  const [selectionIndex, setSelectionIndex] = useState<number>();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (exerciseState === ExerciseState.Correct || exerciseState === ExerciseState.Incorrect) return;
    const intIndex = parseInt((event.target as HTMLInputElement).value);
    onLabelSelected(intIndex);
    setSelectionIndex(intIndex);
  };

  return (
    <Stack spacing={0}>
      <ExerciseImageView key={'LabelSelection' + exerciseImage.imageS3Key} exerciseImage={exerciseImage} />
      <FormControl sx={{ ml: { xs: 12, mob: 16, tab: 32 } }}>
        <RadioGroup
          aria-labelledby="radio-buttons-group-label"
          name="radio-buttons-group"
          onChange={handleChange}
          sx={{ marginX: 8, marginTop: { xs: 4, mob: 10 } }}
        >
          {labels.map((item, index: number) => {
            let color = 'tertiary.contrastText';

            if (exerciseState === ExerciseState.Selected) {
              if (index === selectionIndex) {
                color = 'primary.main';
              }
            } else if (exerciseState === ExerciseState.Correct) {
              if (index === correctChoiceIndex) {
                color = 'success.dark';
              }
            } else if (exerciseState == ExerciseState.Incorrect) {
              if (index === correctChoiceIndex) {
                color = 'success.dark';
              } else if (index === selectionIndex) {
                color = 'lesson.one';
              }
            }

            const icon = (
              <LabelSelectionRadioIcon
                color={color}
                isSelected={index == selectionIndex}
                isCorrect={
                  exerciseState === ExerciseState.Selected || exerciseState === ExerciseState.NotSelected
                    ? false
                    : index === correctChoiceIndex
                }
                isIncorrect={
                  exerciseState === ExerciseState.Selected || exerciseState === ExerciseState.NotSelected
                    ? false
                    : selectionIndex === index && index !== correctChoiceIndex
                }
              />
            );

            return (
              <StyledFormControlLabel
                key={item}
                sx={{
                  marginBottom: { xs: 0.5, mob: 4 },
                }}
                value={index}
                disabled={exerciseState === ExerciseState.Incorrect || exerciseState === ExerciseState.Correct}
                control={
                  <Radio
                    icon={icon}
                    checkedIcon={icon}
                    sx={{
                      color: color,
                      '&.Mui-checked': {
                        color: color,
                      },
                    }}
                  />
                }
                label={item}
              />
            );
          })}
        </RadioGroup>
      </FormControl>
    </Stack>
  );
};

export default LabelSelectionExerciseView;
