import { useAuth0 } from '@auth0/auth0-react';
import { Box, Button, Typography, Container } from '@mui/material';
import Logo from 'Assets/Icons/PNG/hologic@2x.png';
import Email from 'Assets/Icons/PNG/email@2x.png';
import BackgroundImage from 'Assets/Images/Login-bg.png';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomButton from 'Components/General/CustomButton';
import LoadingView from 'Components/General/LoadingView';
import { EMAIL_VERIFICATION } from 'Constants/keys';
import { useVerifyEmailMutation } from 'graphql/generated/graphql';
import { useState } from 'react';

export interface EmailVerifyProps {
  email?: string;
  authToken?: string;
}

const EmailVerificationPage = () => {
  const { logout } = useAuth0();
  const [sendEmailVerification] = useVerifyEmailMutation();

  // 0 - No email resend attempt, 1 - Email resend Success, 2 - Email resend failure
  const [resendEmail, setResendEmail] = useState(0);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { email, authToken } = location.state as EmailVerifyProps;
  return (
    <Container disableGutters maxWidth="des" sx={{ minHeight: '100vh', backgroundImage: `url(${BackgroundImage})` }}>
      {loading ? (
        <Box height={'100vh'}>
          <LoadingView />
        </Box>
      ) : (
        <Box
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <Box
            flexDirection={'column'}
            pt={10}
            mt={10}
            mb={20}
            sx={{
              width: '89%',
              backgroundColor: 'tertiary.dark',
              borderRadius: 2.5,
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <Box component={'img'} src={Logo} alt="logo" width={250} />
            <Typography variant="subtitle2" color="white.main" sx={{ mr: 3, mt: 1 }}>
              {EMAIL_VERIFICATION.SUBTITLE}
            </Typography>
            <Typography mt={10} variant={'h1'}>
              {EMAIL_VERIFICATION.SECTION_TITLE}
            </Typography>
            <Box mt={8} component={'img'} src={Email} alt="logo" width={110} />
            <Typography mt={5}>{EMAIL_VERIFICATION.MESSAGE}</Typography>
            <Typography noWrap={true} textAlign={'center'} fontWeight={'bold'} mt={1} width={'80%'}>
              {email}
            </Typography>
            <Typography mx={10} mt={8} textAlign={'center'}>
              {EMAIL_VERIFICATION.REQUIRED_ACTION}
            </Typography>
            <Box my={10} mx={'10%'}>
              {/* Invisible button overlayed */}
              <Button
                disabled={resendEmail == 1}
                style={{ position: 'absolute', width: '60%' }}
                onClick={async () => {
                  setLoading(true);
                  await sendEmailVerification({
                    variables: {
                      clientId: String(authToken),
                    },
                  })
                    .then((response) => {
                      if (response?.data?.sendEmailVerification) setResendEmail(1);
                      else setResendEmail(2);
                      setLoading(false);
                    })
                    .catch((error) => {
                      setResendEmail(2);
                      console.log(error.message);
                      setLoading(false);
                    });
                }}
              />
              <Typography fontWeight={'300'} variant="subtitle2" display="inline">
                {
                  {
                    0: EMAIL_VERIFICATION.EMAIL_ERROR,
                    1: EMAIL_VERIFICATION.RESEND_SUCCESS,
                    2: EMAIL_VERIFICATION.RESEND_ERROR,
                  }[resendEmail]
                }
              </Typography>
              <Typography fontWeight={'600'} variant="subtitle2" display="inline">
                {
                  {
                    0: EMAIL_VERIFICATION.RESEND_EMAIL,
                    1: '',
                    2: EMAIL_VERIFICATION.RESEND_RETRY,
                  }[resendEmail]
                }
              </Typography>
            </Box>

            <CustomButton style={{ mt: 8 }} title={'RETURN HOME'} type="outlined" onPress={() => navigate(-1)} />

            <Box my={8}>
              {/* Invisible button overlayed */}
              <Button
                style={{ margin: 8, position: 'absolute', width: '60%' }}
                onClick={() => {
                  logout({ returnTo: window.location.origin });
                }}
              />
              <Typography fontWeight={'300'} variant="subtitle2" display="inline">
                {EMAIL_VERIFICATION.WRONG_USER}
              </Typography>
              <Typography fontWeight={'600'} variant="subtitle2" display="inline">
                {EMAIL_VERIFICATION.CHANGE_USER}
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
    </Container>
  );
};

export default EmailVerificationPage;
