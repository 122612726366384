import Link from '@mui/material/Link';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATHS } from 'Routes/RouteKeys';

export interface FooterLinkProps {
  title?: string;
  route?: string;
}

export default function FooterLink({ title, route }: FooterLinkProps) {
  const navigate = useNavigate();
  return (
    <Link
      variant="caption"
      textAlign="center"
      color="tertiary.contrastText"
      onClick={() => {
        navigate(ROUTE_PATHS.ROOT + route ?? '#');
      }}
      underline="none"
      sx={{ cursor: 'pointer' }}
    >
      {title}
    </Link>
  );
}
