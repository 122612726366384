import StaticContent from 'Components/StaticContent';
import { STATIC_CONTENT } from 'Constants/keys';
import { TERMS_BODY } from 'Constants/longKeys';
import BoxedContainer from 'Containers/BoxedContainer';
import { useContext } from 'react';
import SplashContext from 'Context/SplashContext';

const TermsPage = () => {
  const splashContext = useContext(SplashContext);
  splashContext?.setShowSplash(false);
  return (
    <BoxedContainer justifyTop applyInsetTop>
      <StaticContent title={STATIC_CONTENT.TERMS_TITLE} body={TERMS_BODY} />
    </BoxedContainer>
  );
};

export default TermsPage;
