import StaticContent from 'Components/StaticContent';
import { EMPTY_STRING, STATIC_CONTENT } from 'Constants/keys';
import BoxedContainer from 'Containers/BoxedContainer';
import { useEffect, useState } from 'react';
import { useContext } from 'react';
import SplashContext from 'Context/SplashContext';
import ErrorScreen from 'Screens/ErrorScreen';
import LoadingView from 'Components/General/LoadingView';

// Since the license text is large, store it in a text file that is
// fetched on loading the page.
const fetchLicense = async () => {
  const result = await fetch(`${process.env.PUBLIC_URL}/text/license.txt`);

  if (!result.ok) {
    throw result;
  }
  return result.text();
};

const LicensePage = () => {
  const [licenseText, setLicenseText] = useState(EMPTY_STRING);
  const [fetchError, setFetchError] = useState(false);
  const splashContext = useContext(SplashContext);
  useEffect(() => {
    fetchLicense()
      .then((licenceText) => {
        setLicenseText(licenceText);
        setFetchError(false);
      })
      .catch((reason) => {
        console.log(reason);
        setFetchError(true);
      });
  }, []);

  splashContext?.setShowSplash(false);

  if (fetchError) {
    return <ErrorScreen pageTitle={STATIC_CONTENT.LICENSE_TITLE} />;
  }

  if (licenseText === EMPTY_STRING) {
    return (
      <BoxedContainer justifyTop applyInsetTop>
        <LoadingView />
      </BoxedContainer>
    );
  }

  return (
    <BoxedContainer justifyTop applyInsetTop>
      {/* Set whiteSpace property to insert line breaks where present in text.*/}
      <StaticContent title={STATIC_CONTENT.LICENSE_TITLE} body={licenseText} textStyle={{ whiteSpace: 'pre-line' }} />
    </BoxedContainer>
  );
};

export default LicensePage;
