import { Box, Typography } from '@mui/material';
import { TEXT } from 'Constants/keys';
import { useEffect } from 'react';
import OoliGIF from 'Assets/Mascots/Mascot-Loading.gif';

const SplashScreen = () => {
  useEffect(() => {
    // After the component renders, prevent the user from scrolling.
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'scroll';
    };
  });
  return (
    <Box
      bgcolor="primary.appBackground"
      flexDirection="column"
      display="flex"
      height="100vh"
      width="100vw"
      justifyContent="center"
      alignItems="center"
    >
      <img src={OoliGIF} style={{ alignSelf: 'center', width: 260 }} />
      <Typography variant="h1" mt={4} color="white.main">
        {TEXT.WAIT}
      </Typography>
    </Box>
  );
};

export default SplashScreen;
