import LoadingButton from '@mui/lab/LoadingButton';
import { CircularProgress } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';

export interface DefaultButtonProps {
  title?: string;
  style?: SxProps<Theme>;
  type?: 'contained' | 'outlined' | 'text';
  color?: 'inherit' | 'primary' | 'secondary' | 'error' | 'success' | 'white' | 'warning' | 'info';
  size?: 'small' | 'medium' | 'large';
  disabled?: boolean;
  loading?: boolean;
  onPress(): void;
}

export default function CustomButton({
  title,
  style,
  color,
  type,
  size,
  disabled,
  onPress,
  loading,
}: DefaultButtonProps) {
  return (
    <LoadingButton
      disabled={disabled}
      variant={type ? type : 'contained'}
      size={size ? size : 'large'}
      // default color is white for outlined buttons, primary for others.
      color={color ? color : type === 'outlined' ? 'white' : 'primary'}
      loading={loading}
      loadingIndicator={<CircularProgress color={color} size={24} />}
      onClick={onPress}
      sx={{
        borderRadius: '16px',
        borderWidth: type === 'outlined' ? '2px' : '1px',
        borderColor: 'white.main',
        maxWidth: 239,
        maxHeight: 56,
        height: window.innerWidth * 0.12,
        width: window.innerWidth * 0.61,
        alignSelf: 'center',
        ...style,
      }}
    >
      {title}
    </LoadingButton>
  );
}
