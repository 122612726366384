import Grid from '@mui/material/Unstable_Grid2';
import { ExerciseState } from 'Enums/ExerciseEnums';
import { useState } from 'react';
import { ExerciseImage } from '../ExerciseImageView';
import { ImageSelectionItem } from './ImageSelectionItem';

export interface ImageSelectionExerciseViewProps {
  exerciseImages: ExerciseImage[];
  exerciseState: ExerciseState;
  correctChoiceIndex?: number;
  onImageSelected: (selectedIndex: number) => void;
}

export default function ImageSelectionExerciseView({
  exerciseImages,
  exerciseState,
  correctChoiceIndex,
  onImageSelected,
}: ImageSelectionExerciseViewProps) {
  const [selectionIndex, setSelectionIndex] = useState<number>();

  const isLargerMobile = window.innerWidth > 400;
  return (
    <Grid
      container
      columnSpacing={{ xs: 0, mbs: -12, mob: isLargerMobile ? -30 : -20, tab: -64 }}
      rowSpacing={{ xs: 4, mbs: 3, mob: isLargerMobile ? 3 : 4, tab: 4 }}
    >
      {exerciseImages.map((item, index: number) => (
        <Grid key={exerciseImages[index].imageS3Key} xs={6} display="flex" justifyContent="center" alignItems="center">
          <ImageSelectionItem
            key={exerciseImages[index].imageS3Key}
            exerciseImage={exerciseImages[index]}
            isSelected={index == selectionIndex}
            isCorrect={
              exerciseState === ExerciseState.Selected || exerciseState === ExerciseState.NotSelected
                ? false
                : index === correctChoiceIndex
            }
            isIncorrect={
              exerciseState === ExerciseState.Selected || exerciseState === ExerciseState.NotSelected
                ? false
                : selectionIndex === index && index !== correctChoiceIndex
            }
            onClick={() => {
              if (exerciseState === ExerciseState.Correct || exerciseState === ExerciseState.Incorrect) return;
              onImageSelected(index);
              setSelectionIndex(index);
            }}
          />
        </Grid>
      ))}
    </Grid>
  );
}
