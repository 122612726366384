import { Paper, Box, Stack, Typography } from '@mui/material';
import CustomButton from 'Components/General/CustomButton';
import LoadingView from 'Components/General/LoadingView';
import { ACHIEVEMENT_TEXTS, BUTTON_TITLE } from 'Constants/keys';
import { useStreakPopUpSeenMutation } from 'graphql/generated/graphql';
import StreakDisplay from './StreakDisplay';
import ShowSnack from 'Util/SnackbarUtilsConfigurator';

export interface StreakContinuedProps {
  streak: number;
  onContinueClicked: () => void;
  isLoading?: boolean;
}

// StreakContinued is shown when the user has just completed a lesson and continued their streak.
const StreakContinued = ({ streak, onContinueClicked, isLoading }: StreakContinuedProps) => {
  const [streakSeen, { loading: streakSeenLoading }] = useStreakPopUpSeenMutation();
  //Required check when the breakpoint is mob, but the width is less than 375.
  const isSmallerMobile = window.innerWidth <= 375;
  //Required check when the breakpoint is mbs, less than mob, but the width is greater than 360.
  const isMediumMobile = window.innerWidth >= 360;
  return (
    <Paper
      sx={{
        backgroundColor: 'secondary.dark',
        width: { xs: '87vw', mob: '87vw', tab: 341 },
        height: { xs: '85vh', mbs: isMediumMobile ? '75vh' : '85vh', mob: isSmallerMobile ? '85vh' : '71vh', tab: 650 },
      }}
    >
      {streakSeenLoading ? (
        <LoadingView style={{ height: '100%' }} />
      ) : (
        <Box width={1} height={1} justifyContent={'center'} display="flex">
          <Stack mx={{ xs: 6, mob: isSmallerMobile ? 4 : 8 }} alignItems={'center'} justifyContent={'top'}>
            <Typography
              fontSize="2rem"
              lineHeight="2.6875rem"
              fontWeight="bold"
              mt={{ xs: 4, mob: 10 }}
              mb={{ xs: 2, mob: 12 }}
              sx={{ textAlign: 'center' }}
            >
              {ACHIEVEMENT_TEXTS.STREAK_CONTINUED}
            </Typography>
            <StreakDisplay days={streak} lessonCompletedToday={true} isLarge />
            <Typography variant="body1" color="text.secondary" mt={8} sx={{ textAlign: 'center' }}>
              {ACHIEVEMENT_TEXTS.ONGOING_STREAK_DESCRIPTION}
            </Typography>
            <CustomButton
              loading={isLoading}
              title={BUTTON_TITLE.CONTINUE}
              onPress={async () => {
                await streakSeen()
                  .then(() => {
                    console.log('Closing streak modal.');
                    onContinueClicked();
                  })
                  .catch((error) => {
                    console.log(error.message);
                    ShowSnack.error(error.message);
                  });
              }}
              style={{
                marginTop: 'auto',
                marginBottom: 10,
              }}
            />
          </Stack>
        </Box>
      )}
    </Paper>
  );
};

export default StreakContinued;
