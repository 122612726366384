import { useAuth0 } from '@auth0/auth0-react';
import { Stack, Typography } from '@mui/material';
import BoxedContainer from 'Containers/BoxedContainer';
import CustomButton from 'Components/General/CustomButton';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { ROUTE_PATHS } from 'Routes/RouteKeys';
import { BLANK_URL, BUTTON_TITLE, GAME_ELEMENT } from 'Constants/keys';
import TitleBar from 'Components/General/TitleBar';
import { Settings } from '@mui/icons-material';
import SettingsPage from 'Screens/Home/Tabs/Profile/SettingsPage';
import StyledAvatar from 'Components/Avatar/StyledAvatar';
import { AvatarVariant } from 'graphql/generated/graphql';
import SplashContext from 'Context/SplashContext';

import { Profile } from 'graphql/generated/graphql';
import { useContext, useEffect, useState } from 'react';

export interface LoggedInUser {
  userId?: string;
  idToken?: string;
  profile?: Profile;
  email?: string;
}

const ProfilePage = () => {
  const { logout } = useAuth0();
  const navigate = useNavigate();
  const [loggedInUser, setLoggedInUser] = useState<LoggedInUser | undefined>(undefined);

  useEffect(() => {
    const localUser = window.localStorage.getItem(GAME_ELEMENT.LOGGED_IN_USER);
    if (localUser) {
      setLoggedInUser(JSON.parse(localUser));
    }
  }, []);

  const userProfile = loggedInUser?.profile;

  const splashContext = useContext(SplashContext);
  return (
    <Routes>
      <Route path={ROUTE_PATHS.SETTINGS + ROUTE_PATHS.WILDCARD} element={<SettingsPage />} />
      <Route
        path={ROUTE_PATHS.BLANK}
        element={
          <BoxedContainer justifyTop={true}>
            <TitleBar
              title="Profile"
              icon={<Settings sx={{ fontSize: '2rem' }} />}
              iconColor="white"
              buttonAction={() => {
                navigate(ROUTE_PATHS.SETTINGS);
              }}
            />
            <Stack mx={7} mt={6} spacing={6} alignItems="center">
              <StyledAvatar
                username={userProfile?.username ? userProfile?.username : loggedInUser?.email}
                avatarVariant={userProfile?.avatarVariant ?? AvatarVariant.LightInitial}
                url={userProfile?.avatarUrl ?? BLANK_URL}
                size="lg"
              />

              <Stack spacing={1} alignItems="center">
                <Typography color="text.secondary" variant="h2" textOverflow={'ellipsis'} textAlign="justify">
                  {userProfile?.username}
                </Typography>
                <Typography color="tertiary.contrastText" variant="body2" textOverflow={'ellipsis'} textAlign="justify">
                  {userProfile?.institution}
                </Typography>
                <Typography
                  color="text.secondary"
                  variant="body1"
                  textOverflow={'ellipsis'}
                  textAlign="center"
                  noWrap
                  width="90vw"
                >
                  {loggedInUser?.email}
                </Typography>
              </Stack>

              <CustomButton
                type="outlined"
                title={BUTTON_TITLE.EDIT_PROFILE}
                onPress={() => {
                  navigate(ROUTE_PATHS.PRIVATE_ROOT + ROUTE_PATHS.EDIT_PROFILE);
                }}
              />

              <CustomButton
                type="outlined"
                title={BUTTON_TITLE.LOGOUT}
                onPress={() => {
                  splashContext?.setShowSplash(true);
                  window.localStorage.removeItem(GAME_ELEMENT.LOGGED_IN_USER);
                  logout({ returnTo: window.location.origin });
                }}
              />
            </Stack>
          </BoxedContainer>
        }
      />
    </Routes>
  );
};

export default ProfilePage;
