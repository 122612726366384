import { Card, Stack, SvgIcon, SxProps, Theme, Typography } from '@mui/material';
import { ReactComponent as Correct } from 'Assets/Icons/SVG/radio-correct.svg';
import { ReactComponent as Incorrect } from 'Assets/Icons/SVG/radio-incorrect.svg';
import { useDrag } from 'react-dnd';

interface CriteriaItemProps {
  text: string;
  isSelected: boolean;
  isCorrect?: boolean;
  isChoice?: boolean;
  isIncorrect?: boolean;
  isDragPreview?: boolean;
  onClick?: () => void;
  style?: SxProps<Theme>;
}

export const ItemTypes = {
  Criteria: 'Criteria',
};

export type DragCriteriaContent = {
  criteria: string;
};

const CriteriaItem = ({
  text,
  isChoice,
  isSelected,
  isCorrect,
  isDragPreview,
  isIncorrect,
  onClick,
  style,
}: CriteriaItemProps) => {
  //Configure drag item
  const [collectedProps, drag] = useDrag(
    () => ({
      type: ItemTypes.Criteria,
      item: { criteria: text },
      collect: (monitor) => {
        return {
          isDragging: monitor.isDragging(),
        };
      },
    }),
    [text]
  );

  return (
    <Card
      elevation={isSelected || isDragPreview ? 0 : 1}
      sx={{
        width: 1,
        height: 40,
        backgroundColor: isDragPreview ? 'transparent' : 'exercise.dark',
        borderRadius: 1,
      }}
      onClick={onClick}
    >
      {!isSelected && (
        <Card
          variant={'outlined'}
          key={text}
          id={text}
          ref={drag}
          sx={{
            width: 1,
            height: 1,
            borderWidth: isChoice ? 1 : 0,
            borderColor: isCorrect ? 'success.dark' : isIncorrect ? 'error.dark' : 'lesson.dark',
            backgroundColor: 'exercise.criteriaOption',
            borderRadius: 1,
            //Toggle opacity when the item is beign dragged/stops being dragged
            opacity: collectedProps.isDragging ? 0 : 1,
            ...style,
          }}
        >
          <Stack
            spacing={1}
            direction={'row'}
            height={1}
            width={1}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            sx={{
              overflow: 'hidden',
              backgroundColor: isCorrect
                ? 'exercise.criteriaCorrect'
                : isIncorrect
                ? 'exercise.criteriaIncorrect'
                : isChoice
                ? 'exercise.criteria'
                : 'exercise.criteriaOption',
            }}
          >
            {isCorrect || isIncorrect ? (
              <SvgIcon sx={{ fontSize: '1rem', ml: 2.5 }} component={isCorrect ? Correct : Incorrect} />
            ) : null}
            <Typography
              width={1}
              textAlign={'center'}
              sx={{
                cursor: 'pointer',
                userSelect: 'none',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: '2',
                WebkitBoxOrient: 'vertical',
              }}
              variant={'exerciseResult'}
              color="white"
            >
              {text}
            </Typography>
          </Stack>
        </Card>
      )}
    </Card>
  );
};

export default CriteriaItem;
