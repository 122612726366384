import { Paper, Box, Stack, Typography } from '@mui/material';
import CustomButton from 'Components/General/CustomButton';
import LoadingView from 'Components/General/LoadingView';
import { ACHIEVEMENT_TEXTS, BUTTON_TITLE } from 'Constants/keys';
import { Badge, useBadgeSeenMutation } from 'graphql/generated/graphql';
import AchievementBadge from './AchievementBadge';
import BadgeData from './BadgeData';
import ShowSnack from 'Util/SnackbarUtilsConfigurator';

export interface AchievementUnlockedProps {
  badge?: Badge;
  onContinueClicked: () => void;
}

const AchievementUnlocked = ({ badge, onContinueClicked }: AchievementUnlockedProps) => {
  const [badgeSeen, { loading: badgeSeenLoading }] = useBadgeSeenMutation();
  //Required check when the breakpoint is mob, but the width is less than 375.
  const isSmallerMobile = window.innerWidth <= 375;
  //Required check when the breakpoint is mbs, less than mob, but the width is greater than 360.
  const isMediumMobile = window.innerWidth >= 360;
  function getBadgeDescription(badge: Badge) {
    const level = badge.level;
    const isMaxLevel = badge.level >= badge.levelThresholds.length;
    let descriptionText = isMaxLevel
      ? BadgeData[badge.type].descriptions.maxUnlocked
      : BadgeData[badge.type].descriptions.unlocked;
    const value = badge.levelThresholds[level - 1];
    const suffix = isMaxLevel
      ? BadgeData[badge.type].descriptions.maxUnlockedSuffix
      : BadgeData[badge.type].descriptions.unlockedSuffix;
    descriptionText = descriptionText + value + suffix;
    return descriptionText;
  }

  return (
    <Paper
      sx={{
        backgroundColor: 'secondary.dark',
        width: { xs: '87vw', mob: '87vw', tab: 341 },
        height: { xs: '85vh', mbs: isMediumMobile ? '75vh' : '85vh', mob: isSmallerMobile ? '85vh' : '71vh', tab: 650 },
      }}
    >
      {badgeSeenLoading ? (
        <LoadingView style={{ height: '100%' }} />
      ) : (
        <Box width={1} height={1} justifyContent={'center'} display="flex">
          {badge && (
            <Stack mx={{ xs: 6, mbs: 12 }} alignItems={'center'} justifyContent={'top'}>
              <Typography variant="h2" color="text.secondary" mt={{ xs: 4, mob: 10 }} sx={{ textAlign: 'center' }}>
                {ACHIEVEMENT_TEXTS.CONGRATULATIONS}
              </Typography>
              <Typography variant="h2" color="text.secondary" mt={{ xs: 1, mob: 2 }} sx={{ textAlign: 'center' }}>
                {ACHIEVEMENT_TEXTS.BADGE_PREFIX}
              </Typography>
              <Typography
                variant="lessonEndTitle"
                color="text.primary"
                mt={{ xs: 1, mbs: 2 }}
                sx={{ textAlign: 'center' }}
              >
                {BadgeData[badge.type].displayName + ACHIEVEMENT_TEXTS.BADGE_SUFFIX}
              </Typography>
              <AchievementBadge
                style={{
                  marginTop: { xs: 1, mbs: 8, mob: 2 },
                  marginBottom: { xs: 2, mbs: 8, mob: 11 },
                  width: { xs: 175, mob: 225 },
                  height: { xs: 175, mob: 225 },
                }}
                badge={badge}
                isSmall={false}
              />
              <Typography variant="body1" color="text.secondary" sx={{ textAlign: 'center' }}>
                {getBadgeDescription(badge)}
              </Typography>
              <CustomButton
                loading={badgeSeenLoading}
                title={BUTTON_TITLE.CONTINUE}
                onPress={async () => {
                  await badgeSeen({
                    variables: {
                      badgeId: badge.badgeDefinitionId,
                    },
                  })
                    .then((response) => {
                      if (response?.data?.badgeSeen) {
                        onContinueClicked();
                      }
                    })
                    .catch((error) => {
                      ShowSnack.error(error.message);
                    });
                }}
                style={{ marginTop: 'auto', marginBottom: { xs: 10, mob: isSmallerMobile ? 6 : 10 } }}
              />
            </Stack>
          )}
        </Box>
      )}
    </Paper>
  );
};

export default AchievementUnlocked;
