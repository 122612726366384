import { useAuth0 } from '@auth0/auth0-react';
import BrokenImageRoundedIcon from '@mui/icons-material/BrokenImageRounded';
import { Box, Fade, Skeleton, SxProps, Theme, useTheme } from '@mui/material';
import { ExerciseImage } from 'Components/Exercise/ExerciseImageView';
import { useState, useCallback } from 'react';
import { presignUrl } from 'Util/presigner';
import Image from 'react-image-enlarger';
import { useLongPress, LongPressDetectEvents } from 'use-long-press';
import { timeout } from 'Util/delay';

export interface ImageBoxProps {
  exerciseImage: ExerciseImage;
  style?: SxProps<Theme>;
  onClick?: () => void;
}

const ImageBox = ({ exerciseImage, style, onClick }: ImageBoxProps) => {
  const theme = useTheme();
  const { getAccessTokenSilently } = useAuth0();
  const maxRetries = 2;
  const [retryCount, setRetryCount] = useState(0);
  const [load, setLoad] = useState(false);
  const [error, setError] = useState(false);
  const [signedImage, setSignedImage] = useState(exerciseImage.signedImageUrl);
  const [zoomed, setZoomed] = useState(false);
  const [resetTouch, setResettingTouch] = useState(false);

  // Prevent closing of zoom on long press release
  const [canClose, setCanClose] = useState(true);
  const handleLoad = () => {
    setLoad(true);
  };

  const handleZoom = async () => {
    // Reset user touch prior to zoomed image opening. This prevents issue where touch is never deregistered on image
    setResettingTouch(true);
    await timeout(50);
    setResettingTouch(false);
    setZoomed(true);
  };

  const callback = useCallback(() => {
    handleZoom();
  }, []);

  const bind = useLongPress(callback, {
    onStart: () => setCanClose(false),
    onFinish: async () => {
      // Introduce delay to prevent instant closure on release of long press
      await timeout(100);
      setCanClose(true);
    },
    threshold: 400,
    captureEvent: true,
    cancelOnMovement: false,
    detect: LongPressDetectEvents.BOTH,
  });

  const handleError = async () => {
    if (retryCount !== maxRetries) {
      const token = await getAccessTokenSilently();
      const newSignedImage = await presignUrl(exerciseImage.imageS3Key, token);
      if (newSignedImage) {
        console.log(retryCount, exerciseImage.imageS3Key, signedImage);
        setSignedImage(newSignedImage);
      }
      setRetryCount(retryCount + 1);
    } else {
      setError(true);
    }
  };

  //This defines how long the fade runs for.
  //Larger values will cause the fade transition to run longer.
  const fadeInTimeout = 300;

  const fallbackLoading = !load && !error && (
    <Skeleton
      animation={'wave'}
      sx={{ backgroundColor: 'exercise.criteriaOption', borderRadius: 2 }}
      variant={'rounded'}
      height={'100%'}
      width={'100%'}
    />
  );

  const fallbackError = error && (
    <Box
      sx={{
        bgcolor: 'exercise.criteriaOption',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...style,
      }}
    >
      <BrokenImageRoundedIcon sx={{ fontSize: '3rem' }} />
    </Box>
  );

  return (
    <Box
      sx={{
        position: 'relative',
        overflow: 'hidden',
        '& > *': {
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        },
        ...style,
      }}
    >
      <Fade in={load} timeout={fadeInTimeout}>
        <Box onLoad={handleLoad} onError={handleError} sx={{ maxWidth: '100%', height: 'auto', width: '100%' }}>
          <Image
            draggable={false}
            overlayColor={theme.palette.primary.appBackground}
            {...bind()}
            style={{
              pointerEvents: resetTouch ? 'none' : 'auto',
              WebkitTouchCallout: 'none',
              WebkitUserSelect: 'none',
              userSelect: 'none',
              maxWidth: '100%',
              height: 'auto',
              width: '100%',
            }}
            zoomed={zoomed}
            src={signedImage}
            onClick={() => {
              if (onClick) onClick();
            }}
            onRequestClose={() => {
              if (canClose) {
                setZoomed(false);
              }
            }}
          />
        </Box>
      </Fade>
      {fallbackLoading}
      {fallbackError}
    </Box>
  );
};

export default ImageBox;
