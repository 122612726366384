import BoxedContainer from 'Containers/BoxedContainer';
import TitleBar from 'Components/General/TitleBar';
import { ACHIEVEMENT_TEXTS, TABS } from 'Constants/keys';
import { Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useAchievementsQuery } from 'graphql/generated/graphql';
import AchievementPanel from 'Components/Achievements/AchievementPanel';
import LoadingView from 'Components/General/LoadingView';
import AchievementListItem from 'Components/Achievements/AchievementListItem';
import LessonCompletedToday from 'Util/DateFunctions';
import ErrorScreen from 'Screens/ErrorScreen';
import { NetworkStatus } from '@apollo/client';

const AchievementsPage = () => {
  const timezoneIdentifier = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const {
    data: achievementsData,
    loading,
    error,
    refetch,
    networkStatus,
  } = useAchievementsQuery({
    fetchPolicy: 'network-only',
    variables: { timezoneOffset: timezoneIdentifier },
    notifyOnNetworkStatusChange: true,
  });

  if (loading || networkStatus === NetworkStatus.refetch) {
    return (
      <BoxedContainer justifyTop={true} style={{ marginBottom: 16 }}>
        <TitleBar title={TABS.ACHIEVEMENTS} hideIcon />
        <LoadingView />
      </BoxedContainer>
    );
  }

  if (error) {
    return <ErrorScreen pageTitle={TABS.ACHIEVEMENTS} message={error.message} onRetry={refetch} />;
  }

  const achievements = achievementsData?.achievements;

  if (!achievements) {
    const message = 'Got undefined/null achievements object.';
    return <ErrorScreen pageTitle={TABS.ACHIEVEMENTS} message={message} onRetry={refetch} />;
  }

  let lessonCompletedToday: boolean;

  try {
    lessonCompletedToday = LessonCompletedToday(achievements.latestLessonCompletionTime);
  } catch (error) {
    return <ErrorScreen pageTitle={TABS.ACHIEVEMENTS} onRetry={refetch} />;
  }

  return (
    <BoxedContainer justifyTop={true} style={{ marginBottom: 16 }}>
      <TitleBar title={TABS.ACHIEVEMENTS} hideIcon />
      <Stack display={'flex'}>
        <Stack ml={1} mr={1} spacing={1} direction="row" justifyContent="space-evenly">
          <AchievementPanel achievementType="points" achievementCount={achievements.experiencePoints} />
          <AchievementPanel
            achievementType="streak"
            achievementCount={achievements.currentStreak}
            lessonCompletedToday={lessonCompletedToday}
          />
        </Stack>

        <Typography variant="h3" color="text.secondary" mt={4} mb={1} mx={4} textAlign={'left'} alignSelf={'left'}>
          {ACHIEVEMENT_TEXTS.MY_BADGES}
        </Typography>

        <Grid container width={0.9} alignSelf={'center'}>
          {achievements.badges.map((badge, index: number) => (
            <Grid key={index} xs={12} mb={2}>
              <AchievementListItem badge={badge} />
            </Grid>
          ))}
        </Grid>
      </Stack>
    </BoxedContainer>
  );
};

export default AchievementsPage;

