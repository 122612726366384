import { Card, CardActionArea, SvgIcon, SxProps, Theme } from '@mui/material';
import ImageBox from 'Components/General/ImageBox';
import { ReactComponent as Correct } from 'Assets/Icons/SVG/radio-correct.svg';
import { ReactComponent as Incorrect } from 'Assets/Icons/SVG/radio-incorrect.svg';
import { ExerciseImage } from '../ExerciseImageView';

export interface ImageSelectionItemProps {
  isSelected: boolean;
  isCorrect?: boolean;
  isIncorrect?: boolean;
  exerciseImage: ExerciseImage;
  onClick: () => void;
  style?: SxProps<Theme>;
}
export function ImageSelectionItem({
  isSelected,
  isCorrect,
  isIncorrect,
  exerciseImage,
  onClick,
  style,
}: ImageSelectionItemProps) {
  let borderColor = 'primary.main';
  let borderWidth = 1;

  if (isSelected) {
    if (isCorrect) {
      borderColor = 'success.dark';
      borderWidth = 4;
    } else if (isIncorrect) {
      borderColor = 'lesson.one';
      borderWidth = 4;
    } else {
      borderColor = 'primary.main';
      borderWidth = 4;
    }
  } else {
    if (isCorrect) {
      borderColor = 'success.dark';
      borderWidth = 4;
    } else {
      borderColor = 'lesson.locked';
      borderWidth = 1;
    }
  }

  return (
    <Card
      variant="outlined"
      sx={{
        borderWidth: borderWidth,
        borderColor: borderColor,
        height: { xs: 142, mob: 142, tab: 180 },
        width: { xs: 142, mob: 142, tab: 180 },
        borderRadius: 2.5,
        ...style,
      }}
    >
      <CardActionArea sx={{ height: 1, width: 1 }}>
        <ImageBox style={{ height: 1, width: 1 }} exerciseImage={exerciseImage} onClick={onClick} />
        {(isCorrect || isIncorrect) && (
          <SvgIcon
            sx={{ fontSize: '1.5rem', position: 'absolute', bottom: 2, right: 2 }}
            component={isCorrect ? Correct : Incorrect}
          />
        )}
      </CardActionArea>
    </Card>
  );
}
