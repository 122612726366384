import { Box } from '@mui/material';
import { ExerciseState } from 'Enums/ExerciseEnums';
import CriteriaItem, { DragCriteriaContent, ItemTypes } from './CriteriaItem';
import { SxProps, Theme } from '@mui/material/styles';
import { useDrop } from 'react-dnd';

interface SelectedCriteriaAreaProps {
  lastSelectedCriteria: string;
  exerciseState: ExerciseState;
  isCandidate?: boolean;
  isCorrect?: boolean;
  isIncorrect?: boolean;
  isImageMatchCriteria?: boolean;
  onDeselected: (item: string) => void;
  onDropped: (item: string) => void;
  style?: SxProps<Theme>;
}

export const SelectedCriteriaArea = ({
  lastSelectedCriteria,
  exerciseState,
  isCandidate,
  isCorrect,
  isIncorrect,
  onDeselected,
  onDropped,
  style,
}: SelectedCriteriaAreaProps) => {
  //Setup drop area
  const [collectedProps, drop] = useDrop<DragCriteriaContent, void, { isOver: boolean; canDrop: boolean }>({
    accept: ItemTypes.Criteria,
    drop: (item) => onDropped(item.criteria),
    // The dragging monitor
    collect: (monitor) => {
      return {
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      };
    },
  });

  //Identify if an item is attempting to be dropped on the drop area
  const isActive = collectedProps.isOver && collectedProps.canDrop;

  const mStyle = {
    borderRadius: 1,
    border: '2px dashed',
    borderColor: isActive ? 'primary.dark' : 'lesson.dark',
    backgroundColor: isCandidate || isActive ? 'exercise.matchCriteriaCandidate' : 'exercise.matchOptionHolder',
    ...style,
  };

  return (
    <Box
      ref={drop}
      width={175}
      height={40}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      component="span"
      sx={lastSelectedCriteria ? style : mStyle}
    >
      {lastSelectedCriteria && (
        <CriteriaItem
          text={lastSelectedCriteria}
          isSelected={false}
          isChoice={true}
          isCorrect={isCorrect}
          isIncorrect={isIncorrect}
          onClick={() => {
            if (exerciseState === ExerciseState.Selected || exerciseState === ExerciseState.NotSelected) {
              onDeselected(lastSelectedCriteria);
            }
          }}
        />
      )}
    </Box>
  );
};
