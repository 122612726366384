import { Stack, SxProps, Theme, Typography } from '@mui/material';
import TitleBar from './General/TitleBar';
import DOMPurify from 'isomorphic-dompurify';

export interface StaticContentProps {
  title: string;
  body: string;
  textStyle?: SxProps<Theme>;
}

const StaticContent = ({ title, body, textStyle }: StaticContentProps) => {
  return (
    <Stack>
      <TitleBar title={title} />
      <Typography
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(body) }}
        component="div"
        variant="body1"
        color="text.secondary"
        mx={4}
        sx={{
          // Set overflowWrap to wrap long words like URLs.
          overflowWrap: 'anywhere',
          ...textStyle,
        }}
      />
    </Stack>
  );
};

export default StaticContent;
