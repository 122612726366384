import { Card, Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import OnboardingContinueText from './OnboardingContinueText';
import { ReactComponent as OnboardingHalfMascot } from 'Assets/Mascots/OnboardingMascot.svg';
import { ReactComponent as SplashMascot } from 'Assets/Mascots/SplashMascot.svg';

export interface OnboardingCardProps {
  cardText: string;
  continueText: string;
}

// The card displayed at the bottom of the screen during onboarding.
const OnboardingCard = ({ cardText, continueText }: OnboardingCardProps) => {
  const theme = useTheme();
  const isLargeCard = useMediaQuery(theme.breakpoints.up('tab'));
  const isSmallCard = useMediaQuery(theme.breakpoints.down('mbs'));

  return (
    <Card
      sx={{
        display: 'flex',
        position: 'fixed',
        backgroundColor: 'tertiary.light',
        justifyContent: 'center',
        width: { xs: '100vw', tab: '640px' },
        height: '30vh',
        bottom: 0,
        alignSelf: 'center',
        zIndex: 75,
      }}
    >
      {/* We show a wide mascot if the display itself is large. */}
      {/* For very small displays, decrease the size of the mascot and the text. */}
      <Box>
        {isLargeCard ? (
          <SplashMascot
            style={{
              marginTop: 30,
            }}
          />
        ) : (
          <OnboardingHalfMascot
            style={{
              width: isSmallCard ? 100 : 152,
              height: isSmallCard ? 140 : 213,
              marginTop: 20,
            }}
          />
        )}
      </Box>
      <Stack mr={4} mt={4} spacing={6} direction="column" alignContent="center">
        <Typography
          fontWeight="regular"
          fontSize={{ xs: '1.125rem', mbs: '1.25rem' }}
          textAlign="center"
          lineHeight="1.625rem"
        >
          {cardText}
        </Typography>
        <OnboardingContinueText text={continueText} />
      </Stack>
    </Card>
  );
};

export default OnboardingCard;
