import EditProfile from 'Components/EditProfile';
import { EMPTY_JSON_STRING, GAME_ELEMENT, SUCCESS_MESSAGE } from 'Constants/keys';
import { useUpdateUserProfileMutation } from 'graphql/generated/graphql';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATHS } from 'Routes/RouteKeys';
import ShowSnack from 'Util/SnackbarUtilsConfigurator';
import { LoggedInUser } from './ProfilePage';

const SetupProfilePage = () => {
  const navigate = useNavigate();
  const [updateUser, { loading }] = useUpdateUserProfileMutation({});

  const loggedInUser = JSON.parse(
    window.localStorage.getItem(GAME_ELEMENT.LOGGED_IN_USER) ?? EMPTY_JSON_STRING
  ) as LoggedInUser;

  return (
    <EditProfile
      isLoading={loading}
      onClick={async (updatedProfile) => {
        if (typeof updatedProfile === 'string') {
          ShowSnack.error(updatedProfile);
        } else {
          await updateUser({
            variables: {
              profile: {
                profile: updatedProfile,
              },
            },
          })
            .then((response) => {
              const data = response.data;
              if (data?.updateUserProfile.user.profile) {
                loggedInUser.profile = data?.updateUserProfile.user.profile;
                window.localStorage.setItem(GAME_ELEMENT.LOGGED_IN_USER, JSON.stringify(loggedInUser));
              }
              ShowSnack.success(SUCCESS_MESSAGE.PROFILE_UPDATE);
              navigate(ROUTE_PATHS.ROOT + ROUTE_PATHS.PRIVATE);
            })
            .catch((error) => {
              ShowSnack.error(error.message);
            });
        }
      }}
    />
  );
};

export default SetupProfilePage;
