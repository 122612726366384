import { Close } from '@mui/icons-material';
import { IconButton, Stack, Typography } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

export interface TitleBarProps {
  title: string;
  icon?: any;
  hideIcon?: boolean;
  buttonAction?: () => void;
  style?: SxProps<Theme>;
  // Color of the icon displayed in the title bar if not hidden. Defaults to 'primary'.
  iconColor?: 'primary' | 'white';
}

const TitleBar = ({ title, icon, hideIcon, buttonAction, style, iconColor }: TitleBarProps) => {
  const navigate = useNavigate();
  return (
    <Stack ml={4} my={2} direction={'row'} sx={{ ...style }}>
      <Typography variant="title">{title}</Typography>
      {!hideIcon && (
        <IconButton
          size="medium"
          color={iconColor ?? 'primary'}
          onClick={() => {
            if (buttonAction) {
              buttonAction();
            } else {
              navigate(-1);
            }
          }}
          sx={{ ml: 'auto' }}
        >
          {icon || <Close sx={{ fontSize: '2rem' }} />}
        </IconButton>
      )}
    </Stack>
  );
};
export default TitleBar;
