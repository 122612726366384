import { ApolloProvider, ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { useAuth0 } from '@auth0/auth0-react';

const ApolloProviderWithAuth0 = ({ children }: React.PropsWithChildren<unknown>) => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_GRAPHQL_BACKEND,
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) =>
        console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
      );
    }

    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }
  });

  const authLink = setContext(async (_, { headers, ...rest }) => {
    let token;
    if (isAuthenticated) {
      try {
        token = await getAccessTokenSilently();
        console.log(token);
      } catch (error) {
        console.log(error);
      }
    }

    if (!token) return { headers, ...rest };

    return {
      ...rest,
      headers: {
        ...headers,
        authorization: `Bearer ${token}`,
      },
    };
  });

  const cache = new InMemoryCache({
    possibleTypes: {
      Exercise: ['ImageSelectionExercise', 'LabelSelectionExercise', 'ImageMatchExercise', 'CriteriaSelectionExercise'],
    },
  });

  const apolloClient = new ApolloClient({
    link: authLink.concat(errorLink).concat(httpLink),
    cache: cache,
    connectToDevTools: true,
  });

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

export { ApolloProviderWithAuth0 };
