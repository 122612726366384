import { Theme } from '@emotion/react';
import { SxProps, Avatar, Fade } from '@mui/material';
import { BLANK_URL } from 'Constants/keys';
import { AvatarVariant } from 'graphql/generated/graphql';
import { useState } from 'react';

export interface AvatarProps {
  username?: string;
  avatarVariant: AvatarVariant;
  url?: string | undefined;
  size?: 'sm' | 'md' | 'lg';
  style?: SxProps<Theme>;
}

export default function StyledAvatar({ username, avatarVariant, url, size, style }: AvatarProps) {
  const fontSizeConfig = new Map<string, string>();
  fontSizeConfig.set('sm', '1.25rem');
  fontSizeConfig.set('md', '2rem');
  fontSizeConfig.set('lg', '4rem');

  const bgColor =
    avatarVariant === AvatarVariant.LightInitial
      ? 'secondary.main'
      : avatarVariant === AvatarVariant.DarkInitial
      ? 'primary.dark'
      : 'primary.appBackground';

  const [load, setLoad] = useState(false);
  const handleLoad = () => {
    setLoad(true);
  };

  const avatarSize = size ? size : 'md';

  return (
    <Fade in={load} timeout={1000}>
      <Avatar
        onLoad={handleLoad}
        onError={handleLoad}
        alt={username?.toLocaleUpperCase()}
        sx={{
          width: { xs: 100, mob: 154 },
          height: { xs: 100, mob: 154 },
          fontSize: fontSizeConfig.get(avatarSize),
          bgcolor: bgColor,
          ...style,
        }}
        src={avatarVariant === AvatarVariant.Url ? url : BLANK_URL}
      />
    </Fade>
  );
}
