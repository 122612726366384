import { Card } from '@mui/material';
import ImageBox from 'Components/General/ImageBox';

export interface ExerciseImage {
  //The original S3 Key
  imageS3Key: string;
  //The presigned image URL
  signedImageUrl: string;
}

interface ExerciseImageProps {
  exerciseImage: ExerciseImage;
}

export const ExerciseImageView = ({ exerciseImage }: ExerciseImageProps) => {
  return (
    <Card
      variant="outlined"
      sx={{
        alignSelf: 'center',
        borderWidth: 1,
        borderColor: 'exercise.contrastText',
        height: '21.3vh',
        width: '21.3vh',
        maxWidth: 180,
        maxHeight: 180,
        borderRadius: 2.5,
      }}
    >
      <ImageBox style={{ height: 1, width: 1 }} exerciseImage={exerciseImage} />
    </Card>
  );
};
