import { Box, Fade, SxProps, Theme } from '@mui/material';

import BadgeData from 'Components/Achievements/BadgeData';
import { Badge } from 'graphql/generated/graphql';
import { useState } from 'react';
import BadgeLevel from './BadgeLevel';

export interface AchievementBadgeProps {
  badge: Badge;
  // The circleOverlay is a semi-transparent circle displayed over the badge image.
  isSmall?: boolean;
  style?: SxProps<Theme>;
}

const AchievementBadge = ({ badge, isSmall, style }: AchievementBadgeProps) => {
  let badgeImage;

  if (badge.level < badge.levelThresholds.length) {
    badgeImage = BadgeData[badge.type].incompleteImage;
  } else {
    badgeImage = BadgeData[badge.type].completeImage;
  }

  const offset = isSmall ? 10 : 25;

  const [load, setLoad] = useState(false);
  const handleLoad = () => {
    setLoad(true);
  };

  return (
    <Fade in={load} timeout={1000}>
      <Box
        position="relative"
        width={225}
        height={225}
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        justifyContent={'center'}
        sx={{
          ...style,
        }}
      >
        <Box
          onLoad={handleLoad}
          sx={{ opacity: badge.level === 0 ? '20%' : '100%', height: 0.85, width: 0.85 }}
          component="img"
          alt="Badge image"
          src={badgeImage}
        />
        <BadgeLevel
          level={badge.level}
          maxLevel={badge.levelThresholds.length}
          isSmall={isSmall}
          style={{ position: 'absolute', bottom: offset, right: offset, zIndex: 5 }}
        />
      </Box>
    </Fade>
  );
};

export default AchievementBadge;
