export const LANDING = {
  APP_HEADER: 'Edu',
  SUBTITLE: 'IAC/ASC International Meeting 2022',
  TITLE: 'CytoGo',
  SECTION_TITLE: 'Cytology learning - on the go!',
  SECTION_BODY: 'Join the Cytology community with an app that makes morphology mastery fun!',
  SECTION_BULLETS:
    '• Become a Cyto-genius! \n • Unlock personal achievements \n • Track your mastery and progress \n • Keep a streak going \n • Reach the top of the leaderboard ',
  FOOTER_TITLE: "We're so excited for you to join our cytology challenge!",
  FOOTER_BODY: 'See you on the leaderboard!',
  ERROR: 'error',
  ERROR_DESCRIPTION: 'error_description',
  MESSAGE: 'message',
  EMAIL_VERIFIED: 'Your email was verified. You can continue using the application.',
  VERIFY_EMAIL: 'PLEASE VERIFY EMAIL TO ACTIVATE YOUR ACCOUNT',
  REDIRECTING: 'Your email was verified. We are logging you in!',
};

export const EMAIL_VERIFICATION = {
  SUBTITLE: 'DIGITAL CYTOLOGY EDUCATION SITE',
  SECTION_TITLE: "You're almost done!",
  MESSAGE: 'A confirmation email was sent to:',
  REQUIRED_ACTION: 'Check your email and click the link to activate your account',
  EMAIL_ERROR: "Didn't recieve an email? ",
  RESEND_EMAIL: 'Resend email.',
  WRONG_USER: 'Not you? ',
  RESEND_ERROR: 'Resend failure. ',
  RESEND_RETRY: 'Try again.',
  RESEND_SUCCESS: 'Successfully resent email!',
  CHANGE_USER: 'Login as a different user.',
};

export const SUCCESS_MESSAGE = {
  PROFILE_UPDATE: 'Your profile was updated!',
};

export const EMPTY_STRING = '';
export const EMPTY_JSON_STRING = '{}';
export const DELETED_USER = 'DeletedUser';
export const DELETED_USER_DISPLAY_NAME = 'Deleted User';

export const LESSON_TEXTS = {
  UNIT: 'Unit ',
  CONGRATULATIONS: "Congrats! You've completed the challenge!",
  CONGRATULATIONS_SUBTEXT: 'Continue reviewing lessons for points to top the leaderboard!',
  ACCURACY: 'Accuracy',
  ACCURACY_SUFFIX: '%',
  EXPERIENCE_EARNED: 'Points Earned',
  XP_SUFFIX: 'PT',
  LESSON_END_CONGRATULATIONS: 'Lesson Complete!',
  INCORRECT_TEXTS: [
    'Not exactly...',
    'Not quite right...',
    "That's incorrect...",
    'Nope.',
    'Whoops! You missed that one...',
  ],
  CONGRATULATORY_TEXTS: [
    'Good job!',
    "That's correct!",
    'Nice!',
    "That's right!",
    'Very good!',
    'Well done!',
    'Great job!',
    'You got it!',
    'Superstar!',
    'Great work!',
    'Ahhhhmazing!!',
  ],
  CONGRATULATORY_COMPLETION_TEXTS: [
    'Good job!',
    'Nice!',
    'Very good!',
    'Well done!',
    'Great job!',
    'You got it!',
    'Superstar!',
    'Great work!',
    'Ahhhhmazing!!',
    'Woo Hoo!',
    'You crossed the finish line!',
    'You did it!',
    'Brilliant!!',
    'You are making it look easy!',
  ],
};

export const SIGN_UP_DIRECTIVE = 'signUp';
export const BLANK_URL = 'DOES_NOT_EXIST';

export const ENVIRONMENT = {
  DEVELOPMENT: 'development',
  PLAYGROUND: 'playground',
};

export const TEXT = {
  WAIT: 'Please Wait',
  DEFAULT_NAME: 'Jane Doe',
  DEFAULT_EMAIL: 'Email Here',
  DEFAULT_INSTITUTION: 'Hologic',
};

export const TABS = {
  HOME: 'Home',
  HOME_TITLE: 'Lessons',
  ACHIEVEMENTS: 'Achievements',
  LEADERBOARD: 'Leaderboard',
  PROFILE: 'Profile',
  SETUP_PROFILE_TITLE: 'Setup Profile',
  EDIT_PROFILE_TITLE: 'Edit Profile',
};

export const FOOTER = {
  CONTACT: 'CONTACT',
  TERMS: 'TERMS',
  PRIVACY: 'PRIVACY',
  LICENSE: 'LICENSE',
};

export const SETTINGS = {
  TITLE: 'Settings',
  CONTACT: 'CONTACT US',
  TERMS: 'TERMS',
  PRIVACY: 'PRIVACY POLICY',
  LICENSE: 'LICENSE',
  DELETE: 'DELETE ACCOUNT',
};

export const BUTTON_TITLE = {
  LOGIN: 'LOGIN',
  SIGNUP: 'SIGN UP',
  LOGOUT: 'LOGOUT',
  GET_STARTED: 'GET STARTED',
  EDIT_PROFILE: 'EDIT PROFILE',
  SETTINGS: 'SETTINGS',
  CONTINUE: 'CONTINUE',
  SAVE: 'SAVE',
  CHECK: 'CHECK',
  CONTINUE_LESSON: 'CONTINUE LESSON',
  END_LESSON: 'END LESSON',
  EMAIL: 'SEND EMAIL',
  RETURN_APP: 'RETURN TO APP',
  RETURN_SITE: 'RETURN TO SITE',
  DELETE: 'DELETE ACCOUNT',
};

export const EDIT_PROFILE = {
  USERNAME_LABEL: 'Username',
  INSTITUTION_LABEL: 'Institution',
  OPTIONAL_LABEL: ' (Optional)',
  PROFILE_ICON_LABEL: 'PROFILE ICON',
  USERNAME_EMPTY_ERROR: 'Username cannot be empty!',
};

export const GAME_TEXTS = {
  LESSON_LOCKED_ERROR: 'Progress along unlocked topics to unlock this topic!',
  LABEL_SELECTION_EXERCISE_TITLE: 'Identify the image: ',
  IMAGE_SELECTION_EXERCISE_TITLE: 'Select the diagnosis: ',
  IMAGE_MATCH_EXERCISE_TITLE: 'Match label with the image: ',
  CRITERIA_SELECTION_EXERCISE_TITLE: 'Select the correct criteria: ',
  CORRECT: "That's correct!",
  INCORRECT: "That's incorrect...",
  LEAVE_LESSON_CONFIRMATION_TITLE: 'Do you want to leave this lesson?',
  LEAVE_LESSON_CONFIRMATION_SUBTITLE: "If you end now, you'll lose any progress you've made in this lesson.",
};

export const STATIC_CONTENT = {
  CONTACT_TITLE: 'Contact Us',
  SUPPORT_EMAIL: 'support@cyto-go.com',
  TERMS_TITLE: 'Terms & Conditions',
  PRIVACY_TITLE: 'Privacy Policy',
  LICENSE_TITLE: 'License Agreement',
  CONTACT_BODY: `For inquiries about this application, please reach out to our team at `,
  TERMS_BODY:
    'These Terms and Conditions of Use (the “Terms of Use”) apply to the Hologic web site located at www.hologic.com, and all associated sites linked to www.hologic.com by Hologic, its subsidiaries and affiliates, including Hologic sites around the world (collectively, the “Site”). The Site is the property of Hologic Inc. (“Hologic”) and its licensors. BY USING THIS SITE, YOU AGREE TO THESE TERMS OF USE; IF YOU DO NOT AGREE DO NOT USE THE SITE. Hologic reserves the right, at its sole discretion and without prior notice, to: 1) change, modify, add or remove portions of these Terms of Use, at any time 2) monitor and remove postings and 3) discontinue site availability. It is your responsibility to check these Terms of Use periodically for changes. Your continued use of the Site following the posting of changes will mean that you accept and agree to the changes. As long as you comply with these Terms of Use, Hologic grants you a personal, non-exclusive, non-transferable, limited privilege to enter and use the Site. This Agreement constitutes the entire agreement between you and Hologic relating to the subject matter herein. If any term, condition, or provision of this agreement is determined to be unlawful, invalid, void, or for any reason, unenforceable, the validity and enforceability of the remaining terms, conditions, and provisions shall not in any way be affected or impaired thereby. Content All text, graphics, user interfaces, visual interfaces, photographs, trademarks, logos, sounds, music, artwork and computer code (collectively, “Content”), including but not limited to the design, structure, selection, coordination, expression, “look and feel” and arrangement of such Content, contained on the Site is owned, controlled or licensed by or to Hologic, and is protected by trade dress, copyright, patent and trademark laws, and various other intellectual property rights and unfair competition laws.',
  LICENSE_BODY:
    'Please read this End-User Software License Agreement (hereinafter the “Agreement”) carefully before clicking the “I Agree” button, downloading or using Hologic Connect™ or Unifi™ Analytics (hereinafter the “Licensed Software”). By clicking the “I Agree” button, downloading or using the Licensed Software, You are agreeing to be bound by the terms and conditions of this Agreement. If You are accepting these terms of behalf of another person or company or other legal entity, You represent and warrant that you have full authority to bind that person, company or legal entity to these terms. If You do not agree to the terms of this Agreement, do not click on the “I Agree” button and You cannot download or use the Licensed Software. “You” or “Your” means the person or entity who is being licensed to use the Licensed Software in association with any sales or service agreement (“Usage Agreement”). “We”, “Our” and “Us” means Hologic, Inc. 1. License Grant. We grant You a revocable, non-exclusive, non-transferable, limited right to access and use the Licensed Software on a device owned and controlled by You, and to access and use the Licensed Software strictly in accordance with the terms and conditions of this Agreement, the Usage Agreement and any service agreement, sales agreement or any other agreement with You, (collectively, “Related Agreements”). 2. Restrictions on Use. You shall use the Licensed Software strictly in accordance with the terms of the Related Agreements and shall not: (a) decompile, reverse engineer, disassemble, attempt to derive the source code of...',
};

export const DELETE_PROFILE = {
  TRY_AGAIN: 'An error Occurred. Do you want to retry account deletion?',
  GUARD_TITLE: 'Are you sure you want to delete your account?',
  GUARD_BODY:
    'Deleting your account will erase all personal data and remove access to both the CytoGO app and Digital Cytology Education site. \n\n Any progress and achievements associated with this account will be permanently deleted. Once complete, this action cannot be undone.',
  CONFIRMED_TITLE: 'Your account has been deleted',
  PARTIAL_SUCCESS_TITLE: 'An error has occured',
  PARTIAL_SUCCESS_BODY: `We ran into some trouble whilst deleting your account. Please reach out to our team at ${STATIC_CONTENT.SUPPORT_EMAIL} to confirm account deletion`,
  CONFIRMED_BODY: `We're sorry to see you go! \n\n For inquiries about this application, please reach out to our team at ${STATIC_CONTENT.SUPPORT_EMAIL}`,
};

export const ACHIEVEMENT_TEXTS = {
  POINTS: 'Points',
  POINTS_DESCRIPTION: 'Complete lessons to earn points',
  CONGRATULATIONS: 'Congratulations!',
  BADGE_PREFIX: 'You have received a ',
  BADGE_SUFFIX: ' badge!',
  STREAK: 'Current Streak',
  STREAK_CONTINUED: "You're on a streak!",
  ONGOING_STREAK_DESCRIPTION: 'Complete lessons daily to keep your streak going!',
  NO_STREAK_DESCRIPTION: 'Complete lessons daily to start your streak!',
  ONE_DAY: 'Day',
  DAYS: 'Days',
  MY_BADGES: 'My Badges',
};

export const BADGES = {
  LESSON_COMPLETION: 'Lesson Completion',
  UNIT_COMPLETION: 'Unit Completion',
  EXPERIENCE: 'Total XP',
  ACCURACY: 'Perfect Score',
  STREAK: 'Longest Streak',
};

export const GAME_ELEMENT = {
  LOGGED_IN_USER: 'loggedInUser',
  GAME_STATE: 'gameState',
};

export const LEADERBOARD = {
  LEADERBOARD_RESET: 'Resets at ',
  YOUR_POINTS_TODAY: 'Your Points (today): ',
  CURRENT_RANK_PREFIX: 'Current Rank: ',
  CURRENT_RANK_INFIX: ' of ',
  POINTS: ' points',
  EMPTY_LEADERBOARD_TEXT: 'The leaderboard is getting started!',
  EMPTY_LEADERBOARD_SUBTEXT: "Complete a lesson to join today's leaderboard!",
  LEADERBOARD_USER_ERROR: 'Failed to retrieve user info.',
  LEADERBOARD_DATA_ERROR: 'Failed to retrieve leaderboard.',
};

export const ONBOARDING = {
  FIRST_STAGE_UPPER: 'Welcome to CytoGO!',
  FIRST_STAGE_LOWER: "My name is Ooli! I'll be your guide on this cytology journey!",
  SECOND_STAGE: 'Complete lessons and unlock new topics as you progress!',
  THIRD_STAGE: 'You may revisit topics to level up your mastery and earn more points.',
  FOURTH_STAGE: 'Select a lesson to begin. Click here to start your first lesson.',
  CONTINUE: 'CONTINUE ',
  LETS_BEGIN: "LET'S BEGIN ",
};

export const ERROR_PAGE = {
  ERROR_TEXT: 'There was an error!',
  ERROR_SUBTEXT: 'Press the button below to try reloading.',
  TRY_AGAIN: 'Try again',
  CONTACT_SUPPORT: 'Contact Support',
};
