import { Card, Stack } from '@mui/material';

export interface PlayIndicatorProps {
  lessonCount: number;
  lessonCompletionCount: number;
}

export const PlayIndicator = ({ lessonCount, lessonCompletionCount: playCount }: PlayIndicatorProps) => {
  const maxColor = 'lesson.max';
  let circleColor = 'lesson.max';
  switch (playCount) {
    case 0:
      circleColor = 'lesson.locked';
      break;
    case 1:
      circleColor = lessonCount === playCount ? maxColor : 'lesson.main';
      break;
    case 2:
      circleColor = lessonCount === playCount ? maxColor : 'lesson.two';
      break;
    case 3:
      circleColor = lessonCount === playCount ? maxColor : 'lesson.three';
      break;
    default:
      break;
  }

  const circles = [];
  for (let i = 1; i <= lessonCount; i++) {
    circles.push(
      <Card
        variant="outlined"
        key={i}
        sx={{
          borderColor: i <= playCount ? circleColor : 'primary.appBackground',
          width: 12,
          height: 6,
          backgroundColor: i <= playCount ? circleColor : 'primary.appBackground',
        }}
      ></Card>
    );
  }
  return (
    <Stack direction={'row'} spacing={1} alignItems={'center'}>
      {circles}
    </Stack>
  );
};
