import { Dialog, styled } from '@mui/material';
import AchievementUnlocked from 'Components/Achievements/AchievementUnlocked';
import StreakContinued from 'Components/Achievements/StreakContinued';
import QuitLessonConfirmation from 'Components/Lesson/QuitLessonConfirmation';
import { Badge } from 'graphql/generated/graphql';
import DeleteAccountConfirmation from './DeleteAccountConfirmation';

export enum AppModalType {
  EndLessonConfirmation,
  Achievement,
  Streak,
  DeleteConfirmation,
}

// States for account deletion attempt
export enum UserDeleteCall {
  InProgress,
  Success,
  Failure,
  NoAttempt,
  PartialSuccess,
}

export interface AppModalProps {
  open: boolean;
  onClose: () => void;
  onPositiveAction: () => void;
  onNegativeAction?: () => void;
  badge?: Badge;
  modalType: AppModalType;
  deleteState?: UserDeleteCall;
  isLoading?: boolean;
  disableDismiss?: boolean;
  // If modal type is Streak, currentStreakDays must be provided.
  currentStreakDays?: number;
}

const AppModal = ({
  open,
  onPositiveAction,
  onNegativeAction,
  onClose,
  deleteState,
  badge,
  modalType,
  isLoading,
  disableDismiss,
  currentStreakDays: streakDays,
}: AppModalProps) => {
  if (modalType === AppModalType.EndLessonConfirmation && onNegativeAction === undefined) {
    throw new Error('You must set a negative action!');
  }

  if (modalType === AppModalType.Streak && open && !isLoading && (streakDays === undefined || streakDays === 0)) {
    throw new Error('You must provide a streakDays for a Streak modal!');
  }

  const StyledDialog = styled(Dialog)(({ theme }) => ({
    '.MuiDialog-paper': {
      backgroundColor: theme.palette.secondary.dark,
    },
  }));

  //Handle close, but if set to disableDismiss, do not close when clicked on the backdrop
  const handleClose = (reason: string) => {
    if (disableDismiss) {
      if (reason && reason == 'backdropClick') {
        return;
      }
    } else {
      onClose();
    }
  };
  const getModalView = () => {
    switch (modalType) {
      case AppModalType.EndLessonConfirmation:
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return <QuitLessonConfirmation onContinueClicked={onPositiveAction} onEndClicked={onNegativeAction!} />;
      case AppModalType.DeleteConfirmation:
        return (
          <DeleteAccountConfirmation
            deleteState={deleteState}
            onContinueClicked={onPositiveAction}
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            onEndClicked={onNegativeAction!}
          />
        );
      case AppModalType.Achievement:
        return <AchievementUnlocked badge={badge} onContinueClicked={onPositiveAction} />;
      case AppModalType.Streak:
        return <StreakContinued streak={streakDays ?? 0} isLoading={isLoading} onContinueClicked={onPositiveAction} />;
      default:
        return <></>;
    }
  };
  return (
    <StyledDialog
      PaperProps={{
        style: { borderRadius: 8 },
      }}
      disableEscapeKeyDown={disableDismiss}
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      {getModalView()}
    </StyledDialog>
  );
};

export default AppModal;
