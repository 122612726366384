import { Stack } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { ExerciseState } from 'Enums/ExerciseEnums';
import { useCallback, useEffect, useState } from 'react';
import { ExerciseImage, ExerciseImageView } from '../ExerciseImageView';
import CriteriaItem, { DragCriteriaContent } from './CriteriaItem';
import update from 'immutability-helper';
import { SelectedCriteriaArea } from './SelectedCriteriaArea';
import { EMPTY_STRING } from 'Constants/keys';
import { DndProvider, PreviewState, usePreview } from 'react-dnd-multi-backend';
import { HTML5toTouch } from 'rdndmb-html5-to-touch';
import { currentTimestamp } from 'Util/timestamp';
import { DropZone } from './DropZone';

export interface CriteriaSelectionExerciseViewProps {
  exerciseImage: ExerciseImage;
  criteria: string[];
  exerciseState: ExerciseState;
  correctChoices?: string[];
  correctCriteriaCount: number;
  onSelectionChanged: (selections: string[]) => void;
}

export interface CriteraSelectionState {
  selectedCriteria: string[];
  dropArea: string[];
  criteria: string[];
}

export const CriteriaDragPreview = () => {
  const preview = usePreview<DragCriteriaContent>();
  if (!preview.display) {
    return null;
  }
  const { item, style } = preview as PreviewState;
  return (
    <CriteriaItem
      text={(item as DragCriteriaContent).criteria}
      isSelected={false}
      isDragPreview
      style={{ width: 0.4, height: 40, ...style }}
    />
  );
};

const CriteriaSelectionExerciseView = ({
  exerciseImage,
  exerciseState,
  criteria,
  correctChoices,
  correctCriteriaCount,
  onSelectionChanged,
}: CriteriaSelectionExerciseViewProps) => {
  const fullGridSize = 12;

  const [selectedItems, setSelectedItems] = useState<string[]>();

  useEffect(() => {
    const selectedItemsEmptyArray = [];

    for (let i = 0; i < correctCriteriaCount; i++) {
      selectedItemsEmptyArray.push(EMPTY_STRING);
    }

    setSelectedItems(selectedItemsEmptyArray);
  }, [correctCriteriaCount]);

  const handleSelect = useCallback(
    (criteria: string, index?: number) => {
      if (!selectedItems) return;
      const selectIndex = selectedCriteria.indexOf(criteria);
      if (selectedCriteria.length === correctCriteriaCount) return;
      if (selectIndex >= 0) return;
      setSelectedCriteria(update(selectedCriteria, criteria ? { $push: [criteria] } : { $push: [] }));
      //If we're passed an index, an item was explicitly dropped to the drop area at the specified index.
      if (index) {
        setSelectedItems(update(selectedItems, { [index]: { $set: criteria } }));
        return;
      }
      const availableIndex = selectedItems.indexOf(selectedItems.filter((x) => x === EMPTY_STRING)[0]);
      setSelectedItems(update(selectedItems, { [availableIndex]: { $set: criteria } }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedItems]
  );

  const handleClear = useCallback(
    (index: number, criteria: string) => {
      const clearIndex = selectedCriteria.indexOf(criteria);
      if (clearIndex >= 0) {
        setSelectedCriteria(update(selectedCriteria, { $splice: [[clearIndex, 1]] }));
      }
      setSelectedItems(update(selectedItems, { [index]: { $set: EMPTY_STRING } }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedItems]
  );

  const [selectedCriteria, setSelectedCriteria] = useState<string[]>([]);

  useEffect(() => {
    onSelectionChanged(selectedCriteria);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCriteria]);

  function isSelected(boxName: string) {
    return selectedCriteria.indexOf(boxName) > -1;
  }

  const getGridSize = (index: number) => {
    //If there are even number of items, each item needs to take half of the grid
    if (correctCriteriaCount % 2 === 0) {
      return fullGridSize / 2;
    } else {
      //If there are odd number of items, each item needs to take half of the grid
      //but the last one should take full to have it centered in the grid container
      if (index === correctCriteriaCount - 1) return fullGridSize;
      //Default to half grid.
      return fullGridSize / 2;
    }
  };

  return (
    <DndProvider options={HTML5toTouch}>
      <Stack spacing={4} alignItems={'center'}>
        <ExerciseImageView key={'CriteriaSelection' + exerciseImage.imageS3Key} exerciseImage={exerciseImage} />
        <Grid
          container
          columnSpacing={2}
          rowSpacing={{ xs: 1, mob: 2, tab: 2 }}
          width={{ xs: 0.8, mob: 0.8, tab: 0.65 }}
          display={'flex'}
        >
          {selectedItems &&
            selectedItems.map((lastSelectedItem, index) => (
              <Grid
                key={currentTimestamp() + index}
                //This is to put the odd numbered selection area item at the center
                xs={getGridSize(index)}
                display="flex"
                justifyContent="center"
              >
                <SelectedCriteriaArea
                  exerciseState={exerciseState}
                  lastSelectedCriteria={lastSelectedItem}
                  isCorrect={
                    (exerciseState === ExerciseState.Correct || exerciseState === ExerciseState.Incorrect) &&
                    correctChoices &&
                    correctChoices.length > 0 &&
                    correctChoices.includes(lastSelectedItem)
                  }
                  isIncorrect={
                    exerciseState === ExerciseState.Incorrect &&
                    correctChoices &&
                    correctChoices.length > 0 &&
                    !correctChoices.includes(lastSelectedItem)
                  }
                  onDeselected={(criteria) => {
                    handleClear(index, criteria);
                  }}
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  onDropped={(item) => {
                    //If there's already an item at the specified index, do nothing.
                    if (selectedItems[index]) return;
                    //Check if the item is being moved from another slot.
                    const lastIndex = selectedItems.indexOf(item);
                    //If the selected new slot is empty and the item was present in another slot,
                    //empty the old slot and fill the new slot with this criteria
                    if (selectedItems[index] === EMPTY_STRING && lastIndex !== -1) {
                      const updatedItems = selectedItems.map((mItem, mIndex: number) => {
                        if (mIndex === lastIndex) {
                          //Update the current array's item at lastIndex to empty
                          return EMPTY_STRING;
                        } else if (mIndex === index) {
                          //Update the current array's item at drop index to the dropped criteria
                          return item;
                        } else {
                          //Return unmodified item
                          return mItem;
                        }
                      });
                      setSelectedItems(updatedItems);
                    } else {
                      handleSelect(item, index);
                    }
                  }}
                  key={Math.floor(Date.now() * 1000) + index}
                  style={{ backgroundColor: 'primary.appBackground' }}
                />
              </Grid>
            ))}
        </Grid>

        <Grid container rowSpacing={{ xs: 1, mob: 2, tab: 1 }} width={0.91} columnSpacing={2} display={'flex'}>
          {criteria.map((item, index: number) => (
            // Although index is unique per grid item, it is also the same across re-renders.
            //So index alone won't refresh the view. Setting the key for the grid item to a timestamp + index
            //ensures no stale info is displayed on grid items.
            <Grid
              key={currentTimestamp() + index}
              zIndex={1}
              xs={6}
              display="flex"
              justifyContent="center"
              position="relative"
            >
              <CriteriaItem
                style={{ zIndex: 1, position: 'relative' }}
                text={item}
                isSelected={isSelected(item)}
                isCorrect={
                  (exerciseState === ExerciseState.Correct || exerciseState === ExerciseState.Incorrect) &&
                  correctChoices &&
                  correctChoices.length > 0 &&
                  correctChoices.includes(item)
                }
                isIncorrect={
                  exerciseState === ExerciseState.Incorrect &&
                  correctChoices &&
                  correctChoices.length > 0 &&
                  !correctChoices.includes(item)
                }
                onClick={() => {
                  if (exerciseState === ExerciseState.Correct || exerciseState === ExerciseState.Incorrect) return;
                  handleSelect(item);
                }}
              />
              <DropZone
                style={{
                  position: 'absolute',
                  zIndex: 0,
                  height: 40,
                  width: '100%',
                }}
                onDropped={(item) => {
                  if (
                    selectedItems &&
                    exerciseState != ExerciseState.Correct &&
                    exerciseState != ExerciseState.Incorrect
                  )
                    handleClear(selectedItems.indexOf(item), item);
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Stack>
      {!(exerciseState === ExerciseState.Correct || exerciseState === ExerciseState.Incorrect) && (
        <CriteriaDragPreview />
      )}
    </DndProvider>
  );
};

export default CriteriaSelectionExerciseView;
