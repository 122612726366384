import StaticContent from 'Components/StaticContent';
import { BUTTON_TITLE, LANDING, STATIC_CONTENT } from 'Constants/keys';
import BoxedContainer from 'Containers/BoxedContainer';
import { useContext } from 'react';
import SplashContext from 'Context/SplashContext';
import CustomButton from 'Components/General/CustomButton';

const ContactPage = () => {
  const splashContext = useContext(SplashContext);
  splashContext?.setShowSplash(false);
  return (
    <BoxedContainer justifyTop applyInsetTop>
      <StaticContent
        title={STATIC_CONTENT.CONTACT_TITLE}
        body={STATIC_CONTENT.CONTACT_BODY + STATIC_CONTENT.SUPPORT_EMAIL}
      />
      <CustomButton
        style={{ mt: 6, height: 50 }}
        title={BUTTON_TITLE.EMAIL}
        type="outlined"
        onPress={() => window.open(`mailto:${STATIC_CONTENT.SUPPORT_EMAIL}?subject=[${LANDING.TITLE}]`)}
      />
    </BoxedContainer>
  );
};

export default ContactPage;
