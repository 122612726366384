import StaticContent from 'Components/StaticContent';
import { STATIC_CONTENT } from 'Constants/keys';
import { PRIVACY_BODY } from 'Constants/longKeys';
import BoxedContainer from 'Containers/BoxedContainer';
import { useContext } from 'react';
import SplashContext from 'Context/SplashContext';

const PrivacyPage = () => {
  const splashContext = useContext(SplashContext);
  splashContext?.setShowSplash(false);

  return (
    <BoxedContainer justifyTop applyInsetTop>
      <StaticContent title={STATIC_CONTENT.PRIVACY_TITLE} body={PRIVACY_BODY} />
    </BoxedContainer>
  );
};

export default PrivacyPage;
