import { Star, Bookmark, AccountCircle } from '@mui/icons-material';
import { ReactComponent as HomeIcon } from 'Assets/Icons/SVG/home-tab.svg';
import { BottomNavigation, BottomNavigationAction, Paper, styled } from '@mui/material';
import { TABS } from 'Constants/keys';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ROUTE_PATHS } from 'Routes/RouteKeys';

const BottomNavigationStyled = styled(BottomNavigation)(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
}));

export default function BottomNavBar() {
  const currentLocation = useLocation();
  let currentPathItems = currentLocation.pathname.split('/');

  currentPathItems = currentPathItems.filter((x) => x.trim() !== '');

  //check if route has "home-page" or only "private"
  const routeName = currentPathItems.length === 1 ? currentPathItems[0] : currentPathItems[currentPathItems.length - 1];

  const [selected, setSelected] = useState(
    routeName === ROUTE_PATHS.PRIVATE.replace(/.$/, '') || routeName === ROUTE_PATHS.HOME.replace(/.$/, '')
      ? ROUTE_PATHS.LESSONS
      : routeName + ROUTE_PATHS.ROOT
  );

  //Selection indicator needs to be shown after navigating back from static pages or when broswer back button pressed
  useEffect(() => {
    if (currentLocation.pathname === ROUTE_PATHS.PRIVATE_ROOT + ROUTE_PATHS.PROFILE + ROUTE_PATHS.SETTINGS) {
      if (selected !== ROUTE_PATHS.PROFILE) {
        setSelected(ROUTE_PATHS.PROFILE);
      }
    } else {
      setSelected(
        routeName === ROUTE_PATHS.PRIVATE.replace(/.$/, '') || routeName === ROUTE_PATHS.HOME.replace(/.$/, '')
          ? ROUTE_PATHS.LESSONS
          : routeName + ROUTE_PATHS.ROOT
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLocation]);

  const style = {
    color: 'white.main',
    opacity: '40%',
    '&.Mui-selected': {
      color: 'white.main',
      opacity: '100%',
    },
  };

  return (
    <Paper
      sx={{
        position: 'fixed',
        left: 0,
        right: 0,
        zIndex: 66,
        bottom: 0,
        paddingBottom: 'calc(env(safe-area-inset-bottom) - 1.5em)',
        backgroundColor: 'primary.dark',
      }}
      elevation={3}
    >
      <BottomNavigationStyled
        showLabels
        value={selected}
        onChange={(value, newValue) => {
          setSelected(newValue);
        }}
      >
        <BottomNavigationAction
          sx={style}
          label={TABS.HOME}
          icon={<HomeIcon />}
          value={ROUTE_PATHS.LESSONS}
          to={ROUTE_PATHS.LESSONS}
          component={Link}
        />
        <BottomNavigationAction
          sx={style}
          label={TABS.ACHIEVEMENTS}
          icon={<Star />}
          value={ROUTE_PATHS.ACHIEVEMENTS}
          to={ROUTE_PATHS.ACHIEVEMENTS}
          component={Link}
        />
        <BottomNavigationAction
          sx={style}
          label={TABS.LEADERBOARD}
          icon={<Bookmark />}
          value={ROUTE_PATHS.LEADERBOARD}
          to={ROUTE_PATHS.LEADERBOARD}
          component={Link}
        />
        <BottomNavigationAction
          sx={style}
          label={TABS.PROFILE}
          icon={<AccountCircle />}
          value={ROUTE_PATHS.PROFILE}
          to={ROUTE_PATHS.PROFILE}
          component={Link}
        />
      </BottomNavigationStyled>
    </Paper>
  );
}
