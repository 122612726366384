export const ROUTE_PATHS = {
  WILDCARD: '*',
  PRIVATE: 'private/',
  PRIVATE_ROOT: '/private/',
  BLANK: '',
  ROOT: '/',
  LANDING: 'landing-page/',
  HOME: 'home-page/',
  CONTACT: 'contact/',
  TERMS: 'terms/',
  PRIVACY: 'privacy/',
  LICENSE: 'license/',
  SETTINGS: 'settings/',
  LESSONS: 'lessons/',
  EXERCISE: 'exercise/',
  ACHIEVEMENTS: 'achievements/',
  PROFILE: 'profile/',
  EDIT_PROFILE: 'edit-profile/',
  SETUP_PROFILE: 'setup-profile/',
  LEADERBOARD: 'leaderboard/',
  VERIFY: 'email-verifiaction/',
};
