import { Card, Stack, Typography } from '@mui/material';
import { ACHIEVEMENT_TEXTS } from 'Constants/keys';

export interface PointsDisplayProps {
  points: number;
}

const PointsDisplay = ({ points }: PointsDisplayProps) => {
  return (
    <Card
      sx={{
        backgroundColor: 'primary.main',
        minWidth: 70,
        minHeight: 70,
      }}
    >
      <Stack mt={1} alignItems={'center'}>
        <Typography variant="title" letterSpacing="0.58px" align="center">
          {points?.toFixed(0).toString()}
        </Typography>
        <Typography
          mb={2}
          variant="caption"
          align="center"
          letterSpacing="0.96px"
          lineHeight="14px"
          textTransform="uppercase"
          color="white.main"
        >
          {ACHIEVEMENT_TEXTS.POINTS}
        </Typography>
      </Stack>
    </Card>
  );
};

export default PointsDisplay;
