import { Box, Container, SxProps, Theme } from '@mui/material';
import { DefaultComponentProps } from '@mui/material/OverridableComponent';
import { PropsWithChildren } from 'react';

export interface BoxedContainerProps {
  bgColor?: DefaultComponentProps<any>;
  style?: SxProps<Theme>;
  justifyTop?: boolean;
  applyInsetTop?: boolean;
  children: PropsWithChildren<any>;
}

const BoxedContainer = ({ bgColor, justifyTop, applyInsetTop, style, children }: BoxedContainerProps) => {
  return (
    <Container maxWidth={'tab'} disableGutters={true} sx={{ backgroundColor: bgColor }}>
      <Box
        bgcolor={bgColor ?? 'primary.appBackground'}
        flexDirection="column"
        display="flex"
        minHeight="100vh"
        alignSelf="center"
        sx={{
          justifyContent: justifyTop ? 'top' : 'center',
          paddingTop: applyInsetTop ? 'env(safe-area-inset-top)' : 0,
          ...style,
        }}
      >
        {children}
      </Box>
    </Container>
  );
};

export default BoxedContainer;
