import React, { createContext } from 'react';

export interface SplashContextType {
  setShowSplash: React.Dispatch<React.SetStateAction<boolean>>;
  showSplash: boolean;
}

const SplashContext = createContext<SplashContextType | null>(null);

export default SplashContext;
