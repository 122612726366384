import { Stack, Typography } from '@mui/material';
import { ExerciseState } from 'Enums/ExerciseEnums';
import { SelectedCriteriaArea } from '../CriteriaSelection/SelectedCriteriaArea';
import { ExerciseImage } from '../ExerciseImageView';
import { ImageSelectionItem } from '../ImageSelection/ImageSelectionItem';

export interface ImageMatchItemProps {
  exerciseState: ExerciseState;
  isCandidate?: boolean;
  isSelected: boolean;
  isCorrect?: boolean;
  isIncorrect?: boolean;
  exerciseImage: ExerciseImage;
  label: string;
  correctLabel?: string;
  onDeselected: (label: string) => void;
  onSelected: () => void;
  onDropped: (label: string) => void;
}

const ImageMatchItem = ({
  exerciseState,
  isCandidate,
  isSelected,
  isCorrect,
  isIncorrect,
  exerciseImage,
  label,
  correctLabel,
  onDeselected,
  onSelected,
  onDropped,
}: ImageMatchItemProps) => {
  return (
    <Stack
      width={1}
      display={'flex'}
      alignItems={'center'}
      spacing={1}
      sx={{
        borderRadius: 2,
        backgroundColor: isCandidate ? 'primary.dark' : 'transparent',
        padding: isCandidate ? 0.25 : 0,
      }}
    >
      <Stack
        width={1}
        display={'flex'}
        alignItems={'center'}
        py={2}
        spacing={{ xs: 1, mob: 2 }}
        sx={{
          borderRadius: 2,
          backgroundColor: isCorrect
            ? 'exercise.criteriaCorrect'
            : isIncorrect
            ? 'exercise.criteriaIncorrect'
            : isSelected
            ? 'exercise.criteria'
            : isCandidate
            ? 'exercise.matchCandidate'
            : 'exercise.criteriaOption',
        }}
      >
        <ImageSelectionItem
          isSelected={false}
          isCorrect={false}
          isIncorrect={false}
          exerciseImage={exerciseImage}
          onClick={onSelected}
          style={{
            height: { xs: 140, mob: 142, tab: 180 },
            width: { xs: 140, mob: 142, tab: 180 },
          }}
        />
        <SelectedCriteriaArea
          isImageMatchCriteria={true}
          exerciseState={exerciseState}
          lastSelectedCriteria={label}
          isCorrect={isCorrect}
          isIncorrect={isIncorrect}
          isCandidate={isCandidate}
          onDeselected={(criteria) => {
            if (exerciseState === ExerciseState.Correct || exerciseState === ExerciseState.Incorrect) return;
            onDeselected(criteria);
          }}
          style={{ width: 0.9, borderRadius: 1 }}
          onDropped={(criteria) => {
            onDropped(criteria);
          }}
        />
      </Stack>
      {correctLabel && (
        <Typography
          noWrap
          textAlign={'center'}
          width={'90%'}
          variant={'exerciseResult'}
          color={isIncorrect ? 'success.light' : 'transparent'}
        >
          {isIncorrect ? correctLabel : label}
        </Typography>
      )}
    </Stack>
  );
};

export default ImageMatchItem;
