import { Box, Stack, useMediaQuery, useTheme } from '@mui/material';
import { GameProgress, TopicProgress, UnitProgress } from 'graphql/generated/graphql';
import { useEffect, useMemo, useState } from 'react';
import GameEnd from './GameEnd';
import TopicCircle from './TopicCircle';
import UnitTitleBar from './UnitTitleBar';
import Snackbar from 'Util/SnackbarUtilsConfigurator';
import { GAME_TEXTS } from '../../Constants/keys';
import Blob1 from 'Assets/Blobs/Blob1.png';
import Blob2 from 'Assets/Blobs/Blob2.png';
import Blob3 from 'Assets/Blobs/Blob3.png';
import Mountains from 'Assets/Blobs/Mountains.png';
import MountainsBg from 'Assets/Blobs/MountainsBg.png';
import Background from 'Assets/Blobs/BackgroundImage.png';
import { ReactComponent as OnboardingArrow } from 'Assets/Images/Onboarding-Arrow.svg';
import ScrollTo from 'Components/General/ScrollTo';
import { timeout } from 'Util/delay';

interface GamePathElements {
  firstTopicLeft: number;
  firstTopicTop: number;
  height: number;
  elementCoordinates: JSX.Element[];
  svgPathD: string[];
  scrollPosition: number;
  firstLockedPlotY?: number;
}

//Object to keep track of elapsed units and topics when laying out the game elements in the path.
interface ElapsedItems {
  units: number;
  topics: number;
}

interface GameObject {
  unitId: number;
  topicId?: number;
  plotObject: UnitProgress | TopicProgress;
}

export interface GamePathProps {
  gameProgress: GameProgress | undefined;
  onTopicClicked: (unitIdentifier: number, lessonIdentifier: number) => void;
  // The zIndex to apply to the first topic circle. If undefined, has same zIndex as other circles.
  firstTopicZIndex?: number;
  // Whether to show the onboarding arrow below the first topic circle.
  showFirstTopicArrow?: boolean;
}

export default function GamePath({
  gameProgress,
  onTopicClicked,
  firstTopicZIndex,
  showFirstTopicArrow,
}: GamePathProps) {
  //The space allocated for game end component.
  const additionalHeight = gameProgress ? (gameProgress.isComplete ? 425 : 200) : 200;

  const blobs = [Blob1, Blob2, Blob3];

  const theme = useTheme();

  const [showPath, setShowPath] = useState(true);

  //TODO: EX-220
  const isSmall = useMediaQuery(theme.breakpoints.down('mob'));
  const isMobile = useMediaQuery(theme.breakpoints.down('tab'));

  //Memorize game layout to save extensive computation when the game is reloaded.
  const gamePathElements = useMemo(() => {
    if (gameProgress) {
      const gameElements = getGameElements(gameProgress, firstTopicZIndex);
      return gameElements;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameProgress, firstTopicZIndex]);

  function getGameElements(gameProgress: GameProgress, firstTopicZIndex?: number) {
    let totalUnitsCount = 0;
    let totalTopicsCount = 0;

    let height = 0;

    if (gameProgress) {
      totalUnitsCount = gameProgress.unitProgresses.length;
      gameProgress?.unitProgresses.map((x) => {
        return (totalTopicsCount = totalTopicsCount + x.topicProgresses.length);
      });
    }

    //The SVG Path
    const svgPathDx = [];
    //The array that holds unit title and topics
    const elementCoordinates: JSX.Element[] = [];
    //The view factor for each topic item
    const topicCycle = 0.225;
    //The view factor for each unit title item
    const unitCycle = 0.08;
    //The total cycles required
    const cycle = totalUnitsCount * unitCycle + totalTopicsCount * topicCycle;
    //The max swing for the curve
    const xmax = 150;
    //The multiplier for yforx to calculate max height
    const yratio = 7;
    //The basis for height calculation
    const yforx = xmax * yratio;
    //The required height for the gameflow
    height = yforx * cycle;
    //Y offset for drawing path
    const drawPathOffsetY = 30;
    //Y offset for path
    const yOffset = 100;
    //X offset for path
    const xOffset = isSmall ? 50 : isMobile ? 100 : 200;
    //X offset for elements
    const elementXOffset = 70;
    //Y offset for elements
    const elementYOffset = 100;
    //Extra offset for Topic items
    const topicOffset = 25;
    //The factor by which to push game path curve by
    const svgPushCoordsBy = 3;
    //The X offset for the GameEnd component
    const gameEndXOffset = isSmall ? 75 : isMobile ? 135 : 235;
    const gameEndPathXOffset = isSmall ? 160 : isMobile ? 225 : 325;
    const gameEndCompleteXOffset = isSmall ? 0 : isMobile ? 0 : 35;

    //The map to configure y value and the corresponding elements
    const plotY = new Map<number, GameObject>();

    const unitProgresses = gameProgress?.unitProgresses;

    const elapsedItems = {} as ElapsedItems;
    elapsedItems.topics = 0;
    elapsedItems.units = 0;

    let firstTopicLeft = 0;
    let firstTopicTop = 0;

    //Calculate plotY i.e. the top position for each element.
    let foundScrollPosition = false;
    let positionToScroll = 0;
    let firstLockedPlotY = 0;
    if (unitProgresses) {
      if (gameProgress.isComplete) {
        //If Game is complete, scroll to the bottom
        positionToScroll = height;
        foundScrollPosition = true;
      } else {
        //Check if last topic of last unit is unlocked,
        //if so, all other topics must be unlocked too, so scroll to nearly the bottom
        const lastUnit = unitProgresses[unitProgresses.length - 1];
        const topicsInLastUnit = lastUnit.topicProgresses;
        const lastTopicInLastUnit = topicsInLastUnit[topicsInLastUnit.length - 1];
        if (!lastTopicInLastUnit.isLocked) {
          positionToScroll = height - 3 * yOffset;
          foundScrollPosition = true;
        }
      }

      //Units
      for (let i = 0; i < unitProgresses.length; i++) {
        const plotPoint = Math.ceil(yforx * (elapsedItems.units * unitCycle + elapsedItems.topics * topicCycle));
        const unitProgress = unitProgresses[i];
        plotY.set(plotPoint, { unitId: unitProgress.unitDefinitionId, plotObject: unitProgress });
        elapsedItems.units++;

        const topicProgresses = unitProgresses[i].topicProgresses;
        //Topics in Units
        for (let j = 0; j < topicProgresses.length; j++) {
          const plotPoint = Math.ceil(yforx * (elapsedItems.units * unitCycle + elapsedItems.topics * topicCycle));
          const topicProgress = topicProgresses[j];
          plotY.set(plotPoint, {
            unitId: unitProgress.unitDefinitionId,
            topicId: topicProgress.topicDefinitionId,
            plotObject: topicProgress,
          });
          elapsedItems.topics++;

          //If we haven't yet found the scrollPosition, scroll to the first locked item
          if (!foundScrollPosition) {
            const storedLesson = window.localStorage.getItem('recentLesson');
            // i is unit number less 1, j is lesson number less 1
            const lessonString = `${i + 1}${j + 1}`;
            if (topicProgress.isLocked) {
              positionToScroll = plotPoint - 3 * yOffset;
              firstLockedPlotY = plotPoint;
              foundScrollPosition = true;
            } else if (lessonString == storedLesson) {
              positionToScroll = plotPoint - 1.5 * yOffset;
              window.localStorage.setItem('recentLesson', '');
              foundScrollPosition = true;
            }
          }
        }
      }
    }

    let drawRight = false;
    const drawXNormal = isSmall ? 40 : 10;
    const drawXRight = isSmall ? 128 : 138;

    // Track which unit is being rendered
    let unit = 0;

    //Draw the SVG path, push elements along the path
    for (let y = 0; y <= height; y++) {
      //the x position for the items
      const drawX = drawRight ? drawXNormal + xOffset : drawXRight + xOffset;

      const drawPathY = drawPathOffsetY + y + yOffset;

      const drawElementX = drawX - elementXOffset;
      const drawElementY = drawPathY - elementYOffset;
      const drawTitleY = y + yOffset - elementYOffset;

      const itemLeftLocked = drawElementX + topicOffset;
      const itemLeftUnlocked = drawElementX + topicOffset / 2.25;
      const itemTopLocked = drawElementY + topicOffset;

      if (plotY.get(y)) {
        const unitId = plotY.get(y)?.unitId;
        const item = plotY.get(y)?.plotObject;

        if ((item as UnitProgress).topicProgresses) {
          elementCoordinates.push(
            <UnitTitleBar
              key={'unit' + (item as UnitProgress).unitDefinitionId}
              unitName={(item as UnitProgress).unitName}
              unitNumber={(item as UnitProgress).unitDefinitionId}
              style={{
                position: 'absolute',
                left: 0,
                top: drawTitleY,
              }}
            />
          );
          unit = (item as UnitProgress).unitDefinitionId;
        } else {
          const isFirstTopicCircle = elementCoordinates.length === 1;

          const topicLeft = (item as TopicProgress).isLocked ? itemLeftLocked : itemLeftUnlocked;
          const topicTop = (item as TopicProgress).isLocked
            ? itemTopLocked + elementYOffset / 4
            : drawElementY + elementYOffset / 6;

          elementCoordinates.push(
            <TopicCircle
              key={'unit' + unit + 'topic' + (item as TopicProgress).topicDefinitionId}
              lessonsPerTopic={gameProgress?.lessonsPerTopic}
              topic={item as TopicProgress}
              isFirstLocked={firstLockedPlotY === y}
              blob={blobs[Math.floor(Math.random() * blobs.length)]}
              style={{
                position: 'absolute',
                left: (item as TopicProgress).isLocked ? itemLeftLocked : itemLeftUnlocked,
                top: (item as TopicProgress).isLocked
                  ? itemTopLocked + elementYOffset / 4
                  : drawElementY + elementYOffset / 6,
                zIndex: isFirstTopicCircle ? firstTopicZIndex : undefined,
              }}
              onClick={async () => {
                if ((item as TopicProgress).isLocked) {
                  Snackbar.warning(GAME_TEXTS.LESSON_LOCKED_ERROR);
                  return;
                }
                setShowPath(false);
                window.scrollTo(0, 0);
                // allow time for scroll to happen
                await timeout(100);
                const topicId = plotY.get(y)?.topicId;

                if (unitId && topicId) {
                  window.localStorage.setItem('recentLesson', `${unitId}${topicId}`);
                  onTopicClicked(unitId, topicId);
                } else {
                  setShowPath(true);
                }
              }}
            />
          );

          if (isFirstTopicCircle) {
            firstTopicLeft = topicLeft;
            firstTopicTop = topicTop;
          }
        }

        if (elementCoordinates.length <= 1) {
          //Do Nothing
        } else if (elementCoordinates.length === 2) {
          //Move to the first topic
          svgPathDx.push('M' + drawX.toFixed(svgPushCoordsBy) + ',' + drawPathY.toFixed(svgPushCoordsBy));
        } else if (elementCoordinates.length > 2) {
          //Draw line to the next element, but if it is a Unit Title object, slightly change the Y coordinates
          (item as UnitProgress).topicProgresses
            ? svgPathDx.push(
                'L' + drawX.toFixed(svgPushCoordsBy) + ',' + (drawPathY - elementYOffset).toFixed(svgPushCoordsBy)
              )
            : svgPathDx.push('L' + drawX.toFixed(svgPushCoordsBy) + ',' + drawPathY.toFixed(svgPushCoordsBy));
        }
        drawRight = !drawRight;
      }
    }

    elementCoordinates.push(
      <GameEnd
        isComplete={gameProgress?.isComplete ?? false}
        style={{
          position: 'absolute',
          top: gameProgress?.isComplete ? height - yOffset / 7 : height - yOffset / 1.5,
          left: gameProgress?.isComplete ? gameEndCompleteXOffset : gameEndXOffset,
        }}
        key="{exm-gameflow-lesson-end}"
      />
    );

    //Draw path to GameEnd
    svgPathDx.push(
      'L' +
        gameEndPathXOffset.toFixed(svgPushCoordsBy) +
        ',' +
        (gameProgress?.isComplete ? height - yOffset / 7 : height).toFixed(svgPushCoordsBy)
    );

    const gamepathElements: GamePathElements = {
      firstTopicLeft: firstTopicLeft,
      firstTopicTop: firstTopicTop,
      height: height,
      elementCoordinates: elementCoordinates,
      svgPathD: svgPathDx,
      scrollPosition: positionToScroll,
      firstLockedPlotY: firstLockedPlotY,
    };

    console.log(gamepathElements);

    return gamepathElements;
  }

  // Do not show the path while splash screen is loading
  if (!showPath) return <Box />;

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      sx={{
        backgroundImage: 'url(' + Background + ')',
        backgroundRepeat: 'repeat-y',
        backgroundPosition: 'center top',
        minHeight: (gamePathElements?.height || 0) + additionalHeight,
      }}
      key={'exm-gf-div-container'}
    >
      {gamePathElements && <ScrollTo top={gamePathElements.scrollPosition} behavior="smooth" />}
      <Stack
        mx={2}
        display={'flex'}
        style={{ position: 'absolute', alignItems: 'center' }}
        key={'exm-topic-div-container'}
      >
        {showFirstTopicArrow && firstTopicZIndex && (
          <OnboardingArrow
            style={{
              position: 'absolute',
              zIndex: firstTopicZIndex + 1, // Give the arrow a higher z index than the topic.
              width: 150,
              height: 150,
              left: (gamePathElements?.firstTopicLeft ?? 0) - 30,
              top: (gamePathElements?.firstTopicTop ?? 0) + 90,
            }}
          />
        )}
        {gamePathElements?.elementCoordinates ? gamePathElements?.elementCoordinates : <></>}
      </Stack>
      <svg
        style={{
          width: '100%',
          alignSelf: 'center',
          height: (gamePathElements?.height || 0) + additionalHeight,
        }}
        key={'exm-gf-svg-path-container'}
      >
        <path
          id="wave"
          stroke={theme.palette.lesson.dark}
          opacity="0.5"
          strokeWidth="12"
          strokeLinecap="round"
          strokeDasharray="1,20"
          fill="none"
          d={gamePathElements?.svgPathD.join(' ')}
          key={'exm-gf-svg-svgpath'}
        />
      </svg>
      {gameProgress && (
        <Box sx={{ position: 'relative' }}>
          <Box>
            <Box width={1} component="img" src={MountainsBg} />
          </Box>
          <Box component="img" src={Mountains} width={1} sx={{ position: 'absolute', bottom: 0 }} />
        </Box>
      )}
    </Box>
  );
}
