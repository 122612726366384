import ExerciseTitleBar from 'Components/Exercise/ExerciseTitleBar';
import AppModal, { AppModalType } from 'Components/General/AppModal';
import LessonEnd from 'Components/Lesson/LessonEnd';
import BoxedContainer from 'Containers/BoxedContainer';
import { ReactComponent as Mascot1 } from 'Assets/Mascots/Exercise/Exercise1.svg';
import { ReactComponent as Mascot2 } from 'Assets/Mascots/Exercise/Exercise2.svg';
import { ReactComponent as Mascot3 } from 'Assets/Mascots/Exercise/Exercise3.svg';
import { ReactComponent as Mascot4 } from 'Assets/Mascots/Exercise/Exercise4.svg';
import { ReactComponent as Mascot5 } from 'Assets/Mascots/Exercise/Exercise5.svg';
import { ReactComponent as Mascot6 } from 'Assets/Mascots/Exercise/Exercise6.svg';
import { ReactComponent as Mascot7 } from 'Assets/Mascots/Exercise/Exercise7.svg';
import { BLANK_URL, EMPTY_JSON_STRING, EMPTY_STRING, GAME_ELEMENT } from 'Constants/keys';
import {
  CriteriaSelectionExercise,
  ExerciseType,
  ImageMatchExercise,
  ImageSelectionExercise,
  LabelSelectionExercise,
  Lesson,
  NextExerciseQuery,
  useLessonQuery,
  useNextExerciseQuery,
} from 'graphql/generated/graphql';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ShowSnack from 'Util/SnackbarUtilsConfigurator';
import CorrectSound from 'Assets/Sounds/Correct.wav';
import IncorrectSound from 'Assets/Sounds/Incorrect.wav';
import useSound from 'use-sound';
import { GameState } from 'Components/Lesson/Gameflow';
import { useAuth0 } from '@auth0/auth0-react';
import { presignUrl } from 'Util/presigner';
import { ExerciseImage } from 'Components/Exercise/ExerciseImageView';
import { ApolloError } from '@apollo/client';
import ExerciseView, { ExerciseParams } from 'Components/Exercise/ExerciseView';

const ExercisePage = () => {
  const [playCorrectSound] = useSound(CorrectSound, {
    onload() {
      this.soundEnabled = true;
    },
  });
  const [playIncorrectSound] = useSound(IncorrectSound);
  const navigate = useNavigate();

  // Check if user is on the first exercise
  const [isFirstEx, setFirstEx] = useState(true);

  //The images for the next exercise
  const [nextImages, setNextImages] = useState<string[]>();
  // The lesson progress; depends on completedExerciseCount
  const [lessonProgress, setLessonProgress] = useState<number>();
  // Total number of exercises that have been shown to the user.
  const [exerciseCount, setExerciseCount] = useState<number>(0);
  //The number of exercises the user has completed.
  const [completedExerciseCount, setCompletedExerciseCount] = useState<number>();
  // Data describing the current exercise being displayed to the user.
  const [exerciseParams, setExerciseParams] = useState<ExerciseParams>();
  //The images for the next exercise. This is a map between the images in nextImages and the signed urls.
  const [nextExerciseImages, setNextExerciseImages] = useState<Map<string, string>>();

  //The active lesson
  const [lesson, setLesson] = useState<Lesson>();

  const mascots = [<Mascot1 />, <Mascot2 />, <Mascot3 />, <Mascot4 />, <Mascot5 />, <Mascot6 />, <Mascot7 />];

  const [accuracy, setAccuracy] = useState<number>(0);
  const [xp, setXP] = useState<number>(0);

  const [lessonCompleted, setLessonCompleted] = useState(false);
  const [clickedPageBack, setClickedPageBack] = useState(false);

  const firstExImages: ExerciseImage[] = [];
  const gameState = JSON.parse(window.localStorage.getItem(GAME_ELEMENT.GAME_STATE) ?? EMPTY_JSON_STRING) as GameState;

  //The images for the first exercise, both signed and unsigned set into an ExerciseImage object.
  const [firstExerciseImages, setFirstExerciseImages] = useState<ExerciseImage[] | undefined>();

  //Control to show/hide quit lesson confirmation
  const [showQuitLessonConfirmation, setShowQuitLessonConfirmation] = useState(false);

  const { getAccessTokenSilently } = useAuth0();

  // True from when user has clicked the button to continue to the next
  // exercise, until the exercise parameters have been updated.
  const [isTransitioningExercise, setIsTransitioningExercise] = useState(false);

  const unitId = gameState?.unitId;
  const topicId = gameState?.topicId;
  const lessonId = gameState?.lessonId;

  const {
    data: lessonData,
    error: lessonError,
    loading: lessonLoading,
    refetch: refetchLesson,
  } = useLessonQuery({
    refetchWritePolicy: 'overwrite',
    fetchPolicy: 'network-only',
    variables: {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      unitId: unitId!,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      topicId: topicId!,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      lessonId: lessonId!,
    },
  });

  const [exerciseData, setExerciseData] = useState<NextExerciseQuery>();
  const [exerciseLoading, setExerciseLoading] = useState<boolean>();
  const [exerciseError, setExerciseError] = useState<ApolloError>();

  // Information for the next exercise
  const [exerciseDataStore, setExerciseDataStore] = useState<NextExerciseQuery>();

  const {
    data: nextExerciseData,
    loading: nextExerciseLoading,
    error: nextExerciseError,
    refetch: refetchExercise,
  } = useNextExerciseQuery({
    notifyOnNetworkStatusChange: true,
    refetchWritePolicy: 'overwrite',
    fetchPolicy: 'network-only',
    variables: {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      unitId: unitId!,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      topicId: topicId!,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      lessonId: lessonId!,
    },
  });

  useEffect(() => {
    console.log('ExercisePage nextExerciseData useEffect()');
    // Directly load information for the first exercise
    if (nextExerciseData && isFirstEx) {
      setExerciseData(nextExerciseData);
      setExerciseLoading(nextExerciseLoading);
      setExerciseError(nextExerciseError);
      setFirstEx(false);
    }
    // Store information for future exercises
    else {
      if (nextExerciseError) {
        console.log(nextExerciseError.message);
        ShowSnack.error(nextExerciseError.message);
      }
      console.log('setting ex data store');
      console.log(nextExerciseData);
      setExerciseDataStore(nextExerciseData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextExerciseData]);

  // Called on transitiion to next exercise
  const updateExercise = async () => {
    setExerciseData(exerciseDataStore);
  };

  useEffect(() => {
    console.log('ExercisePage useEffect()');
    if (gameState && gameState.exerciseImages) {
      //This might run again on re-render. We do not want redundant sets for firstExerciseImages.
      //If we have an already populated firstExImages, do nothing.
      if (firstExImages.length > 0) return;
      const exImagesMap = new Map<string, string>(JSON.parse(gameState.exerciseImages));
      for (const [key, value] of exImagesMap) {
        firstExImages.push({ imageS3Key: key, signedImageUrl: value });
      }

      //Since it has no default value, it is undefined.
      //If it is not undefined, it already holds value.
      if (!firstExerciseImages) {
        console.log(firstExImages);
        setFirstExerciseImages(firstExImages);
      }

      //Push the lessonId as an extra route to handle onpopstate on this page
      if (gameState && gameState.lessonId) {
        if (window.location.pathname.includes(gameState.lessonId)) return;
        history.pushState(null, EMPTY_STRING, gameState.lessonId);
      }

      //Pressing back key on phones or the back button on browsers
      //triggers. Show the quit lesson dialog.
      window.onpopstate = (e) => {
        if (!lessonCompleted) {
          e.preventDefault();
          setShowQuitLessonConfirmation(true);
        } else {
          clearGameState();
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Used to display error messages received when querying lesson & exercise & checking answer.
  useEffect(() => {
    console.log('ExercisePage [exerciseError, lessonError] useEffect()');
    if (lessonError || exerciseError) {
      let errorMessage = '';
      if (lessonError?.message) {
        errorMessage = lessonError?.message;
      }
      if (exerciseError?.message) {
        errorMessage = exerciseError?.message;
      }
      ShowSnack.error(errorMessage);
    }
  }, [exerciseError, lessonError]);

  useEffect(() => {
    console.log('ExercisePage [lessonData] useEffect()');
    //Runs when lessonData changes.
    //Once lesson is received, start retrieving exercise.
    if (!lessonData) return;
    const thisLesson = lessonData.lesson;
    //Set current lesson. Components that use 'lesson' will be updated once set.
    setLesson(thisLesson);

    if (thisLesson.isComplete) {
      setLessonCompleted(thisLesson.isComplete);
      setXP(thisLesson.experiencePoints ?? 0);
      setAccuracy(thisLesson.accuracy ?? 0);
    } else {
      const completedExercises = thisLesson.exercises.filter((x) => x.isComplete).length;
      //Set completed lesson count, which in turn updates lessonProgress.
      setCompletedExerciseCount(completedExercises);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lessonData]);

  let exerciseParameters = {} as ExerciseParams;

  //Handle new exercise data
  useEffect(() => {
    console.log('ExercisePage [exerciseData] useEffect()');
    const setupExercise = async () => {
      await setupExerciseParameters();
      setIsTransitioningExercise(false);
    };
    setupExercise();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exerciseData]);

  const handleExerciseBackClicked = () => {
    console.log('ExercisePage handleExerciseBackClicked()');
    setClickedPageBack(true);
    setShowQuitLessonConfirmation(true);
  };

  const handleContinueLesson = () => {
    console.log('ExercisePage handleContinueLesson()');
    setShowQuitLessonConfirmation(false);
    //If the continue lesson was triggered from a modal
    //triggered through physical back button, we need
    //to add back the extra route that helps us pick the physical back button press.
    if (!clickedPageBack && gameState && gameState.lessonId) {
      if (window.location.pathname.includes(gameState.lessonId)) return;
      history.pushState(null, EMPTY_STRING, gameState.lessonId);
    }
    setClickedPageBack(false);
  };

  useEffect(() => {
    console.log('ExercisePage lessonCompleted()');
    //Reset the extra added route because if user clicks back on
    //lesson complete page, we want to take the user back to home page.
    if (lessonCompleted && gameState.lessonId && window.location.pathname.includes(gameState.lessonId)) navigate(-1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lessonCompleted]);

  const handleEndLesson = () => {
    console.log('ExercisePage handleEndLesson()');
    clearGameState();
    //If page back button is clicked, onPopState event is not fired,
    //so extra lesson ID is not removed. Navigate two routes back.
    if (clickedPageBack) {
      navigate(-2);
    } else {
      //Physical Back Button click will trigger onPopState event
      //which will have removed the extra lessonId. Only navigate a level back.
      navigate(-1);
    }
  };

  const processNewImages = () => {
    console.log('ExercisePage processNewImages()');
    if (!nextImages) return;
    const generateSignedImageUrls = async (images: string[]) => {
      const token = await getAccessTokenSilently();
      const newImages = new Map<string, string>();
      for (const image of images) {
        //Sign Unsigned Images;
        const signedUrl = await presignUrl(image, token);
        if (signedUrl) {
          newImages.set(image, signedUrl);
        } else {
          console.log('Signed Image Generation failed for', image);
          //Set this to empty string so when it fails loading in the image view,
          //signing runs again
          newImages.set(image, BLANK_URL);
        }
      }
      //This is stored, but not used in current render.
      //The next time exerciseData changes, we use it to populate exerciseParams.exerciseImages
      console.log('newImages', Array.from(newImages));
      if (nextExerciseImages) {
        setNextExerciseImages((previousState) => {
          for (const key of newImages.keys()) {
            previousState?.set(key, newImages.get(key) ?? BLANK_URL);
          }
          return previousState;
        });
      } else {
        setNextExerciseImages(newImages);
      }
    };
    generateSignedImageUrls(nextImages);
  };

  useEffect(() => {
    console.log('ExercisePage nextImages useEffect()');
    processNewImages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextImages]);

  const getExerciseImagesForImageKeys = (images: string[]) => {
    if (!nextExerciseImages) return;
    const exImgs: ExerciseImage[] = [];
    for (const img of images) {
      const signedUrl = nextExerciseImages.get(img);
      exImgs.push({ imageS3Key: img, signedImageUrl: signedUrl ? signedUrl : BLANK_URL });
    }
    return exImgs;
  };

  const setupExerciseParameters = () => {
    console.log('ExercisePage setupExerciseParameters()');
    if (!exerciseData) return;
    const nextEx = exerciseData.nextExercise.exercise;
    console.log(`Setup: ${exerciseData.nextExercise.exercise.exerciseId}`);
    //Set current exercise. Components that use 'exerciseParams' will be updated once set.
    console.log(nextEx.isComplete);
    if (nextEx) {
      if (nextEx.isComplete) {
        console.log('Warning: received already completed exercise');
        //If somehow we receive an already completed exercise, fetch the next exercise
        refetchExercise();
      } else {
        //Update exercise params
        let imageArray: string[] = [];
        switch (nextEx.exerciseType) {
          case ExerciseType.CriteriaSelectionExercise: {
            imageArray = [(nextEx as CriteriaSelectionExercise).image];
            exerciseParameters.labels = (nextEx as CriteriaSelectionExercise).criteria;
            exerciseParameters.images = imageArray;
            exerciseParameters.correctCriteriaCount = (nextEx as CriteriaSelectionExercise).correctCriteriaCount;
            break;
          }
          case ExerciseType.LabelSelectionExercise: {
            imageArray = [(nextEx as LabelSelectionExercise).image];
            exerciseParameters.labels = (nextEx as LabelSelectionExercise).labels;
            exerciseParameters.images = imageArray;
            break;
          }
          case ExerciseType.ImageSelectionExercise: {
            imageArray = (nextEx as ImageSelectionExercise).images;
            exerciseParameters.imageSelectionLabel = (nextEx as ImageSelectionExercise).targetLabel;
            exerciseParameters.images = imageArray;
            break;
          }
          case ExerciseType.ImageMatchExercise: {
            imageArray = (nextEx as ImageMatchExercise).images;
            exerciseParameters.labels = (nextEx as ImageMatchExercise).labels;
            exerciseParameters.images = imageArray;
          }
        }

        gameState.exerciseId = nextEx.exerciseId;
        window.localStorage.setItem(GAME_ELEMENT.GAME_STATE, JSON.stringify(gameState));
        exerciseParameters.mascot = mascots[Math.floor(Math.random() * mascots.length)];
        exerciseParameters.exercise = nextEx;
        exerciseParameters.exerciseType = nextEx.exerciseType;
        //The images for the next exercise.
        //Once setNextImages is called, presigning begins.
        const nextExImages = exerciseData.nextExercise.nextExerciseImages;
        //On the last exercise, nextExImages will be empty.
        //The next render should use existing nextExerciseImages
        //should the user get the last exercise incorrect.
        if (nextExImages && nextExImages.length === 0) {
          if (nextExerciseImages) {
            const exImgs = getExerciseImagesForImageKeys(exerciseParameters.images);
            if (exImgs) {
              exerciseParameters.exerciseImages = exImgs;
            }
          } else {
            console.log('Images for exercise unavailable');
          }
        } else {
          setNextImages(nextExImages);
        }

        //If we have firstExerciseImages, set those as the images for the exercise we just fetched.
        if (firstExerciseImages) {
          exerciseParameters.exerciseImages = firstExerciseImages;
          // console.log('First Images Set');
          //When the first exercise is checked and the next exercise is refetched,
          //we set firstExerciseImages to undefined. So the next time this code block runs,
          //we set the nextExerciseImages as exerciseImages
        } else if (nextExerciseImages) {
          const exImgs = getExerciseImagesForImageKeys(exerciseParameters.images);
          if (exImgs) {
            exerciseParameters.exerciseImages = exImgs;
          }
          // console.log('Next Images Set');
        } else {
          console.log('No exercise images available');
        }

        setExerciseCount(exerciseCount + 1);

        //All exercise params have already been set when we reach here.
        //This setExerciseParams will trigger a re-render of the exerciseView,
        //which will render based on the params we set earlier
        setExerciseParams(exerciseParameters);
      }
    }
  };

  useEffect(() => {
    console.log('ExercisePage completedExerciseCount useEffect()');
    if (!lesson) return;
    //When completedExerciseCount is updated, update lessonProgress
    if (completedExerciseCount === null || completedExerciseCount === undefined) return;
    const progress = (completedExerciseCount / lesson.exercises.length) * 100;
    setLessonProgress(progress);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completedExerciseCount]);

  const clearGameState = () => {
    window.localStorage.removeItem(GAME_ELEMENT.GAME_STATE);
  };

  const onContinueExercisePressed = async () => {
    console.log('ExercisePage onContinueExercisePressed()');
    exerciseParameters = {} as ExerciseParams;
    if (completedExerciseCount !== lesson?.exercises.length) {
      setIsTransitioningExercise(true);
      //If this is the first exercise, we set this to undefined so as to be
      //able to use pre-Signed images for the next exercise in line.
      if (firstExerciseImages) {
        setFirstExerciseImages(undefined);
      }
      //There's more exercises
      console.log('Continue pressed, awaiting updateExercise');
      await updateExercise();
    } else {
      //Completed all exercises, to get to lesson completion screen, refetch lesson
      refetchLesson();
    }
  };

  const onAnswerChecked = async (answerCorrect: boolean) => {
    console.log('ExercisePage onAnswerChecked()');
    //When exercise is checked, update completedExercise count if exercise was correctly answered.
    if (answerCorrect) {
      if (completedExerciseCount === null || completedExerciseCount === undefined) return;
      setCompletedExerciseCount(completedExerciseCount + 1);
      playCorrectSound();
    } else {
      playIncorrectSound();
    }

    if (completedExerciseCount !== lesson?.exercises.length) await refetchExercise();
  };

  return (
    <>
      {lessonCompleted ? (
        <LessonEnd
          accuracy={accuracy}
          xp={xp}
          onAcknowledged={() => {
            handleEndLesson();
          }}
        />
      ) : (
        <BoxedContainer justifyTop applyInsetTop>
          <ExerciseTitleBar progress={lessonProgress ?? 0} onBackClicked={handleExerciseBackClicked} />

          <ExerciseView
            // Set the key to the number of exercises shown, so that
            // when the user advances to the next exercise, a new ExerciseView is created.
            key={exerciseCount}
            exerciseParams={exerciseParams}
            isLoading={nextExerciseLoading || exerciseLoading || lessonLoading}
            isTransitioningToNextExercise={isTransitioningExercise}
            unitId={unitId}
            topicId={topicId}
            lessonId={lessonId}
            onAnswerChecked={onAnswerChecked}
            onContinueExercisePressed={onContinueExercisePressed}
          />
          <AppModal
            open={showQuitLessonConfirmation}
            onPositiveAction={handleContinueLesson}
            onNegativeAction={handleEndLesson}
            onClose={handleContinueLesson}
            modalType={AppModalType.EndLessonConfirmation}
          />
        </BoxedContainer>
      )}
    </>
  );
};

export default ExercisePage;
