import { Box, Card, CardActionArea, CardContent } from '@mui/material';
import { AvatarVariant } from 'graphql/generated/graphql';
import StyledAvatar from './StyledAvatar';

export interface SelectableAvatarProps {
  avatarVariant?: AvatarVariant;
  username?: string | undefined;
  image?: string | undefined;
  isSelected?: boolean;
  onClick: () => void;
}

export default function SelectableAvatar({
  avatarVariant,
  username,
  image,
  isSelected,
  onClick,
}: SelectableAvatarProps) {
  if (image) avatarVariant = AvatarVariant.Url;
  return (
    <Card
      variant="outlined"
      sx={{
        borderWidth: isSelected ? 2 : 0,
        borderRadius: 4.5,
        backgroundColor: isSelected ? 'primary.activeSelection' : 'primary.inactiveSelection',
        display: 'flex',
        width: 120,
        height: 120,
        justifySelf: 'center',
        borderColor: isSelected ? 'primary.main' : 'primary.inactiveSelection',
      }}
      onClick={onClick}
    >
      <CardActionArea sx={{ height: '100%' }}>
        <CardContent sx={{ height: '100%' }}>
          <Box
            flexDirection="column"
            display="flex"
            sx={{
              height: '100%',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <StyledAvatar
              username={username}
              avatarVariant={avatarVariant ?? AvatarVariant.LightInitial}
              url={image}
              style={{ width: '100%', height: '100%' }}
            />
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
