import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { Config, getConfig } from './Util/config';
import history from './Util/history';
import { Auth0Provider } from '@auth0/auth0-react';
import { ApolloProviderWithAuth0 } from 'Containers/ApolloProviderWithAuth0';
import { SnackbarKey, SnackbarProvider } from 'notistack';
import Snackbar from 'Util/SnackbarUtilsConfigurator';
import { Button } from '@mui/material';

const dismissNotification = (key: SnackbarKey): (() => void) => {
  return () => {
    Snackbar.closeSnackbar(key);
  };
};

const dismissAction = (key: SnackbarKey) => {
  return (
    <Button
      onClick={dismissNotification(key)}
      style={{
        height: '100%',
        left: 0,
        position: 'absolute',
        top: 0,
        width: '100%',
      }}
    />
  );
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const onRedirectCallback = (appState: any) => {
  history.push(appState && appState.returnTo ? appState.returnTo : window.location.pathname);
};

const config: Config = getConfig();
console.log('Version ::' + process.env.REACT_APP_VERSION);
root.render(
  <React.StrictMode>
    <Auth0Provider
      domain={config.auth0Domain}
      clientId={config.auth0ClientId}
      redirectUri={window.location.origin}
      audience={config.auth0Audience}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens
      cacheLocation="localstorage"
    >
      <ApolloProviderWithAuth0>
        <SnackbarProvider
          maxSnack={1}
          action={dismissAction}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
        >
          <App />
        </SnackbarProvider>
      </ApolloProviderWithAuth0>
    </Auth0Provider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
