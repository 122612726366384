import { Config, getConfig } from './config';
import { HttpRequest } from '@aws-sdk/protocol-http';
import { S3RequestPresigner } from '@aws-sdk/s3-request-presigner';
import { parseUrl } from '@aws-sdk/url-parser';
import { Sha256 } from '@aws-crypto/sha256-browser';
import { formatUrl } from '@aws-sdk/util-format-url';
import { fromWebToken } from '@aws-sdk/credential-providers';

export const presignUrl = async (key: string, webIdentityToken: string): Promise<string> => {
  const config: Config = getConfig();

  const region: string = config.region;

  const s3ObjectUrl = parseUrl(`https://${config.bucket}.s3.${config.region}.amazonaws.com/${key}`);
  const presigner = new S3RequestPresigner({
    credentials: fromWebToken({
      // Required. ARN of the role that the caller is assuming.
      roleArn: config.roleArn,
      // Required. The OAuth 2.0 access token or OpenID Connect ID token that is provided by the
      // identity provider.
      webIdentityToken: webIdentityToken,
    }),
    region,
    sha256: Sha256,
  });
  const url = formatUrl(await presigner.presign(new HttpRequest(s3ObjectUrl)));
  return url;
};

export const presignUrls = async (keys: string[], webIdentityToken: string): Promise<string[]> => {
  const signedKeys: string[] = [];
  for (const key of keys) {
    const url = await presignUrl(key, webIdentityToken);
    signedKeys.push(url);
  }
  return signedKeys;
};
