import BoxedContainer from 'Containers/BoxedContainer';
import TitleBar from 'Components/General/TitleBar';
import { BUTTON_TITLE, EDIT_PROFILE, EMPTY_JSON_STRING, GAME_ELEMENT, TABS } from 'Constants/keys';
import { Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import StyledTextField from 'Components/General/StyledTextField';
import { AvatarSelections } from 'Components/Avatar/AvatarSelections';
import CustomButton from './General/CustomButton';
import { AvatarVariant, Profile } from 'graphql/generated/graphql';
import { LoggedInUser } from 'Screens/Home/Tabs/Profile/ProfilePage';

export interface EditProfileProps {
  isUpdate?: boolean;
  onClick: (updatedProfile: Profile | string) => void;
  isLoading?: boolean;
}

export interface AvatarObject {
  type?: AvatarVariant;
  image?: string;
}

function EditProfile({ isUpdate, onClick, isLoading }: EditProfileProps) {
  const loggedInUser = JSON.parse(
    window.localStorage.getItem(GAME_ELEMENT.LOGGED_IN_USER) ?? EMPTY_JSON_STRING
  ) as LoggedInUser;
  const userProfile = loggedInUser?.profile;

  const [disabled, setDisabled] = useState(false);
  const [username, setUsername] = useState<string>(userProfile?.username ?? '');
  const [institution, setInstitution] = useState(userProfile?.institution ?? '');
  const [selectedAvatar, setSelectedAvatar] = useState<AvatarObject>();

  useEffect(() => {
    if (!username) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [username]);

  const avatarInitial = userProfile?.username ? userProfile.username : loggedInUser.email;

  function validateAndGetUpdatedProfile(
    username: string,
    institution: string,
    selectedAvatar?: AvatarObject
  ): Profile | string {
    const profile: Profile = {};
    if (!username) {
      return EDIT_PROFILE.USERNAME_EMPTY_ERROR;
    }

    if (selectedAvatar) {
      profile.avatarVariant = selectedAvatar.type;
      if (selectedAvatar.type === AvatarVariant.Url) {
        profile.avatarUrl = selectedAvatar.image;
      } else {
        profile.avatarUrl = '';
      }
    }

    if (username !== userProfile?.username) {
      profile.username = username;
    }

    if (institution !== userProfile?.institution) {
      profile.institution = institution;
    }

    return profile;
  }

  const title = isUpdate ? TABS.EDIT_PROFILE_TITLE : TABS.SETUP_PROFILE_TITLE;

  return (
    <BoxedContainer justifyTop applyInsetTop>
      <TitleBar title={title} hideIcon={!isUpdate} />

      <Stack
        mx={7}
        mt={{ xs: 1, mob: 6 }}
        spacing={{ xs: 4, mob: 8 }}
        sx={{
          // Leave space at the bottom of the stack for the Save/Continue button.
          mb: 10,
        }}
      >
        <Stack alignItems={'center'} spacing={{ xs: 6, mob: 11.75 }}>
          <Typography
            color="text.secondary"
            variant="body1"
            textAlign="center"
            textOverflow="ellipsis"
            noWrap
            width="90vw"
          >
            {loggedInUser?.email}
          </Typography>

          <StyledTextField
            id={EDIT_PROFILE.USERNAME_LABEL}
            value={username ?? ''}
            onChange={(e) => {
              setUsername(e.target.value);
            }}
            label={EDIT_PROFILE.USERNAME_LABEL}
            placeholder={EDIT_PROFILE.USERNAME_LABEL}
            variant="outlined"
            autoComplete="off"
          />
          <StyledTextField
            id={EDIT_PROFILE.INSTITUTION_LABEL}
            value={institution ?? ''}
            onChange={(e) => {
              setInstitution(e.target.value);
            }}
            label={EDIT_PROFILE.INSTITUTION_LABEL + EDIT_PROFILE.OPTIONAL_LABEL}
            placeholder={EDIT_PROFILE.INSTITUTION_LABEL}
            variant="outlined"
            autoComplete="off"
          />
        </Stack>
        <Typography variant="captionBold">{EDIT_PROFILE.PROFILE_ICON_LABEL}</Typography>
        <AvatarSelections
          username={avatarInitial ?? ''}
          onAvatarSelected={(avatar) => {
            setSelectedAvatar(avatar);
          }}
        />
      </Stack>
      <CustomButton
        loading={isLoading}
        disabled={disabled}
        style={{ position: 'sticky', bottom: { xs: 4, mob: 24 } }}
        title={isUpdate ? BUTTON_TITLE.SAVE : BUTTON_TITLE.CONTINUE}
        onPress={() => {
          const updatedProfile = validateAndGetUpdatedProfile(username, institution, selectedAvatar);
          onClick(updatedProfile);
        }}
      />
    </BoxedContainer>
  );
}

export default EditProfile;
