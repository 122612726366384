import { OptionsObject, SnackbarKey, useSnackbar, WithSnackbarProps } from 'notistack';
import useSound from 'use-sound';
import ErrorSound from 'Assets/Sounds/Error.wav';
import { PlayFunction } from 'use-sound/dist/types';

// Must be imported at least once in the app to initialize the ref
let snackbarRef: WithSnackbarProps;
let errorSound: PlayFunction;
export const SnackbarUtilsConfigurator = () => {
  snackbarRef = useSnackbar();
  const [playErrorSound] = useSound(ErrorSound);
  errorSound = playErrorSound;
  return null;
};

export default {
  success(msg: string, options: OptionsObject = {}): void {
    this.toast(msg, { ...options, variant: 'success' });
  },
  warning(msg: string, options: OptionsObject = {}): void {
    this.toast(msg, { ...options, variant: 'warning' });
  },
  info(msg: string, options: OptionsObject = {}): void {
    this.toast(msg, { ...options, variant: 'info' });
  },
  error(msg: string, options: OptionsObject = {}): void {
    errorSound();
    this.toast(msg, { ...options, variant: 'error' });
  },
  closeSnackbar(key: SnackbarKey): void {
    snackbarRef.closeSnackbar(key);
  },
  toast(msg: string, options: OptionsObject = {}): void {
    snackbarRef.enqueueSnackbar(msg, options);
  },
};
