import { Stack, SxProps, Theme, Typography } from '@mui/material';
import { ReactComponent as OnboardingRightTextArrow } from 'Assets/Icons/SVG/onboarding-right-text-arrow.svg';

export interface OnboardingContinueTextProps {
  text: string;
  style?: SxProps<Theme>;
}

// Displays the given text with a right-facing arrow next to it.
const OnboardingContinueText = ({ text, style }: OnboardingContinueTextProps) => {
  return (
    <Stack position="absolute" bottom={12} spacing={4} alignSelf="flex-end" direction="row" sx={style}>
      <Typography
        noWrap
        letterSpacing="1.2px"
        fontWeight="bold"
        fontSize="1.25rem"
        textAlign="right"
        lineHeight="1.625rem"
      >
        {text}
      </Typography>
      <OnboardingRightTextArrow style={{ marginTop: 4, marginRight: 40 }} />
    </Stack>
  );
};

export default OnboardingContinueText;
