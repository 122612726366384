import { Divider, Stack } from '@mui/material';
import { AvatarVariant, LeaderboardEntryWithProfile } from 'graphql/generated/graphql';
import LeaderboardItem, { LeaderboardItemProps } from './LeaderboardItem';
import { DELETED_USER, DELETED_USER_DISPLAY_NAME, EMPTY_STRING, LEADERBOARD } from 'Constants/keys';

// The leaderboard is a list of LeaderboardItems.
// If an error is encountered while creating the leaderboard, an Error will be thrown.
export interface LeaderboardProps {
  // The list of entries to show on the leaderboard. The component will query for the user
  // information of each user on the leaderboard.
  leaderboardEntries: LeaderboardEntryWithProfile[];
}

// Given a list of leaderboard entries sorted from highest to lowest experience,
// and the User data for each of those users in the leaderboard,
// returns an object containing the ranking number for each usrId and the other user info.
// This function assumes that leaderboardEntries is sorted from highest to lowest scoring user.
const CreateUserIdToUserInfoMap = (leaderboardEntries: LeaderboardEntryWithProfile[]) => {
  // type used in this function to represent the ranking and score of each user in the leaderboard.
  type RankingEntry = {
    rank: number;
    points: number;
  };

  const usernameToRankingNumbers = new Map<string, RankingEntry>();

  // If two users have the same XP value, they should have the same rank. Otherwise, each
  // user should be ranked one lower than the previous user.
  // Walk through the leaderboard and calculate the ranking for each user.

  let currentRank = 1;
  let previousXP = leaderboardEntries[0].xP;

  leaderboardEntries.forEach((value, index: number) => {
    if (previousXP > value.xP) {
      currentRank += 1;
    }

    if (!value.username) {
      //For deleted users, we'll create a prefix + index dummy username.
      value.username = DELETED_USER + index;
    }

    usernameToRankingNumbers.set(value.username, {
      rank: currentRank,
      points: value.xP,
    });

    previousXP = value.xP;
  });

  // This is a map of user IDs (eg. auth0|63168bf92dc9843a4f86049a) to information about that user.
  const userIdsToUserInfo = new Map<string, LeaderboardItemProps>();
  leaderboardEntries.forEach((entry, index: number) => {
    let username = entry.username;
    if (!username) {
      //For deleted users, we set the user to a prefix + index dummy username,
      //as it's the also the same in userIdsToRankingNumbers
      username = DELETED_USER + index;
    }
    const userRankingEntry = usernameToRankingNumbers.get(username);
    if (!userRankingEntry) {
      throw new Error('Missing user rank data.');
    } else {
      // Fill in this entry in the map with the data for this user.
      userIdsToUserInfo.set(username, {
        rank: userRankingEntry.rank,
        username: username.includes(DELETED_USER) ? DELETED_USER_DISPLAY_NAME : username,
        institution: entry.institution ?? EMPTY_STRING,
        points: userRankingEntry.points,
        avatarVariant: entry.avatarVariant ?? AvatarVariant.DarkInitial,
        url: entry.avatarUrl ?? EMPTY_STRING,
      });
    }
  });

  return userIdsToUserInfo;
};

const Leaderboard = ({ leaderboardEntries }: LeaderboardProps) => {
  const userInfoMap = CreateUserIdToUserInfoMap(leaderboardEntries);

  return (
    // Set bottom margin to ensure bottom of leaderboard clears navigation tab.
    <Stack mt={12} mb={20} divider={<Divider sx={{ bgcolor: 'white.main' }} variant="middle" />}>
      {leaderboardEntries.map((entry, index: number) => {
        let username = entry.username;
        if (!username) {
          //Default to Deleted User
          username = DELETED_USER + index;
        }
        const itemProps = userInfoMap.get(username);

        if (itemProps === undefined) {
          throw Error(LEADERBOARD.LEADERBOARD_USER_ERROR);
        }

        return <LeaderboardItem key={index} {...itemProps}></LeaderboardItem>;
      })}
      <Divider sx={{ bgcolor: 'white.main' }} variant="middle" />
    </Stack>
  );
};

export default Leaderboard;
