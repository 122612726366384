import { Box, Stack, Typography } from '@mui/material';
import LoadingView from 'Components/General/LoadingView';
import { GAME_TEXTS } from 'Constants/keys';
import { ExerciseState } from 'Enums/ExerciseEnums';
import {
  AnswerInput,
  CriteriaSelectionExercise,
  Exercise,
  ExerciseType,
  ImageMatchExercise,
  ImageSelectionExercise,
  KeyValuePairOfStringAndStringInput,
  LabelSelectionExercise,
  useCheckExerciseMutation,
} from 'graphql/generated/graphql';
import { useEffect, useState } from 'react';
import CriteriaSelectionExerciseView from './CriteriaSelection/CriteriaSelectionExerciseView';
import { ExerciseImage } from './ExerciseImageView';
import ExerciseResultBar from './ExerciseResultBar';
import ImageMatchExerciseView from './ImageMatch/ImageMatchExerciseView';
import ImageSelectionExerciseView from './ImageSelection/ImageSelectionExerciseView';
import LabelSelectionExerciseView from './LabelSelection/LabelSelectionExerciseView';
import ShowSnack from 'Util/SnackbarUtilsConfigurator';

// The exercise to show the user.
export interface ExerciseParams {
  exercise: Exercise;
  exerciseType: ExerciseType;
  images: string[];
  exerciseImages: ExerciseImage[];
  imageSelectionLabel?: string;
  labels?: string[];
  correctCriteriaCount?: number;
  mascot?: JSX.Element;
}

export interface ExerciseProps {
  exerciseParams?: ExerciseParams;
  // While isLoading is true, user is prevented from pressing the check/continue button.
  isLoading: boolean;
  isTransitioningToNextExercise: boolean;
  unitId?: number;
  topicId?: number;
  lessonId?: string;
  onAnswerChecked: (answerCorrect: boolean) => void;
  // Called when the user presses the button to continue from the
  // current exercise to the next one.
  onContinueExercisePressed: () => void;
}

// An ExerciseView represents a single exercise, from start to completion of that exercise.
const ExerciseView = ({
  exerciseParams,
  isLoading,
  isTransitioningToNextExercise: isFetchingNextExercise,
  unitId,
  topicId,
  lessonId,
  onAnswerChecked,
  onContinueExercisePressed: onContinuePressed,
}: ExerciseProps) => {
  //The answer chosen by the user.
  const [userAnswer, setUserAnswer] = useState<undefined | string | string[] | Map<string, string>>();

  //The index where the correct answer is. Used to show correct answer after exercise answer is checked.
  const [exerciseAnswerIndex, setExerciseAnswerIndex] = useState<undefined | number | string[] | Map<string, string>>();

  const [exerciseState, setExerciseState] = useState<ExerciseState>(ExerciseState.NotSelected);

  const timezoneIdentifier = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const [checkExerciseAnswer, { loading: checkingAnswer, error: checkAnswerError }] = useCheckExerciseMutation({});

  useEffect(() => {
    if (checkAnswerError) {
      console.log(checkAnswerError.message);
      ShowSnack.error(checkAnswerError.message);
    }
  }, [checkAnswerError]);

  const getExerciseTitle = (exerciseType?: ExerciseType) => {
    switch (exerciseType) {
      case ExerciseType.LabelSelectionExercise:
        return GAME_TEXTS.LABEL_SELECTION_EXERCISE_TITLE;
      case ExerciseType.ImageSelectionExercise:
        return GAME_TEXTS.IMAGE_SELECTION_EXERCISE_TITLE;
      case ExerciseType.ImageMatchExercise:
        return GAME_TEXTS.IMAGE_MATCH_EXERCISE_TITLE;
      case ExerciseType.CriteriaSelectionExercise:
        return GAME_TEXTS.CRITERIA_SELECTION_EXERCISE_TITLE;
    }
  };

  const getExerciseView = () => {
    switch (exerciseParams?.exerciseType) {
      case ExerciseType.LabelSelectionExercise:
        return (
          <LabelSelectionExerciseView
            exerciseImage={exerciseParams && exerciseParams.exerciseImages[0]}
            labels={(exerciseParams && exerciseParams.labels) ?? []}
            exerciseState={exerciseState}
            correctChoiceIndex={exerciseAnswerIndex as number}
            onLabelSelected={(labelSelectionIndex) => {
              if (exerciseParams && exerciseParams.labels) {
                setUserAnswer(exerciseParams.labels[labelSelectionIndex]);
                setExerciseState(ExerciseState.Selected);
              }
            }}
          />
        );
      case ExerciseType.ImageSelectionExercise:
        return (
          <ImageSelectionExerciseView
            exerciseImages={(exerciseParams && exerciseParams.exerciseImages) ?? []}
            exerciseState={exerciseState}
            correctChoiceIndex={exerciseAnswerIndex as number}
            onImageSelected={(imageSelectionIndex) => {
              if (exerciseParams && exerciseParams.exerciseImages) {
                setUserAnswer(exerciseParams.exerciseImages[imageSelectionIndex].imageS3Key);
                setExerciseState(ExerciseState.Selected);
              }
            }}
          />
        );
      case ExerciseType.ImageMatchExercise:
        return (
          <ImageMatchExerciseView
            exerciseImages={(exerciseParams && exerciseParams.exerciseImages) ?? []}
            labelOptions={exerciseParams?.labels ?? []}
            exerciseState={exerciseState}
            correctChoices={exerciseAnswerIndex as Map<string, string>}
            onSelectionChanged={(selections: Map<string, string>, isValid: boolean) => {
              if (exerciseParams && exerciseParams.exerciseImages && exerciseParams.labels) {
                if (isValid) {
                  setUserAnswer(selections);
                  setExerciseState(ExerciseState.Selected);
                } else {
                  setExerciseState(ExerciseState.NotSelected);
                }
              }
            }}
          />
        );
      case ExerciseType.CriteriaSelectionExercise:
        if (!exerciseParams?.correctCriteriaCount) return null;
        return (
          <CriteriaSelectionExerciseView
            exerciseImage={exerciseParams && exerciseParams.exerciseImages[0]}
            criteria={exerciseParams?.labels ?? []}
            exerciseState={exerciseState}
            correctChoices={exerciseAnswerIndex as string[]}
            correctCriteriaCount={exerciseParams && exerciseParams.correctCriteriaCount}
            onSelectionChanged={(selections) => {
              if (exerciseParams) {
                if (selections.length === (exerciseParams.exercise as CriteriaSelectionExercise).correctCriteriaCount) {
                  setUserAnswer(selections);
                  setExerciseState(ExerciseState.Selected);
                } else {
                  setExerciseState(ExerciseState.NotSelected);
                }
              }
            }}
          />
        );
    }
  };

  const exerciseTitleAndExercise = () => {
    return (
      // Set bottom margin to ensure exercise clears the exercise result bar.
      //The margin is equal to the configured height of the
      //exercise result bar. i.e 31 * 4 (Configured MUI spacing) = 124
      <Stack mb={31} height={{ mob: '75vh', tab: '85vh' }} sx={{ overflowY: 'auto' }}>
        <Stack direction={'row'} spacing={3} sx={{ marginLeft: 4, marginBottom: 1 }}>
          {exerciseParams && exerciseParams.mascot}
          <Stack display={'grid'} justifyContent={'center'}>
            <Typography variant={'exerciseTitle'} fontSize={{ xs: '1rem', mob: '1.5rem' }} alignSelf={'center'}>
              {getExerciseTitle(exerciseParams?.exerciseType)}
            </Typography>
            {exerciseParams?.exerciseType === ExerciseType.ImageSelectionExercise && (
              <Typography variant={'exerciseTitle'} fontWeight={'bold'}>
                {exerciseParams?.imageSelectionLabel ?? ''}
              </Typography>
            )}
          </Stack>
        </Stack>
        {exerciseParams && exerciseParams.exerciseImages ? getExerciseView() : <LoadingView />}
      </Stack>
    );
  };

  return (
    <>
      {isFetchingNextExercise ? <Box /> : exerciseParams && exerciseTitleAndExercise()}
      <ExerciseResultBar
        style={{ zIndex: 100 }}
        exerciseState={exerciseState}
        isLoading={isLoading || checkingAnswer}
        onButtonClicked={async () => {
          if (exerciseParams?.exercise) {
            //Set user selected answer as userInput as variable for checkAnswerMutation
            if (exerciseState === ExerciseState.Selected) {
              const answerInput: AnswerInput = {};
              switch (exerciseParams.exerciseType) {
                case ExerciseType.ImageMatchExercise: {
                  // The generated declaration expects this to be passed as
                  // an array of KeyValuePairOfStringAndStringInput, so peforming the conversion.
                  const processedUserAnswer: KeyValuePairOfStringAndStringInput[] = [];
                  for (const [lbl, img] of (userAnswer as Map<string, string>).entries()) {
                    processedUserAnswer.push({ key: lbl, value: img });
                  }
                  answerInput.selectedMap = processedUserAnswer;
                  break;
                }
                case ExerciseType.ImageSelectionExercise:
                  answerInput.selectedImage = userAnswer as string;
                  break;
                case ExerciseType.CriteriaSelectionExercise:
                  answerInput.selectedCriteria = userAnswer as string[];
                  break;
                case ExerciseType.LabelSelectionExercise:
                  answerInput.selectedLabel = userAnswer as string;
              }
              await checkExerciseAnswer({
                variables: {
                  exerciseAnswerInput: {
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    unitId: unitId!,
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    topicId: topicId!,
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    lessonId: lessonId!,
                    exerciseId: exerciseParams?.exercise.exerciseId,
                    exerciseAnswer: answerInput,
                    timezoneOffset: timezoneIdentifier,
                  },
                },
              })
                .then(async (response) => {
                  if (response.data?.checkExercise) {
                    const checkedExercise = response.data.checkExercise;

                    //When exercise is checked, update exercise state as correct or incorrect
                    setExerciseState(checkedExercise.isComplete ? ExerciseState.Correct : ExerciseState.Incorrect);

                    //Trigger an update to display the correct answer once the exercise is checked.
                    switch (checkedExercise.exerciseType) {
                      case ExerciseType.LabelSelectionExercise: {
                        const correctLabel = (checkedExercise as LabelSelectionExercise).correctLabel;
                        if (correctLabel) {
                          setExerciseAnswerIndex(exerciseParams?.labels?.indexOf(correctLabel));
                        }
                        break;
                      }
                      case ExerciseType.ImageMatchExercise: {
                        const correctMap = (checkedExercise as ImageMatchExercise).correctMap;
                        if (correctMap) {
                          // The generated declaration has correctMap as an array of KeyValuePairOfStringAndStringInput,
                          //so peforming the conversion to a map for easier management of values in the ImageMatchExercise.
                          const answerMap = new Map<string, string>();
                          correctMap.forEach((x) => {
                            answerMap.set(x.key, x.value);
                          });
                          setExerciseAnswerIndex(answerMap);
                        }
                        break;
                      }
                      case ExerciseType.ImageSelectionExercise: {
                        const correctImage = (checkedExercise as ImageSelectionExercise).correctImage;
                        if (correctImage) {
                          const correctExImage = exerciseParams?.exerciseImages?.find(
                            (exImg) => exImg.imageS3Key === correctImage
                          );
                          if (correctExImage) {
                            setExerciseAnswerIndex(exerciseParams?.exerciseImages?.indexOf(correctExImage));
                          }
                        }
                        break;
                      }
                      case ExerciseType.CriteriaSelectionExercise: {
                        const correctCriteria = (checkedExercise as CriteriaSelectionExercise).correctCriteria;
                        if (correctCriteria) {
                          setExerciseAnswerIndex(correctCriteria);
                        }
                        break;
                      }
                    }

                    await onAnswerChecked(checkedExercise.isComplete);
                  } else {
                    const errorMessage = 'Failed to check answer.';
                    console.log(errorMessage);
                    ShowSnack.error(errorMessage);
                  }
                })
                .catch((error) => console.log(error));
            } else if (exerciseState === ExerciseState.Correct || ExerciseState.Incorrect) {
              onContinuePressed();
            }
          }
        }}
      />
    </>
  );
};

export default ExerciseView;
