import BoxedContainer from 'Containers/BoxedContainer';
import TitleBar from 'Components/General/TitleBar';
import { LEADERBOARD, TABS } from 'Constants/keys';
import LeaderboardInfo from 'Components/Leaderboard/LeaderboardInfo';
import { useDailyLeaderboardQuery, useDailyLeaderboardRankingQuery } from 'graphql/generated/graphql';
import { Typography } from '@mui/material';
import Leaderboard from 'Components/Leaderboard/Leaderboard';
import { useEffect } from 'react';
import { NetworkStatus } from '@apollo/client';
import ErrorScreen from 'Screens/ErrorScreen';
import LoadingView from 'Components/General/LoadingView';

const MAX_NUMBER_OF_LEADERBOARD_ENTRIES = 20;

// The leaderboard page. The implementation makes the following assumptions:
//    The list of leaderboard entries returned by the backend is sorted from highest to lowest experience.
const LeaderboardPage = () => {
  // Re render the leaderboard page every 30 seconds in order to live update results
  const LEADERBOARD_REFRESH_INTERVAL_MS = 30000;

  useEffect(() => {
    const interval = setInterval(() => {
      refetchLeaderboard();
      refetchPlayerRank();
    }, LEADERBOARD_REFRESH_INTERVAL_MS);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, []);

  // Get the leaderboard and the current user's rank.
  // Use network-only fetchPolicy to ensure it is up to date when the page is loaded.
  const {
    data: dailyLeaderboard,
    loading: dailyLeaderboardLoading,
    error: dailyLeaderboardError,
    refetch: refetchLeaderboard,
    networkStatus: leaderboardNetworkStatus,
  } = useDailyLeaderboardQuery({
    fetchPolicy: 'network-only',
    variables: { limit: MAX_NUMBER_OF_LEADERBOARD_ENTRIES },
    notifyOnNetworkStatusChange: true,
  });

  const {
    data: playerRank,
    loading: playerRankLoading,
    error: playerRankError,
    refetch: refetchPlayerRank,
    networkStatus: playerRankNetworkStatus,
  } = useDailyLeaderboardRankingQuery({ fetchPolicy: 'network-only', notifyOnNetworkStatusChange: true });

  if (
    dailyLeaderboardLoading ||
    playerRankLoading ||
    leaderboardNetworkStatus === NetworkStatus.refetch ||
    playerRankNetworkStatus === NetworkStatus.refetch
  ) {
    return (
      <BoxedContainer justifyTop={true}>
        <TitleBar title={TABS.LEADERBOARD} hideIcon />
        <LoadingView />
      </BoxedContainer>
    );
  }

  if (dailyLeaderboardError || playerRankError) {
    const errorMessage = dailyLeaderboardError ? dailyLeaderboardError.message : playerRankError?.message;

    return (
      <ErrorScreen
        pageTitle={TABS.LEADERBOARD}
        message={errorMessage}
        onRetry={() => {
          refetchLeaderboard();
          refetchPlayerRank();
        }}
      />
    );
  }

  const numberofRankedUsers = playerRank?.dailyLeaderboardRanking?.totalEntries ?? 0;

  let leaderboardComponent;

  if (dailyLeaderboard?.dailyLeaderboard && numberofRankedUsers > 0) {
    // If we've received a leaderboard, create that component.
    try {
      leaderboardComponent = <Leaderboard leaderboardEntries={dailyLeaderboard?.dailyLeaderboard?.entries} />;
    } catch (error) {
      console.log(error);

      return (
        <ErrorScreen
          pageTitle={TABS.LEADERBOARD}
          onRetry={() => {
            refetchLeaderboard();
            refetchPlayerRank();
          }}
        />
      );
    }
  } else {
    // Otherwise, show the text for when the leaderboard is empty.
    leaderboardComponent = (
      <>
        <Typography
          ml={15}
          mr={15}
          fontSize={'2rem'}
          fontWeight={'bold'}
          lineHeight={'2.6875rem'}
          color="text.primary"
          sx={{ textAlign: 'center' }}
        >
          {LEADERBOARD.EMPTY_LEADERBOARD_TEXT}
        </Typography>
        <Typography
          mt={4}
          ml={20}
          mr={20}
          lineHeight={'1.5rem'}
          variant="body1"
          color="text.primary"
          sx={{ textAlign: 'center' }}
        >
          {LEADERBOARD.EMPTY_LEADERBOARD_SUBTEXT}
        </Typography>
      </>
    );
  }

  return (
    <BoxedContainer justifyTop={true}>
      <TitleBar title={TABS.LEADERBOARD} hideIcon />
      <LeaderboardInfo
        pointsEarnedToday={playerRank?.dailyLeaderboardRanking?.xP}
        currentRank={playerRank?.dailyLeaderboardRanking?.rank}
        numberOfRankedUsers={numberofRankedUsers}
        end={dailyLeaderboard?.dailyLeaderboard?.end && new Date(dailyLeaderboard?.dailyLeaderboard?.end)}
      ></LeaderboardInfo>
      {leaderboardComponent}
    </BoxedContainer>
  );
};

export default LeaderboardPage;
