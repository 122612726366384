import { Box, Card, Stack, Typography } from '@mui/material';
import { ACHIEVEMENT_TEXTS } from 'Constants/keys';
import { ReactComponent as Flame } from 'Assets/Icons/SVG/streak-flame.svg';
import { ReactComponent as GreyFlame } from 'Assets/Icons/SVG/streak-grey-flame.svg';

export interface StreakDisplayProps {
  days: number;
  // Whether the user has completed a lesson today.
  lessonCompletedToday: boolean;
  // If true, display will use a larger flame image and font.
  isLarge?: boolean;
}

const StreakDisplay = ({ days, lessonCompletedToday, isLarge }: StreakDisplayProps) => {
  // Display a different colour if the streak is at 0.
  const hasStreak = days > 0;
  const backgroundColor = hasStreak ? 'transparent' : 'lesson.streakCenter';

  // Display different text depending on whether it should be a plural or not.
  const dayText = days !== 1 ? ACHIEVEMENT_TEXTS.DAYS : ACHIEVEMENT_TEXTS.ONE_DAY;

  // Display the appropriate flame background image, depending on whether
  // the user has a streak and has completed a lesson today.
  const flameImage = hasStreak ? (
    lessonCompletedToday ? (
      <Flame
        style={{ width: isLarge ? 200 : undefined, height: isLarge ? 200 : undefined, position: 'absolute', zIndex: 1 }}
      />
    ) : (
      <GreyFlame style={{ position: 'absolute', zIndex: 1 }} />
    )
  ) : undefined;

  return (
    <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
      {flameImage}

      <Card
        variant="outlined"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '50%',
          width: isLarge ? 200 : 65,
          height: isLarge ? 200 : 65,
          marginLeft: hasStreak ? 5 : 0,
          marginTop: hasStreak ? 0 : 1,
          borderColor: backgroundColor,
          backgroundColor: backgroundColor,
          zIndex: 2,
        }}
      >
        {isLarge ? (
          <Stack alignItems={'center'} ml={6} mt={6}>
            <Typography
              fontSize="4.1875rem"
              lineHeight="5.5rem"
              fontWeight="bold"
              letterSpacing="1.21px"
              align="center"
            >
              {days?.toFixed(0).toString()}
            </Typography>
            <Typography
              fontWeight="regular"
              fontSize="1.3125rem"
              align="center"
              letterSpacing="1.26px"
              lineHeight="1.875rem"
              textTransform="uppercase"
              color="white.main"
            >
              {dayText}
            </Typography>
          </Stack>
        ) : (
          <Stack alignItems={'center'} mt={hasStreak ? 4 : 0}>
            <Typography variant="title" lineHeight="28px" letterSpacing="0.58px" align="center">
              {days?.toFixed(0).toString()}
            </Typography>
            <Typography
              variant="caption"
              align="center"
              letterSpacing="0.96px"
              lineHeight="14px"
              textTransform="uppercase"
              color="white.main"
            >
              {dayText}
            </Typography>
          </Stack>
        )}
      </Card>
    </Box>
  );
};

export default StreakDisplay;
