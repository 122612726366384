import { Paper, Box, Stack, Typography } from '@mui/material';
import CustomButton from 'Components/General/CustomButton';
import { BUTTON_TITLE, DELETE_PROFILE, STATIC_CONTENT } from 'Constants/keys';
import { UserDeleteCall } from './AppModal';
import LoadingView from './LoadingView';
import { Config, getConfig } from 'Util/config';
import { useState } from 'react';

export interface DeleteAccountConfirmationProps {
  onContinueClicked: () => void;
  onEndClicked: () => void;
  deleteState?: UserDeleteCall;
}

const DeleteAccountConfirmation = ({
  onContinueClicked,
  onEndClicked,
  deleteState,
}: DeleteAccountConfirmationProps) => {
  const config: Config = getConfig();
  const SEND_ERROR_EMAIL = `mailto:${STATIC_CONTENT.SUPPORT_EMAIL}?subject=[${config.auth0ClientId}]`;

  const [texts] = useState({
    tryAgain: DELETE_PROFILE.TRY_AGAIN,
    confirmTitle: DELETE_PROFILE.CONFIRMED_TITLE,
    partialSuccessTitle: DELETE_PROFILE.PARTIAL_SUCCESS_TITLE,
    guardTitle: DELETE_PROFILE.GUARD_TITLE,
    confirmBody: DELETE_PROFILE.CONFIRMED_BODY,
    partialSuccessBody: DELETE_PROFILE.PARTIAL_SUCCESS_BODY,
    guardBody: DELETE_PROFILE.GUARD_BODY,
  });

  const getTitle = () => {
    switch (deleteState) {
      case UserDeleteCall.Failure:
        return texts.tryAgain;
      case UserDeleteCall.Success:
        return texts.confirmTitle;
      case UserDeleteCall.PartialSuccess:
        return texts.partialSuccessTitle;
      default:
        return texts.guardTitle;
    }
  };

  const getBody = () => {
    switch (deleteState) {
      case UserDeleteCall.Success:
        return texts.confirmBody;
      case UserDeleteCall.PartialSuccess:
        return texts.partialSuccessBody;
      default:
        return texts.guardBody;
    }
  };

  return (
    <Paper
      sx={{
        backgroundColor: 'secondary.dark',
        width: { xs: '87vw', mob: '87vw', tab: 341 },
      }}
    >
      {deleteState == UserDeleteCall.InProgress ? (
        <LoadingView />
      ) : (
        <Box width={1} height={1} justifyContent={'center'} display="flex">
          <Stack mt={8} mx={{ xs: 6, mob: 5 }} alignItems={'center'} justifyContent={'top'}>
            <Typography variant="h1" color="white" fontWeight={'bold'} sx={{ textAlign: 'center' }}>
              {getTitle()}
            </Typography>
            <Typography
              mt={{ xs: 3, mob: 6.5 }}
              variant="body1"
              color="white"
              sx={{ textAlign: 'left' }}
              whiteSpace={'pre-line'}
            >
              {getBody()}
            </Typography>

            {/* Show only prior to deletion confirmation */}
            {deleteState != UserDeleteCall.Success && deleteState != UserDeleteCall.PartialSuccess && (
              <CustomButton
                title={BUTTON_TITLE.RETURN_APP}
                onPress={() => {
                  onContinueClicked();
                }}
                style={{ marginTop: { xs: 3, mob: 8 } }}
              />
            )}
            {deleteState == UserDeleteCall.PartialSuccess && (
              <CustomButton
                style={{ mt: 6, height: 50 }}
                title={BUTTON_TITLE.EMAIL}
                onPress={async () => window.open(SEND_ERROR_EMAIL)}
              />
            )}
            <CustomButton
              type="outlined"
              title={deleteState == UserDeleteCall.Success ? BUTTON_TITLE.RETURN_SITE : BUTTON_TITLE.DELETE}
              onPress={() => {
                onEndClicked();
              }}
              style={{ marginTop: 4, mb: 10 }}
            />
          </Stack>
        </Box>
      )}
    </Paper>
  );
};

export default DeleteAccountConfirmation;
