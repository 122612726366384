import { Box, Card, Stack, Typography, useTheme } from '@mui/material';
import { ONBOARDING } from 'Constants/keys';
import { ReactComponent as Mascot } from 'Assets/Mascots/Lesson/CompletionMascot1.svg';
import { useEffect } from 'react';
import OnboardingContinueText from 'Components/General/OnboardingContinueText';
import OnboardingCard from 'Components/General/OnboardingCard';
import OnboardingProgression from 'Assets/Images/onboarding-progression.png';

export enum OnboardingStage {
  FirstStage,
  SecondStage,
  ThirdStage,
  FourthStage,
  Complete,
}

export interface OnboardingDialogProps {
  // Which of the onboarding screens to display. If stage is Complete, no screen will be displayed.
  stage: OnboardingStage;
  // Callback to call when the user clicks on the onboarding screen.
  onClick: () => void;
}

// This component displays the onboarding screens.
const Onboarding = ({ stage, onClick: onClick }: OnboardingDialogProps) => {
  const theme = useTheme();

  const onboardingDimColour = theme.palette.tertiary.dark + 'CD';

  useEffect(() => {
    // After the component renders, prevent the user from scrolling.
    // This is necessary because the lessons page can scroll, but we want to make
    // sure the onboarding page always shows the top of the lessons page.
    if (stage !== OnboardingStage.Complete) {
      document.body.style.overflow = 'hidden';
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    return () => {
      document.body.style.overflow = 'scroll';
    };
  });

  if (stage === OnboardingStage.FirstStage) {
    console.log('Onboarding first screen.');
    return (
      <Box position="fixed" left="0vw" width="100vw" height="100vh" zIndex={100} onClick={onClick}>
        <Box
          bgcolor="primary.appBackground"
          flexDirection="column"
          display="flex"
          height="100vh"
          width="100vw"
          justifyContent="center"
          zIndex={75}
        >
          <Typography textAlign="center" width="70vw" variant="h1" mb={4}>
            {ONBOARDING.FIRST_STAGE_UPPER}
          </Typography>
          <Mascot style={{ width: 260, alignSelf: 'center' }} />
          <Typography textAlign="center" width="70vw" variant="h1" mt={6}>
            {ONBOARDING.FIRST_STAGE_LOWER}
          </Typography>
          <OnboardingContinueText
            style={{
              display: 'flex',
              position: 'fixed',
              justifyContent: 'flex-end',
              width: { xs: '100vw', tab: '640px' },
              bottom: 0,
              alignSelf: 'center',
              mr: 8,
              mb: 3,
            }}
            text={ONBOARDING.CONTINUE}
          />
        </Box>
      </Box>
    );
  } else if (stage === OnboardingStage.SecondStage) {
    console.log('Onboarding second screen');
    return (
      <Box>
        {/* This box captures clicks anywhere on the screen.*/}
        <Box position="fixed" left="0vw" width="100vw" height="100vh" top="0vh" zIndex={100} onClick={onClick} />
        <OnboardingCard cardText={ONBOARDING.SECOND_STAGE} continueText={ONBOARDING.CONTINUE} />
      </Box>
    );
  } else if (stage === OnboardingStage.ThirdStage) {
    console.log(`Onboarding third screen`);

    return (
      <Box>
        {/* This box captures clicks anywhere on the screen.*/}
        <Box position="fixed" left="0vw" width="100vw" height="100vh" top="0vh" zIndex={100} onClick={onClick} />

        <Box
          position="fixed"
          display="flex"
          width={{ xs: '96vw', tab: '630px' }}
          ml={{ xs: '2vw', tab: '5px' }}
          bottom="32vh"
          alignSelf="center"
          component="img"
          src={OnboardingProgression}
          zIndex={150}
          decoding="sync" // Load image synchronously as a mitigation for EX-340.
        />
        <Card
          sx={{
            position: 'fixed',
            // Show a background that obscures everything except for the first topic.
            background: onboardingDimColour,
            width: '100vw',
            height: '100vh',
            top: '0vh',
            left: '0vw',
            zIndex: 50,
          }}
        ></Card>
        <OnboardingCard cardText={ONBOARDING.THIRD_STAGE} continueText={ONBOARDING.CONTINUE} />
      </Box>
    );
  } else if (stage === OnboardingStage.FourthStage) {
    console.log(`Onboarding fourth screen`);

    return (
      <Box>
        {/* This box captures clicks anywhere on the screen.*/}
        <Box position="fixed" left="0vw" width="100vw" height="100vh" top="0vh" zIndex={100} onClick={onClick} />
        <Card
          sx={{
            position: 'fixed',
            // Show a background that obscures everything except for the first topic.
            background: onboardingDimColour,
            width: '100vw',
            height: '100vh',
            top: '0vh',
            left: '0vw',
            zIndex: 50,
          }}
        ></Card>
        <OnboardingCard cardText={ONBOARDING.FOURTH_STAGE} continueText={ONBOARDING.LETS_BEGIN} />
      </Box>
    );
  } else {
    // Onboarding is complete, so don't display anything.
    return <></>;
  }
};

export default Onboarding;
