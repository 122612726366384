import { ReactComponent as Correct } from 'Assets/Icons/SVG/radio-correct.svg';
import { ReactComponent as Incorrect } from 'Assets/Icons/SVG/radio-incorrect.svg';
import CircleIcon from '@mui/icons-material/Circle';
import Card from '@mui/material/Card';
import SvgIcon from '@mui/icons-material/Circle';

export interface LabelSelectionRadioIconProps {
  color: string;
  isSelected: boolean;
  isCorrect?: boolean;
  isIncorrect?: boolean;
}

const LabelSelectionRadioIcon = ({ color, isSelected, isCorrect, isIncorrect }: LabelSelectionRadioIconProps) => {
  const style = { color: color, fontSize: '1.25rem' };
  const getChildIcon = () => {
    if (isSelected) {
      if (isCorrect) {
        return <SvgIcon component={Correct} sx={style} />;
      } else if (isIncorrect) {
        return <SvgIcon component={Incorrect} sx={style} />;
      } else {
        return <CircleIcon sx={style} />;
      }
    } else {
      if (isCorrect) {
        return <SvgIcon component={Correct} sx={style} />;
      } else {
        return <></>;
      }
    }
  };

  return (
    <Card
      variant={'outlined'}
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        backgroundColor: 'transparent',
        height: 24,
        width: 24,
        borderColor: color,
        display: 'flex',
      }}
    >
      {getChildIcon()}
    </Card>
  );
};

export default LabelSelectionRadioIcon;
