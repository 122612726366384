import { Routes, Route } from 'react-router-dom';
import { ROUTE_PATHS } from 'Routes/RouteKeys';
import LeaderboardPage from './Tabs/LeaderboardPage';
import BottomNavBar from 'Components/General/BottomNavBar';
import AchievementsPage from './Tabs/AchievementsPage';
import LessonsPage from './Tabs/Lessons/LessonsPage';
import ProfilePage from './Tabs/Profile/ProfilePage';
import BoxedContainer from 'Containers/BoxedContainer';

const HomePage = () => {
  return (
    <BoxedContainer style={{ alignItems: 'center' }} applyInsetTop>
      <Routes>
        <Route index element={<LessonsPage />} />
        <Route path={ROUTE_PATHS.ROOT + ROUTE_PATHS.LESSONS + ROUTE_PATHS.WILDCARD} element={<LessonsPage />} />
        <Route
          path={ROUTE_PATHS.ROOT + ROUTE_PATHS.ACHIEVEMENTS + ROUTE_PATHS.WILDCARD}
          element={<AchievementsPage />}
        />
        <Route path={ROUTE_PATHS.ROOT + ROUTE_PATHS.LEADERBOARD + ROUTE_PATHS.WILDCARD} element={<LeaderboardPage />} />
        <Route path={ROUTE_PATHS.ROOT + ROUTE_PATHS.PROFILE + ROUTE_PATHS.WILDCARD} element={<ProfilePage />} />
      </Routes>
      <BottomNavBar />
    </BoxedContainer>
  );
};

export default HomePage;
