import { useAuth0 } from '@auth0/auth0-react';
import { AppBar, Box, Button, Container, Divider, Stack, Toolbar, Typography } from '@mui/material';
import Logo from 'Assets/Icons/PNG/hologic@2x.png';
import LandingImage from 'Assets/Images/landing-page.png';
import CellBackground from 'Assets/Images/Cells-BG.jpg';
import OoliGIF from 'Assets/Mascots/Ooli-Fade.gif';
import { ReactComponent as Mascot } from 'Assets/Mascots/Lesson/CompletionMascot1.svg';
import { ReactComponent as AppName } from 'Assets/Images/AppName.svg';
import { ReactComponent as Arrow } from 'Assets/Icons/SVG/arrow.svg';
import { ReactComponent as CharacterStrip } from 'Assets/Mascots/CharacterStrip.svg';
import FooterLink from 'Components/General/FooterLink';
import CustomButton from 'Components/General/CustomButton';
import { BUTTON_TITLE, FOOTER, LANDING, SIGN_UP_DIRECTIVE } from 'Constants/keys';
import { ROUTE_PATHS } from 'Routes/RouteKeys';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import SplashContext from 'Context/SplashContext';
import ShowSnack from 'Util/SnackbarUtilsConfigurator';
import UpdateLoggedInUser from 'Util/LocalStorageFunctions';
import { useLoggedInMutation } from 'graphql/generated/graphql';
import ErrorScreen from './ErrorScreen';

const LandingPage = () => {
  const [searchParams] = useSearchParams();
  const [errorPresent, setErrorPresent] = useState<boolean>(false);
  const [redirecting, setRedirecting] = useState<boolean>(false);
  const [loggedIn] = useLoggedInMutation({});
  const [loadingFailed, setLoadingFailed] = useState<string>('');
  const [disableButtons, setDisabled] = useState<boolean>(false);

  const navigate = useNavigate();
  const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const splashContext = useContext(SplashContext);
  const boxHeight = 350;
  const buttonHeight = 50;
  useEffect(() => {
    splashContext?.setShowSplash(true);
    if (searchParams.get(LANDING.ERROR)) {
      setErrorPresent(true);
    }

    if (searchParams.get(LANDING.MESSAGE)) {
      if (searchParams.get(LANDING.MESSAGE) === LANDING.EMAIL_VERIFIED) {
        setRedirecting(true);
        loginWithRedirect({});
      }
    }

    if (isAuthenticated) {
      loggedIn({})
        .then((response) => {
          if (response?.data?.loggedIn.id && response?.data?.loggedIn.profile) {
            UpdateLoggedInUser(response?.data?.loggedIn.id, response?.data?.loggedIn.profile, user?.email);

            splashContext?.setShowSplash(false);
            if (!response?.data?.loggedIn.profile?.username) {
              navigate(ROUTE_PATHS.ROOT + ROUTE_PATHS.PRIVATE + ROUTE_PATHS.SETUP_PROFILE);
            } else {
              navigate(ROUTE_PATHS.ROOT + ROUTE_PATHS.PRIVATE);
            }
          }
        })
        .catch((error) => {
          setLoadingFailed(error.message);
        });
    } else {
      if (isLoading) {
        splashContext?.setShowSplash(isLoading);
      } else {
        splashContext?.setShowSplash(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isAuthenticated]);

  const auth0SignUp = () => {
    setDisabled(true);
    loginWithRedirect({ initialScreen: SIGN_UP_DIRECTIVE }).catch((error) => {
      setDisabled(false);
    });
  };

  if (loadingFailed) {
    splashContext?.setShowSplash(false);
    return <ErrorScreen message={loadingFailed} />;
  }

  return (
    <Container disableGutters maxWidth="des" sx={{ paddingTop: 'env(safe-area-inset-top)' }}>
      <AppBar sx={{ zIndex: 99, paddingTop: 'env(safe-area-inset-top)' }}>
        <Toolbar>
          <Box component={'img'} src={Logo} alt="logo" width={169} />
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          backgroundColor: 'tertiary.dark',
          height: 'env(safe-area-inset-bottom)',
          width: '100%',
          position: 'fixed',
          bottom: 0,
          zIndex: 99,
        }}
      />

      {searchParams.get(LANDING.ERROR) == 'unauthorized' && (
        <Box
          mt={12}
          p={2}
          pt={4}
          flexDirection="row"
          display="flex"
          sx={{
            height: 50,
            width: '100%',
            backgroundColor: 'lesson.max',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            disabled={disableButtons}
            style={{ position: 'absolute', width: '100%', height: '100%', opacity: 0 }}
            variant={'contained'}
            onClick={() => {
              const data = searchParams.get(LANDING.ERROR_DESCRIPTION);
              const arr = data?.split(':');
              if (arr != null)
                navigate(ROUTE_PATHS.ROOT + ROUTE_PATHS.VERIFY, { state: { email: arr[2], authToken: arr[1] } });
            }}
          ></Button>
          <Typography mr={2} textAlign={'center'} variant="subtitle2">
            {LANDING.VERIFY_EMAIL}
          </Typography>
          <Arrow />
        </Box>
      )}

      <Box sx={{ position: 'relative' }}>
        <Box sx={{ height: boxHeight, zIndex: 1, width: '100%' }} component="img" alt="bg" src={LandingImage} />
        <Box
          sx={{
            position: 'absolute',
            height: boxHeight,
            top: 0,
            left: 0,
            width: '100%',
            zIndex: 2,
            backgroundColor: 'primary.dark',
            opacity: [0.5],
          }}
        />
        <Box
          flexDirection="column"
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            position: 'absolute',
            height: boxHeight,
            top: 0,
            left: 0,
            width: '100%',
            zIndex: 3,
          }}
        >
          <Box alignItems="center" justifyContent="center" flexDirection="column" display="flex" mx={15.25} mt={8}>
            <AppName style={{ marginTop: 2 }} />
            <CustomButton
              disabled={disableButtons}
              style={{ mt: 10, height: buttonHeight }}
              title={BUTTON_TITLE.LOGIN}
              onPress={() => {
                setDisabled(true);
                loginWithRedirect({}).catch((error) => {
                  setDisabled(false);
                });
              }}
            />
            <CustomButton
              disabled={disableButtons}
              style={{ mt: 6, height: buttonHeight }}
              title={BUTTON_TITLE.SIGNUP}
              type="outlined"
              onPress={auth0SignUp}
            />
          </Box>
        </Box>
      </Box>
      <Stack spacing={2} sx={{ mt: -2, pt: 8, backgroundColor: 'tertiary.light' }}>
        {redirecting && (
          <Box>
            <Typography variant="h3" color="text.primary" align="center">
              {searchParams.get(LANDING.REDIRECTING)}
            </Typography>
          </Box>
        )}
        <Typography variant="h1" textAlign="center" color="text.primary">
          {LANDING.SECTION_TITLE}
        </Typography>
        <Box sx={{ height: boxHeight, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
          <Box
            maxWidth="des"
            sx={{ height: boxHeight, width: '100%', position: 'absolute' }}
            component="img"
            alt="bg"
            src={CellBackground}
          />
          <img src={OoliGIF} style={{ zIndex: 1, height: boxHeight / 1.5, marginTop: 50 }} alt="loading..." />
        </Box>
        <Typography px={3} variant="body1" textAlign="center" color="text.primary" whiteSpace={'pre-line'}>
          {LANDING.SECTION_BODY}
        </Typography>
        <Typography px={3} variant="body2" textAlign="left" color="text.primary" whiteSpace={'pre-line'}>
          {LANDING.SECTION_BULLETS}
        </Typography>
        <Box flex={1} />
        <CharacterStrip style={{ width: '100%', alignSelf: 'center' }} />
      </Stack>

      <Stack sx={{ px: 2, backgroundColor: 'tertiary.dark' }}>
        <Stack spacing={8} px={10}>
          <Typography variant="body1" color="white.main" textAlign="center" pt={8}>
            {LANDING.FOOTER_TITLE}
          </Typography>

          <Typography variant="body1" color="white.main" textAlign="center">
            {LANDING.FOOTER_BODY}
          </Typography>

          <CustomButton
            disabled={disableButtons}
            style={{ height: buttonHeight }}
            title={BUTTON_TITLE.GET_STARTED}
            onPress={auth0SignUp}
          />
        </Stack>

        <Divider sx={{ mt: 14.5, mb: 4.5, bgcolor: 'tertiary.contrastText' }} />

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={{ xs: 8, mob: 10.25 }}
          sx={{ pb: 6 }}
        >
          <FooterLink title={FOOTER.CONTACT} route={ROUTE_PATHS.CONTACT} />
          <FooterLink title={FOOTER.TERMS} route={ROUTE_PATHS.TERMS} />
          <FooterLink title={FOOTER.PRIVACY} route={ROUTE_PATHS.PRIVACY} />
          <FooterLink title={FOOTER.LICENSE} route={ROUTE_PATHS.LICENSE} />
        </Stack>
        <Box sx={{ height: 'env(safe-area-inset-bottom)' }} />
      </Stack>
    </Container>
  );
};

export default LandingPage;
