import { Stack, useMediaQuery, useTheme } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { ExerciseState } from 'Enums/ExerciseEnums';
import { useCallback, useEffect, useState } from 'react';
import CriteriaItem from '../CriteriaSelection/CriteriaItem';
import ImageMatchItem from './ImageMatchItem';
import { EMPTY_STRING } from 'Constants/keys';
import { DndProvider } from 'react-dnd-multi-backend';
import { HTML5toTouch } from 'rdndmb-html5-to-touch';
import { CriteriaDragPreview } from '../CriteriaSelection/CriteriaSelectionExerciseView';
import { currentTimestamp } from 'Util/timestamp';
import { ExerciseImage } from '../ExerciseImageView';
import { DropZone } from '../CriteriaSelection/DropZone';

export interface ImageMatchExerciseViewProps {
  exerciseImages: ExerciseImage[];
  labelOptions: string[];
  exerciseState: ExerciseState;
  correctChoices?: Map<string, string>;
  onSelectionChanged: (selections: Map<string, string>, isSelectionValid: boolean) => void;
}

export interface CriteraSelectionState {
  selectedCriteria: string[];
  dropArea: string[];
  criteria: string[];
}

const ImageMatchExerciseView = ({
  exerciseImages,
  labelOptions,
  exerciseState,
  correctChoices,
  onSelectionChanged,
}: ImageMatchExerciseViewProps) => {
  //The options that the user has selected.
  const [pickedOptions, setPickedOptions] = useState<Map<number, string>>(
    new Map<number, string>([
      [0, EMPTY_STRING],
      [1, EMPTY_STRING],
      [2, EMPTY_STRING],
      [3, EMPTY_STRING],
    ])
  );

  //Safely define defaultCandidateIndex as something
  //the number of criteria or images won't ever affect
  const defaultCandidateIndex = -1;

  const [selectedMap, setSelectedMap] = useState<Map<string, string>>();
  //Initialize selectedMap with the available label options
  useEffect(() => {
    if (!labelOptions) return;
    setSelectedMap(
      new Map<string, string>([
        [labelOptions[0], EMPTY_STRING],
        [labelOptions[1], EMPTY_STRING],
        [labelOptions[2], EMPTY_STRING],
        [labelOptions[3], EMPTY_STRING],
      ])
    );
  }, [labelOptions]);

  //The signedImage position the user clicks to set the label.
  //Default to -1 meaning user picks sequentially.
  const [candidateIndex, setCandidateIndex] = useState<number>(defaultCandidateIndex);
  const [selectedOptionsCount, setSelectedOptionsCount] = useState<number>(0);

  const handleSelect = useCallback(
    (label: string, dropIndex?: number) => {
      //If user hasn't picked an signedImage or dropped a label, clicking on a label option fills first available slot.
      if (candidateIndex === defaultCandidateIndex && !dropIndex) {
        for (const [pos, lbl] of pickedOptions.entries()) {
          if (lbl === EMPTY_STRING) {
            //If empty, label will be emptystring, in which condition,
            //set the criteria received through the event in the corresponding position.
            setPickedOptions(new Map(pickedOptions.set(pos, label)));
            //Update the map that will be the user's selection.
            setSelectedMap(new Map(selectedMap?.set(label, exerciseImages[pos].imageS3Key)));
            evaluateSelectedValuesCount();
            return;
          } else {
            //No empty fields remaining, which is the last selection. Need to update the map with new selection.
            setSelectedMap(new Map(selectedMap?.set(lbl, exerciseImages[pos].imageS3Key)));
            evaluateSelectedValuesCount();
          }
        }
        return;
      }

      let selIndex;
      //If candidate === -1 is already handled above.
      //Check if we're passed in an index for the item being dropped at the dropIndex
      //If dropIndex is specified, set selectionIndex to dropIndex
      if (dropIndex) {
        selIndex = dropIndex;
      } else {
        //If an signedImage has been selected as candidate for selection, set selectionIndex to candidateIndex.
        selIndex = candidateIndex;
      }

      // If criteria exists in slot then clear it before adding a new one
      const currentItem = pickedOptions.get(selIndex);
      if (currentItem) {
        handleClear(selIndex, currentItem);
      }

      //If user has picked an signedImage, when a label is clicked, populate selected signedImage with the selected label.
      setPickedOptions(new Map(pickedOptions.set(selIndex, label)));
      setSelectedMap(new Map(selectedMap?.set(label, exerciseImages[selIndex].imageS3Key)));
      //If signedImage was selected as candidate for criteria selection, reset the candidate index to allow selection of a new index.
      if (candidateIndex !== defaultCandidateIndex) {
        setCandidateIndex(defaultCandidateIndex);
      }
      //Selection is satisfied, so reset to default.
      evaluateSelectedValuesCount();
      return;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedMap, pickedOptions, candidateIndex]
  );

  //Clear the position the user cleared the selection in both maps.
  const handleClear = useCallback(
    (index: number, label: string) => {
      setPickedOptions(new Map(pickedOptions.set(index, EMPTY_STRING)));
      setSelectedMap(new Map(selectedMap?.set(label, EMPTY_STRING)));
      evaluateSelectedValuesCount();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pickedOptions, selectedOptionsCount]
  );

  useEffect(() => {
    //Only trigger onSelectionChanged before answer is checked.
    if (exerciseState === ExerciseState.Correct || exerciseState === ExerciseState.Incorrect) return;
    if (selectedMap) {
      onSelectionChanged(selectedMap, selectedOptionsCount === labelOptions.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptionsCount]);

  function evaluateSelectedValuesCount() {
    if (selectedMap) {
      let selectedOptions = 0;
      //If emptyValues' size is > 0, selection is invalid.
      for (const [, v] of selectedMap.entries()) {
        if (v !== EMPTY_STRING) selectedOptions++;
      }
      setSelectedOptionsCount(selectedOptions);
    }
  }

  function isSelected(option: string) {
    return Array.from(pickedOptions.values()).indexOf(option) > -1;
  }

  const checkIsCorrect = (item: string) => {
    if (selectedMap && correctChoices) {
      //Find the key in the selectedMap in the position
      const key = keyForValue(selectedMap, item) as string;
      //Get the value for corresponding key from the correct choices
      try {
        //If the item set at the position and the value for the
        //retrieved key from the correct answer map matches, the user made a correct selection.
        const valueForKeyInCorrectChoices = correctChoices.get(key);
        return valueForKeyInCorrectChoices === item;
      } catch (error) {
        return false;
      }
    }
  };

  const getCorrectLabel = (item: string) => {
    if (exerciseState === ExerciseState.Incorrect && selectedMap && correctChoices) {
      //Find the key in the correctChoice in the position
      return keyForValue(correctChoices, item) as string;
    }
  };

  //Helper method to get the keys for the value from a map.
  const keyForValue = (map: Map<string | number, string>, value: string) => {
    const keys = [...map.entries()].filter(({ 1: v }) => v === value).map(([k]) => k);
    return keys[0];
  };

  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down('mob'));

  useEffect(() => {
    // After the component renders, prevent the user from scrolling.
    if (!isSmallDevice) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'scroll';
    };
  });

  return (
    <DndProvider options={HTML5toTouch}>
      <Stack spacing={4} alignItems={'center'} mb={{ xs: 4, des: 20 }}>
        <Grid
          container
          columnSpacing={2}
          rowSpacing={{ xs: 2, mob: 2, tab: 2 }}
          width={{ xs: 0.95, mob: 0.9, tab: 0.65 }}
          display={'flex'}
        >
          {exerciseImages.map((item, index: number) => (
            <Grid
              key={exerciseImages[index].imageS3Key}
              xs={6}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <ImageMatchItem
                exerciseState={exerciseState}
                key={exerciseImages[index].imageS3Key}
                label={pickedOptions.get(index) ?? EMPTY_STRING}
                exerciseImage={exerciseImages[index]}
                isSelected={pickedOptions.get(index) ? true : false}
                isCorrect={
                  (exerciseState === ExerciseState.Correct || exerciseState === ExerciseState.Incorrect) &&
                  checkIsCorrect(item.imageS3Key)
                }
                isIncorrect={exerciseState === ExerciseState.Incorrect && !checkIsCorrect(item.imageS3Key)}
                correctLabel={getCorrectLabel(item.imageS3Key)}
                isCandidate={!pickedOptions.get(index) && index === candidateIndex}
                onDeselected={(label) => {
                  handleClear(index, label);
                }}
                onSelected={() => {
                  if (exerciseState === ExerciseState.Correct || exerciseState === ExerciseState.Incorrect) return;
                  if (selectedOptionsCount === labelOptions.length) return;
                  setCandidateIndex(index);
                }}
                onDropped={(label) => {
                  //Only drop if there's no label selected against the signedImage
                  if (!pickedOptions.get(index)) {
                    //Check if the label is present as choice for some other signedImage
                    const lastIndex = keyForValue(pickedOptions, label) as number;
                    //If lastIndex is not undefined, it means the label is being moved from another signedImage.
                    //In this case, we need to remove it from the previously selected signedImage.
                    //The if(lastIndex) is false if lastIndex === 0, so explicitly 0 check
                    if (lastIndex || lastIndex === 0) {
                      handleClear(lastIndex, label);
                    }
                    handleSelect(label, index);
                  }
                }}
              />
            </Grid>
          ))}
        </Grid>

        {/* This is the grid that holds the possible options */}
        <Grid container rowSpacing={{ xs: 2, mob: 2, tab: 1 }} width={0.91} columnSpacing={2} display={'flex'}>
          {labelOptions.map((item, index: number) => (
            // Although index is unique per grid item, it is also the same across re-renders.
            //So index alone won't refresh the view. Setting the key for the grid item to a timestamp + index
            //ensures no stale info is displayed on grid items.
            <Grid key={currentTimestamp() + index} xs={6} display="flex" justifyContent="center" position="relative">
              <CriteriaItem
                style={{ zIndex: 1, position: 'relative' }}
                text={item}
                isSelected={isSelected(item)}
                onClick={() => {
                  if (exerciseState === ExerciseState.Correct || exerciseState === ExerciseState.Incorrect) return;
                  if (!isSelected(item)) handleSelect(item);
                }}
              />
              <DropZone
                style={{ height: 40, width: '100%', position: 'absolute', zIndex: 0 }}
                onDropped={(item) => {
                  if (
                    exerciseImages &&
                    exerciseState != ExerciseState.Correct &&
                    exerciseState != ExerciseState.Incorrect
                  )
                    handleClear(keyForValue(pickedOptions, item) as number, item);
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Stack>
      {!(exerciseState === ExerciseState.Correct || exerciseState === ExerciseState.Incorrect) && (
        <CriteriaDragPreview />
      )}
    </DndProvider>
  );
};

export default ImageMatchExerciseView;
