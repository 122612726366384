import { Box, CssBaseline, ThemeProvider, useTheme, Modal } from '@mui/material';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { appTheme } from './Themes/theme';
import LandingPage from 'Screens/LandingPage';
import LicensePage from 'Screens/Static/LicensePage';
import ContactPage from 'Screens/Static/ContactPage';
import PrivacyPage from 'Screens/Static/PrivacyPage';
import TermsPage from 'Screens/Static/TermsPage';
import { ROUTE_PATHS } from 'Routes/RouteKeys';
import { PrivateRoutes } from 'Routes/PrivateRoutes';
import SplashContext, { SplashContextType } from 'Context/SplashContext';
import { useEffect, useState } from 'react';
import SplashScreen from 'Screens/SplashScreen';
import { SnackbarUtilsConfigurator } from 'Util/SnackbarUtilsConfigurator';
import { useAuth0 } from '@auth0/auth0-react';
import EmailVerificationPage from 'Screens/EmailVerificationPage';
import ScrollTo from 'Components/General/ScrollTo';

function App() {
  const [showSplash, setShowSplash] = useState<boolean>(true);
  const { isAuthenticated } = useAuth0();

  const theme = useTheme();

  //Paint body with primary background color
  useEffect(() => {
    const backgroundColor = isAuthenticated ? theme.palette.primary.appBackground ?? '#21203F' : 'white';
    document.body.style.backgroundColor = backgroundColor;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return (
    <ThemeProvider theme={appTheme}>
      <CssBaseline enableColorScheme />
      <SplashContext.Provider
        value={
          {
            showSplash: showSplash,
            setShowSplash: setShowSplash,
          } as SplashContextType
        }
      >
        <Modal open={showSplash}>
          <Box position="absolute" width="100%" height="100%" zIndex={100}>
            <SplashScreen />
          </Box>
        </Modal>
        <SnackbarUtilsConfigurator />
        <Router>
          <ScrollTo />
          <Routes>
            <Route path={ROUTE_PATHS.WILDCARD} element={<LandingPage />} />
            <Route path={ROUTE_PATHS.LANDING} element={<LandingPage />} />
            <Route path={ROUTE_PATHS.CONTACT} element={<ContactPage />} />
            <Route path={ROUTE_PATHS.LICENSE} element={<LicensePage />} />
            <Route path={ROUTE_PATHS.PRIVACY} element={<PrivacyPage />} />
            <Route path={ROUTE_PATHS.TERMS} element={<TermsPage />} />
            <Route path={ROUTE_PATHS.VERIFY} element={<EmailVerificationPage />} />
            <Route path={ROUTE_PATHS.PRIVATE + ROUTE_PATHS.WILDCARD} element={<PrivateRoutes />} />
          </Routes>
        </Router>
      </SplashContext.Provider>
    </ThemeProvider>
  );
}

export default App;
