import { Box, Typography, SxProps, Theme, useTheme } from '@mui/material';
import Lock from 'Assets/Icons/PNG/lesson-lock.png';

interface BadgeLevelProps {
  // The badge level.
  level: number;
  // The maximum level for this badge.
  maxLevel: number;
  // Whether to display the small version of the badge or the large version.
  isSmall?: boolean;
  style?: SxProps<Theme>;
}

const BadgeLevel = ({ level, maxLevel, isSmall, style }: BadgeLevelProps) => {
  const theme = useTheme();
  // Determine the appearance of the circle depending on level and display border.
  let circleBackgroundColor;

  if (level === 0) {
    circleBackgroundColor = 'lesson.badgeLevelLocked';
  } else if (level === maxLevel) {
    circleBackgroundColor = 'lesson.max';
  } else {
    circleBackgroundColor = 'primary.main';
  }

  const circleBorderColor = isSmall ? 'lesson.achievementPanelBackground' : 'secondary.dark';
  const circleBorder = isSmall ? 5 : 8;
  const circleDiameter = isSmall ? 34 : 49;

  return (
    // This box is the circle that contains the level text or lock icon.
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{
        border: circleBorder,
        borderColor: circleBorderColor,
        borderRadius: '50%',
        backgroundColor: circleBackgroundColor,
        display: 'flex',
        width: { xs: circleDiameter - 10, mob: circleDiameter },
        height: { xs: circleDiameter - 10, mob: circleDiameter },
        ...style,
      }}
    >
      {level === 0 ? (
        <Box display="flex" position="absolute" justifyContent="center" alignItems="center">
          <img src={Lock} style={{ width: 15, height: 15 }} />
        </Box>
      ) : (
        <Typography position="absolute" fontSize={isSmall ? '1rem' : '1.5rem'} fontWeight="bold" color="white.main">
          {level}
        </Typography>
      )}
    </Box>
  );
};

export default BadgeLevel;
