// Returns true if the supplied lessonCompletionTime is the current day.
// If the supplied time is invalid, shows an error.
const LessonCompletedToday = (lessonCompletionTime: string) => {
  const lastLessonDate = new Date(lessonCompletionTime);

  // If we failed to parse a date from the time string, getTime will return NaN.
  if (isNaN(lastLessonDate.getTime())) {
    const errorMessage = `Received invalid lesson date: ${lessonCompletionTime}`;
    console.log(errorMessage);
    throw Error(errorMessage);
  }

  const today = new Date();

  // Check that the day, month, and year match the current date.
  return (
    lastLessonDate.getDay() === today.getDay() &&
    lastLessonDate.getMonth() === today.getMonth() &&
    lastLessonDate.getFullYear() === today.getFullYear()
  );
};

export default LessonCompletedToday;

