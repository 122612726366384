import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// Scrolls by the provided distance on page load. Default location is top of page.
// Note that `left` and `top` are offsets from the current position.
function ScrollTo(options: ScrollToOptions) {
  const { pathname } = useLocation();

  useEffect(() => {
    if (options?.left || options?.top) {
      window.scrollTo(options);
    } else {
      window.scrollTo(0, 0);
    }
  }, [pathname, options]);

  return null;
}

export default ScrollTo;
