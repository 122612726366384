import { createTheme, PaletteColorOptions, responsiveFontSizes } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Palette {
    tertiary: PaletteColorOptions;
    lesson: PaletteColorOptions;
    white: PaletteColorOptions;
    exercise: PaletteColorOptions;
  }

  interface PaletteOptions {
    tertiary: PaletteColorOptions;
    lesson: PaletteColorOptions;
    white: PaletteColorOptions;
    exercise: PaletteColorOptions;
  }

  interface PaletteColor {
    tertiary?: string;
    appBackground?: string;
    lesson?: string;
    exercise?: string;
    white?: string;
    zero?: string;
    zeroBg?: string;
    one?: string;
    oneBg?: string;
    two?: string;
    twoBg?: string;
    three?: string;
    threeBg?: string;
    max?: string;
    maxBg?: string;
    title?: string;
    locked?: string;
    circle?: string;
    badgeUnlocked?: string;
    achievementPanelBackground?: string;
    streakCenter?: string;
    streakGrey?: string;
    criteria?: string;
    matchOptionHolder?: string;
    matchCandidate?: string;
    matchCriteriaCandidate?: string;
    criteriaOption?: string;
    criteriaCorrect?: string;
    criteriaIncorrect?: string;
    gameEnd?: string;
    activeSelection?: string;
    inactiveSelection?: string;
    correct?: string;
    incorrect?: string;
  }

  interface SimplePaletteColorOptions {
    tertiary?: string;
    contrastText?: string;
    appBackground?: string;
    lesson?: string;
    exercise?: string;
    white?: string;
    zero?: string;
    zeroBg?: string;
    one?: string;
    oneBg?: string;
    two?: string;
    twoBg?: string;
    three?: string;
    threeBg?: string;
    max?: string;
    maxBg?: string;
    title?: string;
    locked?: string;
    circle?: string;
    badgeLevelLocked?: string;
    achievementPanelBackground?: string;
    streakCenter?: string;
    streakGrey?: string;
    criteria?: string;
    matchOptionHolder?: string;
    matchCandidate?: string;
    matchCriteriaCandidate?: string;
    criteriaOption?: string;
    criteriaCorrect?: string;
    criteriaIncorrect?: string;
    gameEnd?: string;
    activeSelection?: string;
    inactiveSelection?: string;
    correct?: string;
    incorrect?: string;
  }

  interface TypographyVariants {
    title: React.CSSProperties;
    captionBold?: React.CSSProperties;
    exerciseTitle?: React.CSSProperties;
    lessonEndTitle?: React.CSSProperties;
    exerciseResult?: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    title?: React.CSSProperties;
    captionBold?: React.CSSProperties;
    exerciseTitle?: React.CSSProperties;
    lessonEndTitle?: React.CSSProperties;
    exerciseResult?: React.CSSProperties;
  }
}

// Here we update the `color` prop for certain components to accept
// colours other than the existing mui colours.
declare module '@mui/material/styles/createPalette' {
  interface Palette {
    lesson: PaletteColor;
    exercise: PaletteColor;
    tertiary: PaletteColor;
    white: PaletteColor;
  }
  interface PaletteOptions {
    lesson: PaletteColorOptions;
    exercise: PaletteColorOptions;
    tertiary: PaletteColorOptions;
    white: PaletteColorOptions;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    title: true;
    captionBold: true;
    exerciseTitle: true;
    lessonEndTitle: true;
    exerciseResult: true;
  }
}

declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsColorOverrides {
    lesson: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    tertiary: true;
    white: true;
  }
}

declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    tertiary: true;
    white: true;
  }
}

declare module '@mui/material/CircularProgress' {
  interface CircularProgressPropsColorOverrides {
    white: true;
  }
}

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true;
    sm: false; // removes the `sm` breakpoint
    md: false;
    lg: false;
    mbs: true;
    mob: true; //mobile
    tab: true; //tablet
    lap: true; //laptop
    des: true; //desktop
    xl: true;
  }
}
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      mbs: 321,
      mob: 361,
      tab: 640,
      lap: 1024,
      des: 1200,
      xl: 1536,
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: '#0E1546',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          color: 'white',
        },
      },
    },
  },
  palette: {
    error: {
      main: '#C13C3C',
      light: '#FFDDDB',
      dark: '#D24E4A',
    },
    success: {
      main: '#0B9F00',
      light: '#BAFFB5',
      dark: '#18AE0D',
    },
    //This is the background and text color for disabled button
    action: {
      disabledBackground: '#AF3DB233',
      disabled: '#FFFFFF4D',
    },
    primary: {
      main: '#AF3DB2',
      dark: '#73287B',
      light: '#FC84FF',
      contrastText: 'FFFFFF',
      appBackground: '#21203F',
      activeSelection: '#622168',
      inactiveSelection: '#FFFFFF1A',
    },
    secondary: {
      main: '#118BAE',
      dark: '#4D2663',
      contrastText: '#68719C',
    },
    tertiary: {
      main: '#118BAE',
      light: '#562966',
      dark: '#0E1546',
      contrastText: '#FFFFFF',
    },
    lesson: {
      main: '#D24E4A',
      dark: '#999999',
      light: '#B8E2FF',
      zero: '#EAEAEA',
      one: '#E3576E',
      two: '#F9E05B',
      three: '#18AE0D',
      max: '#5CB4F1',
      title: '#D5BED7',
      circle: '#CCDDE5',
      locked: '#E6E6E6',
      badgeLevelLocked: '#0C153F',
      achievementPanelBackground: '#363558',
      streakCenter: '#272D59',
      streakGrey: '#BBBBBB',
      zeroBg: '#5E3A7E',
      oneBg: '#853C4D',
      twoBg: '#A6935B',
      threeBg: '#265B41',
      maxBg: '#3C5B8E',
      gameEnd: '#484D72',
    },
    exercise: {
      main: '#FFFFF',
      dark: '#0000004D',
      light: '#FFFFFF',
      contrastText: '#707070',
      criteria: '#4B2258',
      matchOptionHolder: '#0E1546B3',
      matchCandidate: '#743176',
      matchCriteriaCandidate: '#49284D',
      criteriaOption: '#272D59',
      criteriaCorrect: '#1B3833',
      criteriaIncorrect: '#412335',
      incorrect: '#401E2D',
      correct: '#1A3D2D',
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#FFFFFF',
    },
    white: {
      main: '#FFFFFF',
    },
  },
  spacing: 4,
  typography: {
    h1: {
      fontSize: '2rem',
      fontWeight: '500',
      alignSelf: 'center',
    },
    h2: {
      fontSize: '1.5rem',
      fontWeight: '500',
      alignSelf: 'center',
    },
    h3: {
      fontSize: '1.125rem',
      fontWeight: '500',
      letterSpacing: '0.96px',
      lineHeight: '1.5',
    },
    body1: {
      fontSize: '1.125rem',
      fontWeight: 'regular',
    },
    body2: {
      fontSize: '1.125rem',
      fontWeight: 'light',
      fontStyle: 'italic',
      alignSelf: 'center',
    },
    caption: {
      fontSize: '0.75rem',
      fontWeight: 'regular',
      letterSpacing: '0.96px',
      lineHeight: '0.875rem',
    },
    title: {
      fontWeight: 'bold',
      fontSize: '2rem',
      alignSelf: 'left',
      textTransform: 'capitalize',
    },
    lessonEndTitle: {
      fontSize: '1.5rem',
      fontWeight: 'bold',
    },
    exerciseTitle: {
      fontSize: '1.25rem',
      fontWeight: '500',
      color: 'white',
    },
    exerciseResult: {
      fontSize: '0.875rem',
      fontWeight: 'regular',
    },
    captionBold: {
      fontSize: '0.75rem',
      fontWeight: 'bold',
      alignSelf: 'left',
    },
  },
});

export const appTheme = responsiveFontSizes(theme);
