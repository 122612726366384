import BadgeLesson from 'Assets/Badges/Badge - Lesson.svg';
import BadgeMaxLesson from 'Assets/Badges/Badge-Max - Lesson.svg';
import BadgePerfect from 'Assets/Badges/Badge - Perfect.svg';
import BadgeMaxPerfect from 'Assets/Badges/Badge-Max - Perfect.svg';
import BadgePoints from 'Assets/Badges/Badge - Points.svg';
import BadgeMaxPoints from 'Assets/Badges/Badge-Max - Points.svg';
import BadgeStreak from 'Assets/Badges/Badge - Streak.svg';
import BadgeMaxStreak from 'Assets/Badges/Badge-Max - Streak.svg';
import BadgeUnit from 'Assets/Badges/Badge - Unit.svg';
import BadgeMaxUnit from 'Assets/Badges/Badge-Max - Unit.svg';
import { BADGES } from 'Constants/keys';

const lessonCompletionDescriptions = {
  locked: 'Complete ',
  lockedSuffix: ' lessons',
  unlocked: 'You completed ',
  unlockedSuffix: ' lessons!',
  maxUnlocked: 'Congrats! You completed ',
  maxUnlockedSuffix: ' lessons!',
};

const unitCompletionDescriptions = {
  locked: 'Complete Unit ',
  lockedSuffix: '',
  unlocked: 'You completed all lessons in Unit ',
  unlockedSuffix: '!',
  maxUnlocked: 'Congrats! You completed all lessons in Unit ',
  maxUnlockedSuffix: '!',
};

const experienceDescriptions = {
  locked: 'Gain ',
  lockedSuffix: ' XP',
  unlocked: 'You earned ',
  unlockedSuffix: ' total points!',
  maxUnlocked: 'Congrats! You earned ',
  maxUnlockedSuffix: ' total points!',
};

const accuracyDescriptions = {
  locked: 'Complete ',
  lockedSuffix: ' lessons without mistakes',
  unlocked: 'You completed ',
  unlockedSuffix: ' lessons without any mistakes!',
  maxUnlocked: 'Congrats! You completed ',
  maxUnlockedSuffix: ' lessons without any mistakes!',
};

const streakDescriptions = {
  locked: 'Complete a lesson per day for ',
  lockedSuffix: ' days',
  unlocked: 'You reached a ',
  unlockedSuffix: '-day streak!',
  maxUnlocked: 'Congrats! You reached a ',
  maxUnlockedSuffix: '-day streak!',
};

// Contains data such as image locations for each badge.
const badgeData = {
  LESSON_COMPLETION: {
    completeImage: BadgeMaxLesson,
    incompleteImage: BadgeLesson,
    displayName: BADGES.LESSON_COMPLETION,
    descriptions: lessonCompletionDescriptions,
  },
  UNIT_COMPLETION: {
    completeImage: BadgeMaxUnit,
    incompleteImage: BadgeUnit,
    displayName: BADGES.UNIT_COMPLETION,
    descriptions: unitCompletionDescriptions,
  },
  EXPERIENCE: {
    completeImage: BadgeMaxPoints,
    incompleteImage: BadgePoints,
    displayName: BADGES.EXPERIENCE,
    descriptions: experienceDescriptions,
  },
  ACCURACY: {
    completeImage: BadgeMaxPerfect,
    incompleteImage: BadgePerfect,
    displayName: BADGES.ACCURACY,
    descriptions: accuracyDescriptions,
  },
  STREAK: {
    completeImage: BadgeMaxStreak,
    incompleteImage: BadgeStreak,
    displayName: BADGES.STREAK,
    descriptions: streakDescriptions,
  },
};

export default badgeData;
