import { Stack, Box, Typography, SxProps, Theme } from '@mui/material';
import CustomButton from 'Components/General/CustomButton';
import { useEffect, useState } from 'react';
import { DefaultButtonProps } from '../General/CustomButton';
import { BUTTON_TITLE, EMPTY_STRING, LESSON_TEXTS } from 'Constants/keys';
import { ExerciseState } from 'Enums/ExerciseEnums';

export interface ExerciseResultBarProps {
  exerciseState: ExerciseState;
  isLoading?: boolean;
  onButtonClicked: () => void;
  style?: SxProps<Theme>;
}

interface ExerciseResultBarParams {
  exerciseState?: ExerciseState;
  bgColor?: string;
  borderColor?: string;
  isDisabled?: boolean;
  btnColor?: DefaultButtonProps['color'];
  btnTitle?: string;
  feedbackMessage?: string;
}

export default function ExerciseResultBar({
  exerciseState,
  onButtonClicked,
  isLoading,
  style,
}: ExerciseResultBarProps) {
  const resultBarParams = {} as ExerciseResultBarParams;
  const [exerciseResultBarParams, setExerciseResultBarParams] = useState<ExerciseResultBarParams>();

  const maxMessageLength = 25;

  useEffect(() => {
    let newBgColor;
    let newBorderColor;
    let isDisabled;
    let newBtnColor;
    let newBtnTitle;
    switch (exerciseState) {
      case ExerciseState.Selected:
        newBgColor = 'primary.appBackground';
        newBorderColor = 'primary.appBackground';
        isDisabled = false;
        newBtnColor = 'primary';
        newBtnTitle = BUTTON_TITLE.CHECK;
        break;
      case ExerciseState.NotSelected:
        newBgColor = 'primary.appBackground';
        newBorderColor = 'primary.appBackground';
        isDisabled = true;
        newBtnColor = 'primary';
        newBtnTitle = BUTTON_TITLE.CHECK;
        break;
      case ExerciseState.Correct:
        newBgColor = 'exercise.correct';
        newBorderColor = 'success.dark';
        isDisabled = false;
        newBtnColor = 'success';
        newBtnTitle = BUTTON_TITLE.CONTINUE;
        break;
      case ExerciseState.Incorrect:
        newBgColor = 'exercise.incorrect';
        newBorderColor = 'lesson.one';
        isDisabled = false;
        newBtnColor = 'error';
        newBtnTitle = BUTTON_TITLE.CONTINUE;
        break;
    }
    resultBarParams.borderColor = newBorderColor;
    resultBarParams.bgColor = newBgColor;
    resultBarParams.isDisabled = isDisabled;
    resultBarParams.btnColor = newBtnColor as DefaultButtonProps['color'];
    resultBarParams.btnTitle = newBtnTitle;
    resultBarParams.exerciseState = exerciseState;
    resultBarParams.feedbackMessage = getFeedbackMessage(exerciseState);
    setExerciseResultBarParams(resultBarParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exerciseState]);

  const getFeedbackMessage = (exerciseState: ExerciseState) => {
    switch (exerciseState) {
      case ExerciseState.NotSelected:
      case ExerciseState.Selected:
        return EMPTY_STRING;
      case ExerciseState.Correct:
        return LESSON_TEXTS.CONGRATULATORY_TEXTS[Math.floor(Math.random() * LESSON_TEXTS.CONGRATULATORY_TEXTS.length)];
      case ExerciseState.Incorrect:
        return LESSON_TEXTS.INCORRECT_TEXTS[Math.floor(Math.random() * LESSON_TEXTS.INCORRECT_TEXTS.length)];
    }
  };

  //When we have the long text, we need to Reduce the margin to fit the text well. This function identifies long texts.
  const isLongText = () =>
    exerciseResultBarParams?.feedbackMessage && exerciseResultBarParams.feedbackMessage.length >= maxMessageLength;

  return (
    <Stack
      width={1}
      sx={{
        height: 124,
        backgroundColor: exerciseResultBarParams && exerciseResultBarParams.bgColor,
        position: 'fixed',
        left: 0,
        right: 0,
        bottom: 0,
        ...style,
      }}
    >
      <Box
        sx={{ width: 1, height: 4, backgroundColor: exerciseResultBarParams && exerciseResultBarParams.borderColor }}
      />
      <Box display="flex" justifyContent={'center'} alignItems={'center'} sx={{ width: 1, height: 1 }}>
        <Stack width={1}>
          {((exerciseResultBarParams && exerciseResultBarParams.exerciseState === ExerciseState.Correct) ||
            exerciseState === ExerciseState.Incorrect) && (
            <Typography
              variant="exerciseResult"
              color={exerciseState === ExerciseState.Correct ? 'success.light' : 'error.light'}
              sx={{
                position: 'absolute',
                bottom: 84,
                alignSelf: { xs: 'left', mob: 'center' },
              }}
              mr={{ mob: isLongText() ? 40 : 50 }}
              ml={{ xs: 10, mob: 0 }}
            >
              {exerciseResultBarParams?.feedbackMessage}
            </Typography>
          )}
          <CustomButton
            onPress={onButtonClicked}
            loading={isLoading}
            color={exerciseResultBarParams && exerciseResultBarParams.btnColor}
            disabled={exerciseResultBarParams && exerciseResultBarParams.isDisabled}
            title={exerciseResultBarParams && exerciseResultBarParams.btnTitle}
            style={{ position: 'absolute', bottom: 30, maxHeight: 48 }}
          />
        </Stack>
      </Box>
    </Stack>
  );
}
