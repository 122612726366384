import { Paper, Box, Stack, Typography, useTheme, useMediaQuery } from '@mui/material';
import { ReactComponent as Mascot } from 'Assets/Mascots/Lesson/ExitMascot.svg';
import CustomButton from 'Components/General/CustomButton';
import { BUTTON_TITLE, GAME_TEXTS } from 'Constants/keys';

export interface QuitLessonConfirmationProps {
  onContinueClicked: () => void;
  onEndClicked: () => void;
}

const QuitLessonConfirmation = ({ onContinueClicked, onEndClicked }: QuitLessonConfirmationProps) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('mob'));
  //Required check when the breakpoint is mob, but the width is less than 375.
  const isSmallerMobile = window.innerWidth <= 375;
  //Required check when the breakpoint is mbs, less than mob, but the width is greater than 360.
  const isMediumMobile = window.innerWidth >= 360;

  return (
    <Paper
      sx={{
        backgroundColor: 'secondary.dark',
        width: { xs: '87vw', mob: '87vw', tab: 341 },
        height: { xs: '85vh', mbs: isMediumMobile ? '75vh' : '85vh', mob: isSmallerMobile ? '85vh' : '71vh', tab: 650 },
      }}
    >
      <Box width={1} height={1} justifyContent={'center'} display="flex">
        <Stack mt={8} mx={{ xs: 6, mob: 5 }} alignItems={'center'} justifyContent={'top'}>
          <Typography
            variant="h1"
            color="white"
            fontWeight={'bold'}
            sx={{ textAlign: 'center' }}
            mb={{ xs: 2, mob: 8 }}
            mt={{ xs: 0, mbs: isMediumMobile ? 4 : 8, mob: isSmallerMobile ? 0 : 8 }}
          >
            {GAME_TEXTS.LEAVE_LESSON_CONFIRMATION_TITLE}
          </Typography>
          <Mascot style={{ width: isSmall ? 190 : '100%' }} />
          <Typography mt={{ xs: 3, mob: 6.5 }} variant="body1" color="white" sx={{ textAlign: 'center' }}>
            {GAME_TEXTS.LEAVE_LESSON_CONFIRMATION_SUBTITLE}
          </Typography>
          <CustomButton
            title={BUTTON_TITLE.CONTINUE_LESSON}
            onPress={onContinueClicked}
            style={{ marginTop: { xs: 3, mbs: 8, mob: 12 } }}
          />
          <CustomButton
            type="outlined"
            title={BUTTON_TITLE.END_LESSON}
            onPress={onEndClicked}
            style={{ marginTop: 4, marginBottom: 4 }}
          />
        </Stack>
      </Box>
    </Paper>
  );
};

export default QuitLessonConfirmation;
