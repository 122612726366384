import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& label': {
    color: 'primary.inactiveSelection',
    '& fieldset': {
      color: 'white',
    },
  },
  '& input': {
    color: theme.palette.white,
  },
  '& label.Mui-focused': {
    color: 'white',
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: theme.spacing(4),
    '& fieldset': {
      borderColor: 'white',
      borderWidth: theme.spacing(0.5),
    },
    '&.Mui-focused fieldset': {
      borderColor: 'primary',
      borderWidth: theme.spacing(0.5),
    },
  },
}));

export default StyledTextField;
