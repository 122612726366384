import BoxedContainer from 'Containers/BoxedContainer';
import TitleBar from 'Components/General/TitleBar';
import { EMPTY_JSON_STRING, GAME_ELEMENT, TABS } from 'Constants/keys';
import Gameflow from 'Components/Lesson/Gameflow';
import { Stack } from '@mui/material';
import { useState } from 'react';
import Onboarding, { OnboardingStage } from 'Components/Lesson/OnboardingDialog';
import { LoggedInUser } from '../Profile/ProfilePage';
import { useUserOnboardedMutation } from 'graphql/generated/graphql';
import ShowSnack from 'Util/SnackbarUtilsConfigurator';
import UpdateLoggedInUser from 'Util/LocalStorageFunctions';

const LessonsPage = () => {
  const [userOnboarded] = useUserOnboardedMutation();

  const loggedInUser = JSON.parse(
    window.localStorage.getItem(GAME_ELEMENT.LOGGED_IN_USER) ?? EMPTY_JSON_STRING
  ) as LoggedInUser;

  // onboardingProgress tracks the user's progress through the onboarding flow.
  // it always starts at the first screen.
  const [onboardingProgress, setOnboardingProgress] = useState<OnboardingStage>(OnboardingStage.FirstStage);

  // If we've loaded the user's profile, and it shows they have not onboarded,
  // display the current stage of the onboarding flow. Otherwise, show the Complete stage.
  const onboardingDisplay =
    loggedInUser.profile === undefined || loggedInUser.profile.onBoarded
      ? OnboardingStage.Complete
      : onboardingProgress;

  console.log(`Onboarding progress: ${onboardingProgress}`);
  console.log(`Onboarding display: ${onboardingDisplay}`);

  return (
    <BoxedContainer justifyTop={true}>
      <Onboarding
        onClick={() => {
          // When the user clicks on the onboarding screen, progress them through the screen.
          if (onboardingProgress === OnboardingStage.FirstStage) {
            setOnboardingProgress(OnboardingStage.SecondStage);
          } else if (onboardingProgress === OnboardingStage.SecondStage) {
            setOnboardingProgress(OnboardingStage.ThirdStage);
          } else if (onboardingProgress === OnboardingStage.ThirdStage) {
            setOnboardingProgress(OnboardingStage.FourthStage);
          } else if (onboardingProgress === OnboardingStage.FourthStage) {
            setOnboardingProgress(OnboardingStage.Complete);
            // Since the user has now seen the whole onboarding flow, mark them as onboarded.
            userOnboarded()
              .then(() => {
                // Update the local storage to mark the user as onboarded, since the backend has been updated.
                if (loggedInUser.profile) {
                  loggedInUser.profile.onBoarded = true;
                  UpdateLoggedInUser(loggedInUser.userId, loggedInUser.profile, loggedInUser.email);
                }
              })
              .catch((error) => {
                console.log(error);
                ShowSnack.error(error.message);
              });
          }
        }}
        stage={onboardingDisplay}
      />
      <TitleBar title={TABS.HOME_TITLE} hideIcon />
      <Stack spacing={6} alignItems="center">
        <Gameflow
          // If onboarding is not complete, raise the first topic ZIndex above the onboarding background.
          firstTopicZIndex={onboardingDisplay !== OnboardingStage.Complete ? 75 : undefined}
          // Show the arrow on the last onboarding screen.
          showFirstTopicArrow={onboardingDisplay === OnboardingStage.FourthStage}
        />
      </Stack>
    </BoxedContainer>
  );
};

export default LessonsPage;
