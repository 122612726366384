import { Box, Stack, Typography } from '@mui/material';
import LeaderboardImage from 'Assets/Images/leaderboard.png';
import LeaderboardImageGray from 'Assets/Images/leaderboard-gray.png';
import { LEADERBOARD } from 'Constants/keys';

export interface LeaderboardInfoProps {
  // Number of xp the current user has earned today
  pointsEarnedToday: number | null | undefined;
  // Rank of the current user
  currentRank: number | null | undefined;
  // Total number of ranked users in the leaderboard
  numberOfRankedUsers?: number;
  // Time at which the leaderboard will be reset.
  end?: Date;
}

const LeaderboardInfo = ({ pointsEarnedToday, currentRank, numberOfRankedUsers, end }: LeaderboardInfoProps) => {
  return (
    <Stack justifyContent={'center'}>
      <Typography
        letterSpacing={'0.34px'}
        lineHeight={'2rem'}
        fontSize={'1.5rem'}
        fontWeight="medium"
        color="text.primary"
        sx={{ textAlign: 'center' }}
      >
        {Intl.DateTimeFormat('default', { year: 'numeric', month: 'long', day: 'numeric' }).format(Date.now())}
      </Typography>
      {/* If we've been provided an end date, show it. */}
      {end && (
        <Typography variant="caption" color="text.primary" textTransform={'uppercase'} sx={{ textAlign: 'center' }}>
          {LEADERBOARD.LEADERBOARD_RESET +
            Intl.DateTimeFormat('default', {
              hour: 'numeric',
              minute: 'numeric',
              hour12: true,
              timeZoneName: 'shortGeneric',
            }).format(end)}
        </Typography>
      )}
      {numberOfRankedUsers ? (
        // If there are any users on the leaderboard, show the trophy image
        <Box
          mt={5}
          mb={6}
          height={140}
          width={140}
          alignSelf="center"
          component="img"
          alt="Leaderboard Image"
          src={LeaderboardImage}
        />
      ) : (
        // If not, show the grey trophy image.
        <Box
          mt={5}
          mb={6}
          height={140}
          width={140}
          alignSelf="center"
          component="img"
          alt="Leaderboard Image"
          src={LeaderboardImageGray}
        />
      )}
      {numberOfRankedUsers ? (
        // If the leaderboard is non-empty, show the number of points the user has earned.
        <>
          <Stack alignSelf={'center'} direction={'row'} spacing={1}>
            <Typography lineHeight={'1.5rem'} fontSize={'1.125rem'} color="primary.light" sx={{ textAlign: 'center' }}>
              {LEADERBOARD.YOUR_POINTS_TODAY}
            </Typography>
            <Typography
              fontWeight={'bold'}
              lineHeight={'1.5rem'}
              fontSize={'1.125rem'}
              color="primary.light"
              sx={{ textAlign: 'center' }}
            >
              {pointsEarnedToday ?? 0}
            </Typography>
          </Stack>
        </>
      ) : (
        <></>
      )}
      {currentRank && numberOfRankedUsers ? (
        // If the user has placed on the leaderboard, show their current rank.
        <Typography
          mt={1}
          variant="caption"
          color="white.main"
          textTransform={'uppercase'}
          sx={{ textAlign: 'center' }}
        >
          {LEADERBOARD.CURRENT_RANK_PREFIX + currentRank + LEADERBOARD.CURRENT_RANK_INFIX + numberOfRankedUsers}
        </Typography>
      ) : (
        <></>
      )}
    </Stack>
  );
};

export default LeaderboardInfo;
