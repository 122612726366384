import { Box, Card, CardActionArea, Stack, SxProps, Theme, Typography, useTheme } from '@mui/material';
import { TopicProgress } from 'graphql/generated/graphql';
import { PlayIndicator } from './PlayIndicator';
import { ReactComponent as Lock } from 'Assets/Icons/SVG/lesson-locked.svg';
import { ReactComponent as Star } from 'Assets/Icons/SVG/game-incomplete.svg';

export interface TopicCircleProps {
  lessonsPerTopic: number;
  topic: TopicProgress;
  style?: SxProps<Theme>;
  isFirstLocked?: boolean;
  blob?: string;
  onClick: () => void;
}

const TopicCircle = ({ lessonsPerTopic, blob, topic, style, isFirstLocked, onClick }: TopicCircleProps) => {
  const isLocked = topic.isLocked;
  const completedLessonCount = topic.completedLessonCount;
  const topicName = topic.topicName;
  const borderColor = getBorderColorString(isLocked, completedLessonCount, lessonsPerTopic);
  const maxCompletionCount = lessonsPerTopic >= 4 ? 4 : lessonsPerTopic;
  const theme = useTheme();
  const backgroundColor =
    completedLessonCount >= maxCompletionCount ? 'lesson.maxBg' : getBackgroundColorString(completedLessonCount);
  const hexCode = getThemeColor(borderColor ?? 'lesson.zero');
  const boxShadowString = '10px 11px 73px ' + hexCode;
  function getThemeColor(borderColor: string) {
    switch (borderColor) {
      case 'lesson.max':
        return theme.palette.lesson.max;
      case 'lesson.main':
        return theme.palette.lesson.main;
      case 'lesson.two':
        return theme.palette.lesson.two;
      case 'lesson.three':
        return theme.palette.lesson.three;
      default:
        return theme.palette.primary.dark;
    }
  }
  return (
    <Box sx={{ width: 200, height: 200 }} alignItems="center" justifyContent={'center'}>
      {!isLocked && blob && (
        <Box sx={{ ml: -9, mt: -9, width: 200, height: 200, ...style }} component="img" src={blob} />
      )}
      <Card
        sx={{
          borderWidth: isLocked ? 0 : 8,
          borderRadius: '50%',
          backgroundColor: isLocked ? 'primary.appBackground' : backgroundColor,
          display: 'flex',
          width: isLocked ? 80 : 128,
          height: isLocked ? 80 : 128,
          alignItems: 'center',
          justifyContent: 'center',
          filter: isLocked && !isFirstLocked ? 'blur(2px)' : 'blur(0px)',
          boxShadow: !isLocked ? boxShadowString : 0,
          ...style,
        }}
        onClick={onClick}
      >
        <Card
          variant="outlined"
          sx={{
            borderWidth: isLocked ? 8 : 6,
            borderRadius: '50%',
            backgroundColor: isLocked ? 'primary.appBackground' : backgroundColor,
            display: 'flex',
            width: isLocked ? 80 : 120,
            height: isLocked ? 80 : 120,
            borderColor: borderColor,
          }}
        >
          <CardActionArea sx={{ borderRadius: '50%' }}>
            <Box
              width={1}
              height={1}
              flexDirection="column"
              display="flex"
              justifyContent={'center'}
              alignItems={'center'}
            >
              {isLocked ? (
                <Lock style={{ width: 46, height: 44 }} fill={theme.palette.lesson.circle} />
              ) : (
                <Stack spacing={2.5} alignItems={'center'}>
                  {/* TODO: For completing all lessons, topicProgress needs to provide lessonCount which is not yet implemented. */}
                  {completedLessonCount >= maxCompletionCount ? (
                    <Star style={{ height: 21, width: 21 }} fill={theme.palette.lesson.max} />
                  ) : (
                    <div />
                  )}
                  <Typography color="text.secondary" variant="body1">
                    {topicName}
                  </Typography>
                  <PlayIndicator lessonCount={maxCompletionCount} lessonCompletionCount={completedLessonCount} />
                </Stack>
              )}
            </Box>
          </CardActionArea>
        </Card>
      </Card>
    </Box>
  );
};

function getBackgroundColorString(completedLessonCount: number) {
  const maxColor = 'lesson.maxBg';
  if (completedLessonCount === 0) return 'lesson.zeroBg';
  if (completedLessonCount === 1) {
    return 'lesson.oneBg';
  }
  if (completedLessonCount === 2) {
    return 'lesson.twoBg';
  }
  if (completedLessonCount === 3) {
    return 'lesson.threeBg';
  }
  return maxColor;
}

function getBorderColorString(
  isLocked: boolean,
  completedLessonCount: number,
  lessonsPerTopic: number
): string | undefined {
  if (isLocked) {
    return 'lesson.circle';
  }
  const maxColor = 'lesson.max';
  if (completedLessonCount === 0) return 'lesson.zero';
  if (completedLessonCount === 1) {
    return lessonsPerTopic === completedLessonCount ? maxColor : 'lesson.main';
  }
  if (completedLessonCount === 2) {
    return lessonsPerTopic === completedLessonCount ? maxColor : 'lesson.two';
  }
  if (completedLessonCount === 3) {
    return lessonsPerTopic === completedLessonCount ? maxColor : 'lesson.three';
  }

  return 'lesson.max';
}

export default TopicCircle;
