import { ArrowBackIosNewRounded } from '@mui/icons-material';
import { IconButton, Box, Stack, Theme, SxProps } from '@mui/material';
import LinearProgressBar from 'Components/General/LinearProgressBar';

export interface ExerciseTitleBarProps {
  progress: number;
  style?: SxProps<Theme>;
  onBackClicked: () => void;
}

export default function ExerciseTitleBar({ progress, style, onBackClicked }: ExerciseTitleBarProps) {
  return (
    <Stack mr={7} my={1} direction={'row'} spacing={3} sx={{ ...style }}>
      <IconButton sx={{ marginLeft: 1 }} size="medium" color="primary" onClick={onBackClicked}>
        <ArrowBackIosNewRounded sx={{ fontSize: '1.5rem', color: 'tertiary.contrastText' }} />
      </IconButton>
      <Box sx={{ width: 1, display: 'grid', alignItems: 'center', justifyItems: 'center' }}>
        <LinearProgressBar variant="determinate" value={progress} sx={{ width: 1 }} />
      </Box>
    </Stack>
  );
}
