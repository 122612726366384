import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
};

export type Achievements = {
  __typename?: 'Achievements';
  badges: Array<Badge>;
  currentStreak: Scalars['Int'];
  experiencePoints: Scalars['Int'];
  latestLessonCompletionTime?: Maybe<Scalars['DateTime']>;
  longestStreak: Scalars['Int'];
  perfectLessons: Scalars['Int'];
  shouldShowStreakPopUp?: Maybe<Scalars['Boolean']>;
};

export type AchievementsFilterInput = {
  and?: InputMaybe<Array<AchievementsFilterInput>>;
  badges?: InputMaybe<ListFilterInputTypeOfBadgeFilterInput>;
  currentStreak?: InputMaybe<ComparableInt32OperationFilterInput>;
  experiencePoints?: InputMaybe<ComparableInt32OperationFilterInput>;
  latestLessonCompletionTime?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  longestStreak?: InputMaybe<ComparableInt32OperationFilterInput>;
  or?: InputMaybe<Array<AchievementsFilterInput>>;
  perfectLessons?: InputMaybe<ComparableInt32OperationFilterInput>;
  shouldShowStreakPopUp?: InputMaybe<BooleanOperationFilterInput>;
};

export type AchievementsSortInput = {
  currentStreak?: InputMaybe<SortEnumType>;
  experiencePoints?: InputMaybe<SortEnumType>;
  latestLessonCompletionTime?: InputMaybe<SortEnumType>;
  longestStreak?: InputMaybe<SortEnumType>;
  perfectLessons?: InputMaybe<SortEnumType>;
  shouldShowStreakPopUp?: InputMaybe<SortEnumType>;
};

export type AnswerInput = {
  selectedCriteria?: InputMaybe<Array<Scalars['String']>>;
  selectedImage?: InputMaybe<Scalars['String']>;
  selectedLabel?: InputMaybe<Scalars['String']>;
  selectedMap?: InputMaybe<Array<KeyValuePairOfStringAndStringInput>>;
};

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER'
}

export enum AvatarVariant {
  DarkInitial = 'DARK_INITIAL',
  LightInitial = 'LIGHT_INITIAL',
  Url = 'URL'
}

export type Badge = {
  __typename?: 'Badge';
  badgeDefinitionId: Scalars['String'];
  level: Scalars['Int'];
  levelThresholds: Array<Scalars['Int']>;
  seen: Scalars['Boolean'];
  type: BadgeType;
};

export type BadgeDefinition = {
  __typename?: 'BadgeDefinition';
  badgeDefinitionId: Scalars['String'];
  levelThresholds: Array<Scalars['Int']>;
  type: BadgeType;
};

export type BadgeDefinitionFilterInput = {
  and?: InputMaybe<Array<BadgeDefinitionFilterInput>>;
  badgeDefinitionId?: InputMaybe<StringOperationFilterInput>;
  levelThresholds?: InputMaybe<ListComparableInt32OperationFilterInput>;
  or?: InputMaybe<Array<BadgeDefinitionFilterInput>>;
  type?: InputMaybe<BadgeTypeOperationFilterInput>;
};

export type BadgeFilterInput = {
  and?: InputMaybe<Array<BadgeFilterInput>>;
  badgeDefinitionId?: InputMaybe<StringOperationFilterInput>;
  level?: InputMaybe<ComparableInt32OperationFilterInput>;
  levelThresholds?: InputMaybe<ListComparableInt32OperationFilterInput>;
  or?: InputMaybe<Array<BadgeFilterInput>>;
  seen?: InputMaybe<BooleanOperationFilterInput>;
  type?: InputMaybe<BadgeTypeOperationFilterInput>;
};

export enum BadgeType {
  Accuracy = 'ACCURACY',
  Experience = 'EXPERIENCE',
  LessonCompletion = 'LESSON_COMPLETION',
  Streak = 'STREAK',
  UnitCompletion = 'UNIT_COMPLETION'
}

export type BadgeTypeOperationFilterInput = {
  eq?: InputMaybe<BadgeType>;
  in?: InputMaybe<Array<BadgeType>>;
  neq?: InputMaybe<BadgeType>;
  nin?: InputMaybe<Array<BadgeType>>;
};

export type BooleanOperationFilterInput = {
  eq?: InputMaybe<Scalars['Boolean']>;
  neq?: InputMaybe<Scalars['Boolean']>;
};

/** Information about the offset pagination. */
export type CollectionSegmentInfo = {
  __typename?: 'CollectionSegmentInfo';
  /** Indicates whether more items exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean'];
  /** Indicates whether more items exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean'];
};

export type ComparableDateTimeOperationFilterInput = {
  eq?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  neq?: InputMaybe<Scalars['DateTime']>;
  ngt?: InputMaybe<Scalars['DateTime']>;
  ngte?: InputMaybe<Scalars['DateTime']>;
  nin?: InputMaybe<Array<Scalars['DateTime']>>;
  nlt?: InputMaybe<Scalars['DateTime']>;
  nlte?: InputMaybe<Scalars['DateTime']>;
};

export type ComparableInt32OperationFilterInput = {
  eq?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  neq?: InputMaybe<Scalars['Int']>;
  ngt?: InputMaybe<Scalars['Int']>;
  ngte?: InputMaybe<Scalars['Int']>;
  nin?: InputMaybe<Array<Scalars['Int']>>;
  nlt?: InputMaybe<Scalars['Int']>;
  nlte?: InputMaybe<Scalars['Int']>;
};

export type ComparableNullableOfDateTimeOperationFilterInput = {
  eq?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  neq?: InputMaybe<Scalars['DateTime']>;
  ngt?: InputMaybe<Scalars['DateTime']>;
  ngte?: InputMaybe<Scalars['DateTime']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  nlt?: InputMaybe<Scalars['DateTime']>;
  nlte?: InputMaybe<Scalars['DateTime']>;
};

export type ComparableNullableOfDoubleOperationFilterInput = {
  eq?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  neq?: InputMaybe<Scalars['Float']>;
  ngt?: InputMaybe<Scalars['Float']>;
  ngte?: InputMaybe<Scalars['Float']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  nlt?: InputMaybe<Scalars['Float']>;
  nlte?: InputMaybe<Scalars['Float']>;
};

export type ComparableNullableOfInt32OperationFilterInput = {
  eq?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  neq?: InputMaybe<Scalars['Int']>;
  ngt?: InputMaybe<Scalars['Int']>;
  ngte?: InputMaybe<Scalars['Int']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  nlt?: InputMaybe<Scalars['Int']>;
  nlte?: InputMaybe<Scalars['Int']>;
};

export type CreateGameFlowDefinitionPayload = {
  __typename?: 'CreateGameFlowDefinitionPayload';
  gameFlowDefinition: GameFlowDefinition;
};

export type CreateGameFlowInput = {
  gameFlowDefinition: Scalars['String'];
};

export type CreateLessonPayload = {
  __typename?: 'CreateLessonPayload';
  exerciseImages: Array<Scalars['String']>;
  lessonId: Scalars['String'];
};

export type CriteriaSelectionExercise = Exercise & {
  __typename?: 'CriteriaSelectionExercise';
  correctCriteria?: Maybe<Array<Scalars['String']>>;
  correctCriteriaCount: Scalars['Int'];
  criteria: Array<Scalars['String']>;
  exerciseAnswers?: Maybe<Array<ExerciseAnswer>>;
  exerciseId: Scalars['String'];
  exerciseOrder: Scalars['Int'];
  exerciseType: ExerciseType;
  image: Scalars['String'];
  isComplete: Scalars['Boolean'];
};

export type CriteriaSelectionExerciseAnswer = ExerciseAnswer & {
  __typename?: 'CriteriaSelectionExerciseAnswer';
  isCorrect: Scalars['Boolean'];
  selectedCriteria?: Maybe<Array<Scalars['String']>>;
};

export type CriteriaSelectionExerciseDefinition = {
  __typename?: 'CriteriaSelectionExerciseDefinition';
  criteriaCount: Scalars['Int'];
};

export type CriteriaSelectionExerciseDefinitionFilterInput = {
  and?: InputMaybe<Array<CriteriaSelectionExerciseDefinitionFilterInput>>;
  criteriaCount?: InputMaybe<ComparableInt32OperationFilterInput>;
  or?: InputMaybe<Array<CriteriaSelectionExerciseDefinitionFilterInput>>;
};

export type DailyLeaderboardWithProfile = {
  __typename?: 'DailyLeaderboardWithProfile';
  created: Scalars['DateTime'];
  end: Scalars['DateTime'];
  entries: Array<LeaderboardEntryWithProfile>;
  id: Scalars['String'];
  start: Scalars['DateTime'];
};

export enum DeleteUserResult {
  Failed = 'FAILED',
  OnlyDeidentifyDeleted = 'ONLY_DEIDENTIFY_DELETED',
  Success = 'SUCCESS'
}

export type DictionaryOfStringAndListOfStringFilterInput = {
  and?: InputMaybe<Array<DictionaryOfStringAndListOfStringFilterInput>>;
  comparer?: InputMaybe<IEqualityComparerOfStringFilterInput>;
  count?: InputMaybe<ComparableInt32OperationFilterInput>;
  keys?: InputMaybe<ListStringOperationFilterInput>;
  or?: InputMaybe<Array<DictionaryOfStringAndListOfStringFilterInput>>;
  values?: InputMaybe<ListListStringOperationFilterInput>;
};

export type Exercise = {
  exerciseAnswers?: Maybe<Array<ExerciseAnswer>>;
  exerciseId: Scalars['String'];
  exerciseOrder: Scalars['Int'];
  exerciseType: ExerciseType;
  isComplete: Scalars['Boolean'];
};

export type ExerciseAnswer = {
  isCorrect: Scalars['Boolean'];
};

export type ExerciseAnswerFilterInput = {
  and?: InputMaybe<Array<ExerciseAnswerFilterInput>>;
  isCorrect?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<ExerciseAnswerFilterInput>>;
};

export type ExerciseAnswerInput = {
  exerciseAnswer: AnswerInput;
  exerciseId: Scalars['String'];
  lessonId: Scalars['String'];
  timezoneOffset: Scalars['String'];
  topicId: Scalars['Int'];
  unitId: Scalars['Int'];
};

export type ExerciseFilterInput = {
  and?: InputMaybe<Array<ExerciseFilterInput>>;
  exerciseAnswers?: InputMaybe<ListFilterInputTypeOfExerciseAnswerFilterInput>;
  exerciseId?: InputMaybe<StringOperationFilterInput>;
  exerciseOrder?: InputMaybe<ComparableInt32OperationFilterInput>;
  exerciseType?: InputMaybe<ExerciseTypeOperationFilterInput>;
  isComplete?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<ExerciseFilterInput>>;
};

export enum ExerciseType {
  CriteriaSelectionExercise = 'CRITERIA_SELECTION_EXERCISE',
  ImageMatchExercise = 'IMAGE_MATCH_EXERCISE',
  ImageSelectionExercise = 'IMAGE_SELECTION_EXERCISE',
  LabelSelectionExercise = 'LABEL_SELECTION_EXERCISE'
}

export type ExerciseTypeOperationFilterInput = {
  eq?: InputMaybe<ExerciseType>;
  in?: InputMaybe<Array<ExerciseType>>;
  neq?: InputMaybe<ExerciseType>;
  nin?: InputMaybe<Array<ExerciseType>>;
};

/** A connection to a list of items. */
export type GameFLowDefinitionsConnection = {
  __typename?: 'GameFLowDefinitionsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<GameFLowDefinitionsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<GameFlowDefinition>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type GameFLowDefinitionsEdge = {
  __typename?: 'GameFLowDefinitionsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: GameFlowDefinition;
};

export type GameFlowDefinition = Node & {
  __typename?: 'GameFlowDefinition';
  badgeDefinitions: Array<BadgeDefinition>;
  createdAt: Scalars['DateTime'];
  criteriaAntonyms: Array<KeyValuePairOfStringAndListOfString>;
  id: Scalars['ID'];
  lessonsPerTopic: Scalars['Int'];
  unitDefinitions: Array<UnitDefinition>;
  version: Scalars['Int'];
};

export type GameFlowDefinitionFilterInput = {
  and?: InputMaybe<Array<GameFlowDefinitionFilterInput>>;
  badgeDefinitions?: InputMaybe<ListFilterInputTypeOfBadgeDefinitionFilterInput>;
  createdAt?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  criteriaAntonyms?: InputMaybe<DictionaryOfStringAndListOfStringFilterInput>;
  id?: InputMaybe<StringOperationFilterInput>;
  lessonsPerTopic?: InputMaybe<ComparableInt32OperationFilterInput>;
  or?: InputMaybe<Array<GameFlowDefinitionFilterInput>>;
  unitDefinitions?: InputMaybe<ListFilterInputTypeOfUnitDefinitionFilterInput>;
  version?: InputMaybe<ComparableInt32OperationFilterInput>;
};

export type GameFlowDefinitionSortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  lessonsPerTopic?: InputMaybe<SortEnumType>;
  version?: InputMaybe<SortEnumType>;
};

export type GameProgress = {
  __typename?: 'GameProgress';
  definitionVersion: Scalars['Int'];
  isComplete: Scalars['Boolean'];
  lessonsPerTopic: Scalars['Int'];
  unitProgresses: Array<UnitProgress>;
};

export type GameProgressFilterInput = {
  and?: InputMaybe<Array<GameProgressFilterInput>>;
  definitionVersion?: InputMaybe<ComparableInt32OperationFilterInput>;
  isComplete?: InputMaybe<BooleanOperationFilterInput>;
  lessonsPerTopic?: InputMaybe<ComparableInt32OperationFilterInput>;
  or?: InputMaybe<Array<GameProgressFilterInput>>;
  unitProgresses?: InputMaybe<ListFilterInputTypeOfUnitProgressFilterInput>;
};

export type GameProgressSortInput = {
  definitionVersion?: InputMaybe<SortEnumType>;
  isComplete?: InputMaybe<SortEnumType>;
  lessonsPerTopic?: InputMaybe<SortEnumType>;
};

export type IEqualityComparerOfStringFilterInput = {
  and?: InputMaybe<Array<IEqualityComparerOfStringFilterInput>>;
  or?: InputMaybe<Array<IEqualityComparerOfStringFilterInput>>;
};

export type ImageMatchExercise = Exercise & {
  __typename?: 'ImageMatchExercise';
  correctMap?: Maybe<Array<KeyValuePairOfStringAndString>>;
  exerciseAnswers?: Maybe<Array<ExerciseAnswer>>;
  exerciseId: Scalars['String'];
  exerciseOrder: Scalars['Int'];
  exerciseType: ExerciseType;
  images: Array<Scalars['String']>;
  isComplete: Scalars['Boolean'];
  labels: Array<Scalars['String']>;
};

export type ImageMatchExerciseAnswer = ExerciseAnswer & {
  __typename?: 'ImageMatchExerciseAnswer';
  isCorrect: Scalars['Boolean'];
  selectedMap?: Maybe<Array<KeyValuePairOfStringAndString>>;
};

export type ImageMatchExerciseDefinition = {
  __typename?: 'ImageMatchExerciseDefinition';
  imageCount: Scalars['Int'];
};

export type ImageMatchExerciseDefinitionFilterInput = {
  and?: InputMaybe<Array<ImageMatchExerciseDefinitionFilterInput>>;
  imageCount?: InputMaybe<ComparableInt32OperationFilterInput>;
  or?: InputMaybe<Array<ImageMatchExerciseDefinitionFilterInput>>;
};

export type ImageSelectionExercise = Exercise & {
  __typename?: 'ImageSelectionExercise';
  correctImage?: Maybe<Scalars['String']>;
  exerciseAnswers?: Maybe<Array<ExerciseAnswer>>;
  exerciseId: Scalars['String'];
  exerciseOrder: Scalars['Int'];
  exerciseType: ExerciseType;
  images: Array<Scalars['String']>;
  isComplete: Scalars['Boolean'];
  targetLabel: Scalars['String'];
};

export type ImageSelectionExerciseAnswer = ExerciseAnswer & {
  __typename?: 'ImageSelectionExerciseAnswer';
  isCorrect: Scalars['Boolean'];
  selectedImage?: Maybe<Scalars['String']>;
};

export type ImageSelectionExerciseDefinition = {
  __typename?: 'ImageSelectionExerciseDefinition';
  imageCount: Scalars['Int'];
};

export type ImageSelectionExerciseDefinitionFilterInput = {
  and?: InputMaybe<Array<ImageSelectionExerciseDefinitionFilterInput>>;
  imageCount?: InputMaybe<ComparableInt32OperationFilterInput>;
  or?: InputMaybe<Array<ImageSelectionExerciseDefinitionFilterInput>>;
};

export type KeyValuePairOfStringAndListOfString = {
  __typename?: 'KeyValuePairOfStringAndListOfString';
  key: Scalars['String'];
  value: Array<Scalars['String']>;
};

export type KeyValuePairOfStringAndString = {
  __typename?: 'KeyValuePairOfStringAndString';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type KeyValuePairOfStringAndStringInput = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type LabelSelectionExercise = Exercise & {
  __typename?: 'LabelSelectionExercise';
  correctLabel?: Maybe<Scalars['String']>;
  exerciseAnswers?: Maybe<Array<ExerciseAnswer>>;
  exerciseId: Scalars['String'];
  exerciseOrder: Scalars['Int'];
  exerciseType: ExerciseType;
  image: Scalars['String'];
  isComplete: Scalars['Boolean'];
  labels: Array<Scalars['String']>;
};

export type LabelSelectionExerciseAnswer = ExerciseAnswer & {
  __typename?: 'LabelSelectionExerciseAnswer';
  isCorrect: Scalars['Boolean'];
  selectedLabel?: Maybe<Scalars['String']>;
};

export type LabelSelectionExerciseDefinition = {
  __typename?: 'LabelSelectionExerciseDefinition';
  labelCount: Scalars['Int'];
};

export type LabelSelectionExerciseDefinitionFilterInput = {
  and?: InputMaybe<Array<LabelSelectionExerciseDefinitionFilterInput>>;
  labelCount?: InputMaybe<ComparableInt32OperationFilterInput>;
  or?: InputMaybe<Array<LabelSelectionExerciseDefinitionFilterInput>>;
};

export type LeaderboardEntryWithProfile = {
  __typename?: 'LeaderboardEntryWithProfile';
  avatarUrl?: Maybe<Scalars['String']>;
  avatarVariant?: Maybe<AvatarVariant>;
  institution?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  xP: Scalars['Int'];
};

export type LeaderboardResetInfo = {
  __typename?: 'LeaderboardResetInfo';
  dailyResetTime: Scalars['DateTime'];
  id: Scalars['String'];
};

export type Lesson = {
  __typename?: 'Lesson';
  accuracy?: Maybe<Scalars['Float']>;
  completionTime?: Maybe<Scalars['DateTime']>;
  exercises: Array<Exercise>;
  experiencePoints?: Maybe<Scalars['Int']>;
  isComplete: Scalars['Boolean'];
  isPractice: Scalars['Boolean'];
  lessonId: Scalars['String'];
  startTime: Scalars['DateTime'];
};

export type LessonFilterInput = {
  accuracy?: InputMaybe<ComparableNullableOfDoubleOperationFilterInput>;
  and?: InputMaybe<Array<LessonFilterInput>>;
  completionTime?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  exercises?: InputMaybe<ListFilterInputTypeOfExerciseFilterInput>;
  experiencePoints?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  isComplete?: InputMaybe<BooleanOperationFilterInput>;
  isPractice?: InputMaybe<BooleanOperationFilterInput>;
  lessonId?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<LessonFilterInput>>;
  startTime?: InputMaybe<ComparableDateTimeOperationFilterInput>;
};

export type LifetimeLeaderboardWithProfile = {
  __typename?: 'LifetimeLeaderboardWithProfile';
  created: Scalars['DateTime'];
  entries: Array<LeaderboardEntryWithProfile>;
  id: Scalars['String'];
};

export type ListComparableInt32OperationFilterInput = {
  all?: InputMaybe<ComparableInt32OperationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ComparableInt32OperationFilterInput>;
  some?: InputMaybe<ComparableInt32OperationFilterInput>;
};

export type ListFilterInputTypeOfBadgeDefinitionFilterInput = {
  all?: InputMaybe<BadgeDefinitionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<BadgeDefinitionFilterInput>;
  some?: InputMaybe<BadgeDefinitionFilterInput>;
};

export type ListFilterInputTypeOfBadgeFilterInput = {
  all?: InputMaybe<BadgeFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<BadgeFilterInput>;
  some?: InputMaybe<BadgeFilterInput>;
};

export type ListFilterInputTypeOfExerciseAnswerFilterInput = {
  all?: InputMaybe<ExerciseAnswerFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ExerciseAnswerFilterInput>;
  some?: InputMaybe<ExerciseAnswerFilterInput>;
};

export type ListFilterInputTypeOfExerciseFilterInput = {
  all?: InputMaybe<ExerciseFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ExerciseFilterInput>;
  some?: InputMaybe<ExerciseFilterInput>;
};

export type ListFilterInputTypeOfLessonFilterInput = {
  all?: InputMaybe<LessonFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<LessonFilterInput>;
  some?: InputMaybe<LessonFilterInput>;
};

export type ListFilterInputTypeOfTopicDefinitionFilterInput = {
  all?: InputMaybe<TopicDefinitionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<TopicDefinitionFilterInput>;
  some?: InputMaybe<TopicDefinitionFilterInput>;
};

export type ListFilterInputTypeOfTopicProgressFilterInput = {
  all?: InputMaybe<TopicProgressFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<TopicProgressFilterInput>;
  some?: InputMaybe<TopicProgressFilterInput>;
};

export type ListFilterInputTypeOfUnitDefinitionFilterInput = {
  all?: InputMaybe<UnitDefinitionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<UnitDefinitionFilterInput>;
  some?: InputMaybe<UnitDefinitionFilterInput>;
};

export type ListFilterInputTypeOfUnitProgressFilterInput = {
  all?: InputMaybe<UnitProgressFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<UnitProgressFilterInput>;
  some?: InputMaybe<UnitProgressFilterInput>;
};

export type ListItemResponse = {
  __typename?: 'ListItemResponse';
  continuationToken?: Maybe<Scalars['String']>;
  listedItems: Array<Scalars['String']>;
};

export type ListListStringOperationFilterInput = {
  all?: InputMaybe<ListStringOperationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ListStringOperationFilterInput>;
  some?: InputMaybe<ListStringOperationFilterInput>;
};

export type ListStringOperationFilterInput = {
  all?: InputMaybe<StringOperationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<StringOperationFilterInput>;
  some?: InputMaybe<StringOperationFilterInput>;
};

export type Mutation = {
  __typename?: 'Mutation';
  badgeSeen: Scalars['Boolean'];
  checkExercise: Exercise;
  createGameFlowDefinition: CreateGameFlowDefinitionPayload;
  deleteAnyUser: DeleteUserResult;
  deleteCurrentUser: DeleteUserResult;
  loggedIn: User;
  markPendingReset: Scalars['Boolean'];
  onBoarded: Scalars['Boolean'];
  sendEmailVerification: Scalars['Boolean'];
  setDailyLeaderboardResetTime: Scalars['Boolean'];
  startLesson: Scalars['String'];
  startLessonWithExerciseImages: CreateLessonPayload;
  streakPopUpSeen: Scalars['Boolean'];
  updateUserProfile: UpdateUserPayload;
};


export type MutationBadgeSeenArgs = {
  badgeId: Scalars['String'];
};


export type MutationCheckExerciseArgs = {
  exerciseAnswerInput: ExerciseAnswerInput;
};


export type MutationCreateGameFlowDefinitionArgs = {
  gameFlowInput: CreateGameFlowInput;
};


export type MutationDeleteAnyUserArgs = {
  userId: Scalars['ID'];
};


export type MutationMarkPendingResetArgs = {
  userId: Scalars['ID'];
};


export type MutationSendEmailVerificationArgs = {
  clientId: Scalars['String'];
};


export type MutationSetDailyLeaderboardResetTimeArgs = {
  utcResetTime: Scalars['DateTime'];
};


export type MutationStartLessonArgs = {
  topicId: Scalars['Int'];
  unitId: Scalars['Int'];
};


export type MutationStartLessonWithExerciseImagesArgs = {
  topicId: Scalars['Int'];
  unitId: Scalars['Int'];
};


export type MutationUpdateUserProfileArgs = {
  input: UpdateUserProfileInput;
};

export type NextExercisePayload = {
  __typename?: 'NextExercisePayload';
  exercise: Exercise;
  nextExerciseImages: Array<Scalars['String']>;
};

/** The node interface is implemented by entities that have a global unique identifier. */
export type Node = {
  id: Scalars['ID'];
};

export type NullableOfAvatarVariantOperationFilterInput = {
  eq?: InputMaybe<AvatarVariant>;
  in?: InputMaybe<Array<InputMaybe<AvatarVariant>>>;
  neq?: InputMaybe<AvatarVariant>;
  nin?: InputMaybe<Array<InputMaybe<AvatarVariant>>>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** Indicates whether more edges exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean'];
  /** Indicates whether more edges exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

export type PlayerRank = {
  __typename?: 'PlayerRank';
  created: Scalars['DateTime'];
  rank?: Maybe<Scalars['Int']>;
  totalEntries: Scalars['Int'];
  xP?: Maybe<Scalars['Int']>;
};

export type Profile = {
  __typename?: 'Profile';
  avatarUrl?: Maybe<Scalars['String']>;
  avatarVariant?: Maybe<AvatarVariant>;
  institution?: Maybe<Scalars['String']>;
  lastLoginTime?: Maybe<Scalars['DateTime']>;
  onBoarded?: Maybe<Scalars['Boolean']>;
  username?: Maybe<Scalars['String']>;
};

export type ProfileFilterInput = {
  and?: InputMaybe<Array<ProfileFilterInput>>;
  avatarUrl?: InputMaybe<StringOperationFilterInput>;
  avatarVariant?: InputMaybe<NullableOfAvatarVariantOperationFilterInput>;
  institution?: InputMaybe<StringOperationFilterInput>;
  lastLoginTime?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  onBoarded?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<ProfileFilterInput>>;
  username?: InputMaybe<StringOperationFilterInput>;
};

export type ProfileInput = {
  avatarUrl?: InputMaybe<Scalars['String']>;
  avatarVariant?: InputMaybe<AvatarVariant>;
  institution?: InputMaybe<Scalars['String']>;
  lastLoginTime?: InputMaybe<Scalars['DateTime']>;
  onBoarded?: InputMaybe<Scalars['Boolean']>;
  username?: InputMaybe<Scalars['String']>;
};

export type ProfileSortInput = {
  avatarUrl?: InputMaybe<SortEnumType>;
  avatarVariant?: InputMaybe<SortEnumType>;
  institution?: InputMaybe<SortEnumType>;
  lastLoginTime?: InputMaybe<SortEnumType>;
  onBoarded?: InputMaybe<SortEnumType>;
  username?: InputMaybe<SortEnumType>;
};

export type Query = {
  __typename?: 'Query';
  achievements?: Maybe<Achievements>;
  avatars: ListItemResponse;
  dailyLeaderboard?: Maybe<DailyLeaderboardWithProfile>;
  dailyLeaderboardByDate?: Maybe<DailyLeaderboardWithProfile>;
  dailyLeaderboardRanking?: Maybe<PlayerRank>;
  gameFLowDefinitions?: Maybe<GameFLowDefinitionsConnection>;
  gameFlowDefinitionById: GameFlowDefinition;
  gameProgress?: Maybe<GameProgress>;
  latestGameFlowDefinition: GameFlowDefinition;
  leaderboardResetInfo: LeaderboardResetInfo;
  lesson: Lesson;
  lifetimeLeaderboard: LifetimeLeaderboardWithProfile;
  lifetimeLeaderboardRanking: PlayerRank;
  nextExercise: NextExercisePayload;
  /** Fetches an object given its ID. */
  node?: Maybe<Node>;
  /** Lookup nodes by a list of IDs. */
  nodes: Array<Maybe<Node>>;
  userById?: Maybe<User>;
  users?: Maybe<UsersConnection>;
  usersWithOffsetPaging?: Maybe<UserCollectionSegment>;
  whoAmI: User;
};


export type QueryAchievementsArgs = {
  timezoneOffset: Scalars['String'];
};


export type QueryAvatarsArgs = {
  continuationToken?: InputMaybe<Scalars['String']>;
};


export type QueryDailyLeaderboardArgs = {
  limit: Scalars['Int'];
};


export type QueryDailyLeaderboardByDateArgs = {
  limit: Scalars['Int'];
  utcDateTime: Scalars['DateTime'];
};


export type QueryGameFLowDefinitionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<GameFlowDefinitionSortInput>>;
  where?: InputMaybe<GameFlowDefinitionFilterInput>;
};


export type QueryGameFlowDefinitionByIdArgs = {
  id: Scalars['ID'];
};


export type QueryLessonArgs = {
  lessonId: Scalars['String'];
  topicId: Scalars['Int'];
  unitId: Scalars['Int'];
};


export type QueryLifetimeLeaderboardArgs = {
  limit: Scalars['Int'];
};


export type QueryNextExerciseArgs = {
  lessonId: Scalars['String'];
  topicId: Scalars['Int'];
  unitId: Scalars['Int'];
};


export type QueryNodeArgs = {
  id: Scalars['ID'];
};


export type QueryNodesArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryUserByIdArgs = {
  id: Scalars['ID'];
};


export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<UserSortInput>>;
  where?: InputMaybe<UserFilterInput>;
};


export type QueryUsersWithOffsetPagingArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};

export enum SortEnumType {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type StringOperationFilterInput = {
  and?: InputMaybe<Array<StringOperationFilterInput>>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  eq?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ncontains?: InputMaybe<Scalars['String']>;
  nendsWith?: InputMaybe<Scalars['String']>;
  neq?: InputMaybe<Scalars['String']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  nstartsWith?: InputMaybe<Scalars['String']>;
  or?: InputMaybe<Array<StringOperationFilterInput>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type TopicDefinition = {
  __typename?: 'TopicDefinition';
  bonusXP: Scalars['Int'];
  criteriaSelectionExerciseDefinition: CriteriaSelectionExerciseDefinition;
  difficulty: Scalars['Int'];
  exerciseCount: Scalars['Int'];
  imageMatchExerciseDefinition: ImageMatchExerciseDefinition;
  imageSelectionExerciseDefinition: ImageSelectionExerciseDefinition;
  incorrectAnswerXP: Scalars['Int'];
  labelSelectionExerciseDefinition: LabelSelectionExerciseDefinition;
  normalXP: Scalars['Int'];
  practiceXP: Scalars['Int'];
  targetLabelOptions: Array<Scalars['String']>;
  topicId: Scalars['Int'];
  topicName: Scalars['String'];
};

export type TopicDefinitionFilterInput = {
  and?: InputMaybe<Array<TopicDefinitionFilterInput>>;
  bonusXP?: InputMaybe<ComparableInt32OperationFilterInput>;
  criteriaSelectionExerciseDefinition?: InputMaybe<CriteriaSelectionExerciseDefinitionFilterInput>;
  difficulty?: InputMaybe<ComparableInt32OperationFilterInput>;
  exerciseCount?: InputMaybe<ComparableInt32OperationFilterInput>;
  imageMatchExerciseDefinition?: InputMaybe<ImageMatchExerciseDefinitionFilterInput>;
  imageSelectionExerciseDefinition?: InputMaybe<ImageSelectionExerciseDefinitionFilterInput>;
  incorrectAnswerXP?: InputMaybe<ComparableInt32OperationFilterInput>;
  labelSelectionExerciseDefinition?: InputMaybe<LabelSelectionExerciseDefinitionFilterInput>;
  normalXP?: InputMaybe<ComparableInt32OperationFilterInput>;
  or?: InputMaybe<Array<TopicDefinitionFilterInput>>;
  practiceXP?: InputMaybe<ComparableInt32OperationFilterInput>;
  targetLabelOptions?: InputMaybe<ListStringOperationFilterInput>;
  topicId?: InputMaybe<ComparableInt32OperationFilterInput>;
  topicName?: InputMaybe<StringOperationFilterInput>;
};

export type TopicProgress = {
  __typename?: 'TopicProgress';
  bonusXP: Scalars['Int'];
  completedLessonCount: Scalars['Int'];
  difficulty: Scalars['Int'];
  incorrectAnswerXP: Scalars['Int'];
  isLocked: Scalars['Boolean'];
  lessons: Array<Lesson>;
  normalXP: Scalars['Int'];
  practiceXP: Scalars['Int'];
  topicDefinitionId: Scalars['Int'];
  topicName: Scalars['String'];
};

export type TopicProgressFilterInput = {
  and?: InputMaybe<Array<TopicProgressFilterInput>>;
  bonusXP?: InputMaybe<ComparableInt32OperationFilterInput>;
  completedLessonCount?: InputMaybe<ComparableInt32OperationFilterInput>;
  difficulty?: InputMaybe<ComparableInt32OperationFilterInput>;
  incorrectAnswerXP?: InputMaybe<ComparableInt32OperationFilterInput>;
  isLocked?: InputMaybe<BooleanOperationFilterInput>;
  lessons?: InputMaybe<ListFilterInputTypeOfLessonFilterInput>;
  normalXP?: InputMaybe<ComparableInt32OperationFilterInput>;
  or?: InputMaybe<Array<TopicProgressFilterInput>>;
  practiceXP?: InputMaybe<ComparableInt32OperationFilterInput>;
  topicDefinitionId?: InputMaybe<ComparableInt32OperationFilterInput>;
  topicName?: InputMaybe<StringOperationFilterInput>;
};

export type UnitDefinition = {
  __typename?: 'UnitDefinition';
  topicDefinitions: Array<TopicDefinition>;
  unitId: Scalars['Int'];
  unitName: Scalars['String'];
};

export type UnitDefinitionFilterInput = {
  and?: InputMaybe<Array<UnitDefinitionFilterInput>>;
  or?: InputMaybe<Array<UnitDefinitionFilterInput>>;
  topicDefinitions?: InputMaybe<ListFilterInputTypeOfTopicDefinitionFilterInput>;
  unitId?: InputMaybe<ComparableInt32OperationFilterInput>;
  unitName?: InputMaybe<StringOperationFilterInput>;
};

export type UnitProgress = {
  __typename?: 'UnitProgress';
  isComplete: Scalars['Boolean'];
  topicProgresses: Array<TopicProgress>;
  unitDefinitionId: Scalars['Int'];
  unitName: Scalars['String'];
};

export type UnitProgressFilterInput = {
  and?: InputMaybe<Array<UnitProgressFilterInput>>;
  isComplete?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<UnitProgressFilterInput>>;
  topicProgresses?: InputMaybe<ListFilterInputTypeOfTopicProgressFilterInput>;
  unitDefinitionId?: InputMaybe<ComparableInt32OperationFilterInput>;
  unitName?: InputMaybe<StringOperationFilterInput>;
};

export type UpdateUserPayload = {
  __typename?: 'UpdateUserPayload';
  user: User;
};

export type UpdateUserProfileInput = {
  profile: ProfileInput;
};

export type User = Node & {
  __typename?: 'User';
  achievements?: Maybe<Achievements>;
  email?: Maybe<Scalars['String']>;
  gameProgress?: Maybe<GameProgress>;
  id: Scalars['ID'];
  profile?: Maybe<Profile>;
  requiresReset: Scalars['Boolean'];
};

export type UserCollectionSegment = {
  __typename?: 'UserCollectionSegment';
  items?: Maybe<Array<User>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int'];
};

export type UserFilterInput = {
  achievements?: InputMaybe<AchievementsFilterInput>;
  and?: InputMaybe<Array<UserFilterInput>>;
  email?: InputMaybe<StringOperationFilterInput>;
  gameProgress?: InputMaybe<GameProgressFilterInput>;
  id?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<UserFilterInput>>;
  profile?: InputMaybe<ProfileFilterInput>;
  requiresReset?: InputMaybe<BooleanOperationFilterInput>;
};

export type UserSortInput = {
  achievements?: InputMaybe<AchievementsSortInput>;
  email?: InputMaybe<SortEnumType>;
  gameProgress?: InputMaybe<GameProgressSortInput>;
  id?: InputMaybe<SortEnumType>;
  profile?: InputMaybe<ProfileSortInput>;
  requiresReset?: InputMaybe<SortEnumType>;
};

/** A connection to a list of items. */
export type UsersConnection = {
  __typename?: 'UsersConnection';
  /** A list of edges. */
  edges?: Maybe<Array<UsersEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type UsersEdge = {
  __typename?: 'UsersEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: User;
};

export type AchievementsFieldsFragment = { __typename?: 'Achievements', currentStreak: number, longestStreak: number, perfectLessons: number, latestLessonCompletionTime?: any | null, experiencePoints: number, shouldShowStreakPopUp?: boolean | null, badges: Array<{ __typename?: 'Badge', badgeDefinitionId: string, type: BadgeType, levelThresholds: Array<number>, level: number, seen: boolean }> };

type ExerciseFields_CriteriaSelectionExercise_Fragment = { __typename?: 'CriteriaSelectionExercise', image: string, criteria: Array<string>, correctCriteria?: Array<string> | null, correctCriteriaCount: number, exerciseOrder: number, exerciseId: string, exerciseType: ExerciseType, isComplete: boolean };

type ExerciseFields_ImageMatchExercise_Fragment = { __typename?: 'ImageMatchExercise', images: Array<string>, labels: Array<string>, exerciseOrder: number, exerciseId: string, exerciseType: ExerciseType, isComplete: boolean, correctMap?: Array<{ __typename?: 'KeyValuePairOfStringAndString', key: string, value: string }> | null };

type ExerciseFields_ImageSelectionExercise_Fragment = { __typename?: 'ImageSelectionExercise', images: Array<string>, targetLabel: string, correctImage?: string | null, exerciseOrder: number, exerciseId: string, exerciseType: ExerciseType, isComplete: boolean };

type ExerciseFields_LabelSelectionExercise_Fragment = { __typename?: 'LabelSelectionExercise', image: string, labels: Array<string>, correctLabel?: string | null, exerciseOrder: number, exerciseId: string, exerciseType: ExerciseType, isComplete: boolean };

export type ExerciseFieldsFragment = ExerciseFields_CriteriaSelectionExercise_Fragment | ExerciseFields_ImageMatchExercise_Fragment | ExerciseFields_ImageSelectionExercise_Fragment | ExerciseFields_LabelSelectionExercise_Fragment;

export type GameProgressFieldsFragment = { __typename?: 'GameProgress', definitionVersion: number, isComplete: boolean, lessonsPerTopic: number, unitProgresses: Array<{ __typename?: 'UnitProgress', unitName: string, unitDefinitionId: number, isComplete: boolean, topicProgresses: Array<{ __typename?: 'TopicProgress', topicDefinitionId: number, topicName: string, isLocked: boolean, completedLessonCount: number, difficulty: number, normalXP: number, bonusXP: number, incorrectAnswerXP: number, practiceXP: number, lessons: Array<{ __typename?: 'Lesson', lessonId: string, startTime: any, completionTime?: any | null, accuracy?: number | null, experiencePoints?: number | null, isComplete: boolean, isPractice: boolean, exercises: Array<{ __typename?: 'CriteriaSelectionExercise', image: string, criteria: Array<string>, correctCriteria?: Array<string> | null, correctCriteriaCount: number, exerciseOrder: number, exerciseId: string, exerciseType: ExerciseType, isComplete: boolean } | { __typename?: 'ImageMatchExercise', images: Array<string>, labels: Array<string>, exerciseOrder: number, exerciseId: string, exerciseType: ExerciseType, isComplete: boolean, correctMap?: Array<{ __typename?: 'KeyValuePairOfStringAndString', key: string, value: string }> | null } | { __typename?: 'ImageSelectionExercise', images: Array<string>, targetLabel: string, correctImage?: string | null, exerciseOrder: number, exerciseId: string, exerciseType: ExerciseType, isComplete: boolean } | { __typename?: 'LabelSelectionExercise', image: string, labels: Array<string>, correctLabel?: string | null, exerciseOrder: number, exerciseId: string, exerciseType: ExerciseType, isComplete: boolean }> }> }> }> };

export type DailyLeaderboardFieldsFragment = { __typename?: 'DailyLeaderboardWithProfile', id: string, created: any, start: any, end: any, entries: Array<{ __typename?: 'LeaderboardEntryWithProfile', xP: number, username?: string | null, institution?: string | null, avatarUrl?: string | null, avatarVariant?: AvatarVariant | null }> };

export type PlayerRankFieldsFragment = { __typename?: 'PlayerRank', created: any, rank?: number | null, totalEntries: number, xP?: number | null };

export type LessonFieldsFragment = { __typename?: 'Lesson', lessonId: string, startTime: any, completionTime?: any | null, accuracy?: number | null, experiencePoints?: number | null, isComplete: boolean, isPractice: boolean, exercises: Array<{ __typename?: 'CriteriaSelectionExercise', image: string, criteria: Array<string>, correctCriteria?: Array<string> | null, correctCriteriaCount: number, exerciseOrder: number, exerciseId: string, exerciseType: ExerciseType, isComplete: boolean } | { __typename?: 'ImageMatchExercise', images: Array<string>, labels: Array<string>, exerciseOrder: number, exerciseId: string, exerciseType: ExerciseType, isComplete: boolean, correctMap?: Array<{ __typename?: 'KeyValuePairOfStringAndString', key: string, value: string }> | null } | { __typename?: 'ImageSelectionExercise', images: Array<string>, targetLabel: string, correctImage?: string | null, exerciseOrder: number, exerciseId: string, exerciseType: ExerciseType, isComplete: boolean } | { __typename?: 'LabelSelectionExercise', image: string, labels: Array<string>, correctLabel?: string | null, exerciseOrder: number, exerciseId: string, exerciseType: ExerciseType, isComplete: boolean }> };

export type UserFieldsFragment = { __typename?: 'User', id: string, email?: string | null, requiresReset: boolean, profile?: { __typename?: 'Profile', institution?: string | null, username?: string | null, avatarUrl?: string | null, lastLoginTime?: any | null, avatarVariant?: AvatarVariant | null, onBoarded?: boolean | null } | null };

export type BadgeSeenMutationVariables = Exact<{
  badgeId: Scalars['String'];
}>;


export type BadgeSeenMutation = { __typename?: 'Mutation', badgeSeen: boolean };

export type StreakPopUpSeenMutationVariables = Exact<{ [key: string]: never; }>;


export type StreakPopUpSeenMutation = { __typename?: 'Mutation', streakPopUpSeen: boolean };

export type CheckExerciseMutationVariables = Exact<{
  exerciseAnswerInput: ExerciseAnswerInput;
}>;


export type CheckExerciseMutation = { __typename?: 'Mutation', checkExercise: { __typename?: 'CriteriaSelectionExercise', image: string, criteria: Array<string>, correctCriteria?: Array<string> | null, correctCriteriaCount: number, exerciseOrder: number, exerciseId: string, exerciseType: ExerciseType, isComplete: boolean } | { __typename?: 'ImageMatchExercise', images: Array<string>, labels: Array<string>, exerciseOrder: number, exerciseId: string, exerciseType: ExerciseType, isComplete: boolean, correctMap?: Array<{ __typename?: 'KeyValuePairOfStringAndString', key: string, value: string }> | null } | { __typename?: 'ImageSelectionExercise', images: Array<string>, targetLabel: string, correctImage?: string | null, exerciseOrder: number, exerciseId: string, exerciseType: ExerciseType, isComplete: boolean } | { __typename?: 'LabelSelectionExercise', image: string, labels: Array<string>, correctLabel?: string | null, exerciseOrder: number, exerciseId: string, exerciseType: ExerciseType, isComplete: boolean } };

export type StartLessonMutationVariables = Exact<{
  unitId: Scalars['Int'];
  topicId: Scalars['Int'];
}>;


export type StartLessonMutation = { __typename?: 'Mutation', startLesson: string };

export type StartLessonWithExerciseImagesMutationVariables = Exact<{
  unitId: Scalars['Int'];
  topicId: Scalars['Int'];
}>;


export type StartLessonWithExerciseImagesMutation = { __typename?: 'Mutation', startLessonWithExerciseImages: { __typename?: 'CreateLessonPayload', lessonId: string, exerciseImages: Array<string> } };

export type LoggedInMutationVariables = Exact<{ [key: string]: never; }>;


export type LoggedInMutation = { __typename?: 'Mutation', loggedIn: { __typename?: 'User', id: string, email?: string | null, requiresReset: boolean, profile?: { __typename?: 'Profile', institution?: string | null, username?: string | null, avatarUrl?: string | null, lastLoginTime?: any | null, avatarVariant?: AvatarVariant | null, onBoarded?: boolean | null } | null } };

export type UpdateUserProfileMutationVariables = Exact<{
  profile: UpdateUserProfileInput;
}>;


export type UpdateUserProfileMutation = { __typename?: 'Mutation', updateUserProfile: { __typename?: 'UpdateUserPayload', user: { __typename?: 'User', id: string, email?: string | null, requiresReset: boolean, profile?: { __typename?: 'Profile', institution?: string | null, username?: string | null, avatarUrl?: string | null, lastLoginTime?: any | null, avatarVariant?: AvatarVariant | null, onBoarded?: boolean | null } | null } } };

export type UserOnboardedMutationVariables = Exact<{ [key: string]: never; }>;


export type UserOnboardedMutation = { __typename?: 'Mutation', onBoarded: boolean };

export type DeleteUserMutationVariables = Exact<{ [key: string]: never; }>;


export type DeleteUserMutation = { __typename?: 'Mutation', deleteCurrentUser: DeleteUserResult };

export type VerifyEmailMutationVariables = Exact<{
  clientId: Scalars['String'];
}>;


export type VerifyEmailMutation = { __typename?: 'Mutation', sendEmailVerification: boolean };

export type AchievementsQueryVariables = Exact<{
  timezoneOffset: Scalars['String'];
}>;


export type AchievementsQuery = { __typename?: 'Query', achievements?: { __typename?: 'Achievements', currentStreak: number, longestStreak: number, perfectLessons: number, latestLessonCompletionTime?: any | null, experiencePoints: number, shouldShowStreakPopUp?: boolean | null, badges: Array<{ __typename?: 'Badge', badgeDefinitionId: string, type: BadgeType, levelThresholds: Array<number>, level: number, seen: boolean }> } | null };

export type NextExerciseQueryVariables = Exact<{
  unitId: Scalars['Int'];
  topicId: Scalars['Int'];
  lessonId: Scalars['String'];
}>;


export type NextExerciseQuery = { __typename?: 'Query', nextExercise: { __typename?: 'NextExercisePayload', nextExerciseImages: Array<string>, exercise: { __typename?: 'CriteriaSelectionExercise', image: string, criteria: Array<string>, correctCriteria?: Array<string> | null, correctCriteriaCount: number, exerciseOrder: number, exerciseId: string, exerciseType: ExerciseType, isComplete: boolean } | { __typename?: 'ImageMatchExercise', images: Array<string>, labels: Array<string>, exerciseOrder: number, exerciseId: string, exerciseType: ExerciseType, isComplete: boolean, correctMap?: Array<{ __typename?: 'KeyValuePairOfStringAndString', key: string, value: string }> | null } | { __typename?: 'ImageSelectionExercise', images: Array<string>, targetLabel: string, correctImage?: string | null, exerciseOrder: number, exerciseId: string, exerciseType: ExerciseType, isComplete: boolean } | { __typename?: 'LabelSelectionExercise', image: string, labels: Array<string>, correctLabel?: string | null, exerciseOrder: number, exerciseId: string, exerciseType: ExerciseType, isComplete: boolean } } };

export type GameProgressQueryVariables = Exact<{ [key: string]: never; }>;


export type GameProgressQuery = { __typename?: 'Query', gameProgress?: { __typename?: 'GameProgress', definitionVersion: number, isComplete: boolean, lessonsPerTopic: number, unitProgresses: Array<{ __typename?: 'UnitProgress', unitName: string, unitDefinitionId: number, isComplete: boolean, topicProgresses: Array<{ __typename?: 'TopicProgress', topicDefinitionId: number, topicName: string, isLocked: boolean, completedLessonCount: number, difficulty: number, normalXP: number, bonusXP: number, incorrectAnswerXP: number, practiceXP: number, lessons: Array<{ __typename?: 'Lesson', lessonId: string, startTime: any, completionTime?: any | null, accuracy?: number | null, experiencePoints?: number | null, isComplete: boolean, isPractice: boolean, exercises: Array<{ __typename?: 'CriteriaSelectionExercise', image: string, criteria: Array<string>, correctCriteria?: Array<string> | null, correctCriteriaCount: number, exerciseOrder: number, exerciseId: string, exerciseType: ExerciseType, isComplete: boolean } | { __typename?: 'ImageMatchExercise', images: Array<string>, labels: Array<string>, exerciseOrder: number, exerciseId: string, exerciseType: ExerciseType, isComplete: boolean, correctMap?: Array<{ __typename?: 'KeyValuePairOfStringAndString', key: string, value: string }> | null } | { __typename?: 'ImageSelectionExercise', images: Array<string>, targetLabel: string, correctImage?: string | null, exerciseOrder: number, exerciseId: string, exerciseType: ExerciseType, isComplete: boolean } | { __typename?: 'LabelSelectionExercise', image: string, labels: Array<string>, correctLabel?: string | null, exerciseOrder: number, exerciseId: string, exerciseType: ExerciseType, isComplete: boolean }> }> }> }> } | null };

export type DailyLeaderboardQueryVariables = Exact<{
  limit: Scalars['Int'];
}>;


export type DailyLeaderboardQuery = { __typename?: 'Query', dailyLeaderboard?: { __typename?: 'DailyLeaderboardWithProfile', id: string, created: any, start: any, end: any, entries: Array<{ __typename?: 'LeaderboardEntryWithProfile', xP: number, username?: string | null, institution?: string | null, avatarUrl?: string | null, avatarVariant?: AvatarVariant | null }> } | null };

export type DailyLeaderboardRankingQueryVariables = Exact<{ [key: string]: never; }>;


export type DailyLeaderboardRankingQuery = { __typename?: 'Query', dailyLeaderboardRanking?: { __typename?: 'PlayerRank', created: any, rank?: number | null, totalEntries: number, xP?: number | null } | null };

export type LessonQueryVariables = Exact<{
  unitId: Scalars['Int'];
  topicId: Scalars['Int'];
  lessonId: Scalars['String'];
}>;


export type LessonQuery = { __typename?: 'Query', lesson: { __typename?: 'Lesson', lessonId: string, startTime: any, completionTime?: any | null, accuracy?: number | null, experiencePoints?: number | null, isComplete: boolean, isPractice: boolean, exercises: Array<{ __typename?: 'CriteriaSelectionExercise', image: string, criteria: Array<string>, correctCriteria?: Array<string> | null, correctCriteriaCount: number, exerciseOrder: number, exerciseId: string, exerciseType: ExerciseType, isComplete: boolean } | { __typename?: 'ImageMatchExercise', images: Array<string>, labels: Array<string>, exerciseOrder: number, exerciseId: string, exerciseType: ExerciseType, isComplete: boolean, correctMap?: Array<{ __typename?: 'KeyValuePairOfStringAndString', key: string, value: string }> | null } | { __typename?: 'ImageSelectionExercise', images: Array<string>, targetLabel: string, correctImage?: string | null, exerciseOrder: number, exerciseId: string, exerciseType: ExerciseType, isComplete: boolean } | { __typename?: 'LabelSelectionExercise', image: string, labels: Array<string>, correctLabel?: string | null, exerciseOrder: number, exerciseId: string, exerciseType: ExerciseType, isComplete: boolean }> } };

export type UserByIdQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type UserByIdQuery = { __typename?: 'Query', userById?: { __typename?: 'User', id: string, email?: string | null, requiresReset: boolean, profile?: { __typename?: 'Profile', institution?: string | null, username?: string | null, avatarUrl?: string | null, lastLoginTime?: any | null, avatarVariant?: AvatarVariant | null, onBoarded?: boolean | null } | null } | null };

export type WhoAmIQueryVariables = Exact<{ [key: string]: never; }>;


export type WhoAmIQuery = { __typename?: 'Query', whoAmI: { __typename?: 'User', id: string } };

export const AchievementsFieldsFragmentDoc = gql`
    fragment AchievementsFields on Achievements {
  currentStreak
  longestStreak
  perfectLessons
  latestLessonCompletionTime
  experiencePoints
  shouldShowStreakPopUp
  badges {
    badgeDefinitionId
    type
    levelThresholds
    level
    seen
  }
}
    `;
export const ExerciseFieldsFragmentDoc = gql`
    fragment ExerciseFields on Exercise {
  exerciseOrder
  exerciseId
  exerciseType
  isComplete
  ... on LabelSelectionExercise {
    image
    labels
    correctLabel
  }
  ... on CriteriaSelectionExercise {
    image
    criteria
    correctCriteria
    correctCriteriaCount
  }
  ... on ImageSelectionExercise {
    images
    targetLabel
    correctImage
  }
  ... on ImageMatchExercise {
    images
    labels
    correctMap {
      key
      value
    }
  }
}
    `;
export const LessonFieldsFragmentDoc = gql`
    fragment LessonFields on Lesson {
  lessonId
  startTime
  completionTime
  accuracy
  experiencePoints
  isComplete
  isPractice
  exercises {
    ...ExerciseFields
  }
}
    ${ExerciseFieldsFragmentDoc}`;
export const GameProgressFieldsFragmentDoc = gql`
    fragment GameProgressFields on GameProgress {
  definitionVersion
  isComplete
  lessonsPerTopic
  unitProgresses {
    unitName
    unitDefinitionId
    isComplete
    topicProgresses {
      topicDefinitionId
      topicName
      isLocked
      completedLessonCount
      difficulty
      normalXP
      bonusXP
      incorrectAnswerXP
      practiceXP
      lessons {
        ...LessonFields
      }
    }
  }
}
    ${LessonFieldsFragmentDoc}`;
export const DailyLeaderboardFieldsFragmentDoc = gql`
    fragment DailyLeaderboardFields on DailyLeaderboardWithProfile {
  id
  created
  start
  end
  entries {
    xP
    username
    institution
    avatarUrl
    avatarVariant
  }
}
    `;
export const PlayerRankFieldsFragmentDoc = gql`
    fragment PlayerRankFields on PlayerRank {
  created
  rank
  totalEntries
  xP
}
    `;
export const UserFieldsFragmentDoc = gql`
    fragment UserFields on User {
  id
  email
  profile {
    institution
    username
    avatarUrl
    lastLoginTime
    avatarVariant
    onBoarded
  }
  requiresReset
}
    `;
export const BadgeSeenDocument = gql`
    mutation badgeSeen($badgeId: String!) {
  badgeSeen(badgeId: $badgeId)
}
    `;
export type BadgeSeenMutationFn = Apollo.MutationFunction<BadgeSeenMutation, BadgeSeenMutationVariables>;

/**
 * __useBadgeSeenMutation__
 *
 * To run a mutation, you first call `useBadgeSeenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBadgeSeenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [badgeSeenMutation, { data, loading, error }] = useBadgeSeenMutation({
 *   variables: {
 *      badgeId: // value for 'badgeId'
 *   },
 * });
 */
export function useBadgeSeenMutation(baseOptions?: Apollo.MutationHookOptions<BadgeSeenMutation, BadgeSeenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BadgeSeenMutation, BadgeSeenMutationVariables>(BadgeSeenDocument, options);
      }
export type BadgeSeenMutationHookResult = ReturnType<typeof useBadgeSeenMutation>;
export type BadgeSeenMutationResult = Apollo.MutationResult<BadgeSeenMutation>;
export type BadgeSeenMutationOptions = Apollo.BaseMutationOptions<BadgeSeenMutation, BadgeSeenMutationVariables>;
export const StreakPopUpSeenDocument = gql`
    mutation streakPopUpSeen {
  streakPopUpSeen
}
    `;
export type StreakPopUpSeenMutationFn = Apollo.MutationFunction<StreakPopUpSeenMutation, StreakPopUpSeenMutationVariables>;

/**
 * __useStreakPopUpSeenMutation__
 *
 * To run a mutation, you first call `useStreakPopUpSeenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStreakPopUpSeenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [streakPopUpSeenMutation, { data, loading, error }] = useStreakPopUpSeenMutation({
 *   variables: {
 *   },
 * });
 */
export function useStreakPopUpSeenMutation(baseOptions?: Apollo.MutationHookOptions<StreakPopUpSeenMutation, StreakPopUpSeenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StreakPopUpSeenMutation, StreakPopUpSeenMutationVariables>(StreakPopUpSeenDocument, options);
      }
export type StreakPopUpSeenMutationHookResult = ReturnType<typeof useStreakPopUpSeenMutation>;
export type StreakPopUpSeenMutationResult = Apollo.MutationResult<StreakPopUpSeenMutation>;
export type StreakPopUpSeenMutationOptions = Apollo.BaseMutationOptions<StreakPopUpSeenMutation, StreakPopUpSeenMutationVariables>;
export const CheckExerciseDocument = gql`
    mutation checkExercise($exerciseAnswerInput: ExerciseAnswerInput!) {
  checkExercise(exerciseAnswerInput: $exerciseAnswerInput) {
    ...ExerciseFields
  }
}
    ${ExerciseFieldsFragmentDoc}`;
export type CheckExerciseMutationFn = Apollo.MutationFunction<CheckExerciseMutation, CheckExerciseMutationVariables>;

/**
 * __useCheckExerciseMutation__
 *
 * To run a mutation, you first call `useCheckExerciseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckExerciseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkExerciseMutation, { data, loading, error }] = useCheckExerciseMutation({
 *   variables: {
 *      exerciseAnswerInput: // value for 'exerciseAnswerInput'
 *   },
 * });
 */
export function useCheckExerciseMutation(baseOptions?: Apollo.MutationHookOptions<CheckExerciseMutation, CheckExerciseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CheckExerciseMutation, CheckExerciseMutationVariables>(CheckExerciseDocument, options);
      }
export type CheckExerciseMutationHookResult = ReturnType<typeof useCheckExerciseMutation>;
export type CheckExerciseMutationResult = Apollo.MutationResult<CheckExerciseMutation>;
export type CheckExerciseMutationOptions = Apollo.BaseMutationOptions<CheckExerciseMutation, CheckExerciseMutationVariables>;
export const StartLessonDocument = gql`
    mutation startLesson($unitId: Int!, $topicId: Int!) {
  startLesson(unitId: $unitId, topicId: $topicId)
}
    `;
export type StartLessonMutationFn = Apollo.MutationFunction<StartLessonMutation, StartLessonMutationVariables>;

/**
 * __useStartLessonMutation__
 *
 * To run a mutation, you first call `useStartLessonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartLessonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startLessonMutation, { data, loading, error }] = useStartLessonMutation({
 *   variables: {
 *      unitId: // value for 'unitId'
 *      topicId: // value for 'topicId'
 *   },
 * });
 */
export function useStartLessonMutation(baseOptions?: Apollo.MutationHookOptions<StartLessonMutation, StartLessonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartLessonMutation, StartLessonMutationVariables>(StartLessonDocument, options);
      }
export type StartLessonMutationHookResult = ReturnType<typeof useStartLessonMutation>;
export type StartLessonMutationResult = Apollo.MutationResult<StartLessonMutation>;
export type StartLessonMutationOptions = Apollo.BaseMutationOptions<StartLessonMutation, StartLessonMutationVariables>;
export const StartLessonWithExerciseImagesDocument = gql`
    mutation startLessonWithExerciseImages($unitId: Int!, $topicId: Int!) {
  startLessonWithExerciseImages(unitId: $unitId, topicId: $topicId) {
    lessonId
    exerciseImages
  }
}
    `;
export type StartLessonWithExerciseImagesMutationFn = Apollo.MutationFunction<StartLessonWithExerciseImagesMutation, StartLessonWithExerciseImagesMutationVariables>;

/**
 * __useStartLessonWithExerciseImagesMutation__
 *
 * To run a mutation, you first call `useStartLessonWithExerciseImagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartLessonWithExerciseImagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startLessonWithExerciseImagesMutation, { data, loading, error }] = useStartLessonWithExerciseImagesMutation({
 *   variables: {
 *      unitId: // value for 'unitId'
 *      topicId: // value for 'topicId'
 *   },
 * });
 */
export function useStartLessonWithExerciseImagesMutation(baseOptions?: Apollo.MutationHookOptions<StartLessonWithExerciseImagesMutation, StartLessonWithExerciseImagesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartLessonWithExerciseImagesMutation, StartLessonWithExerciseImagesMutationVariables>(StartLessonWithExerciseImagesDocument, options);
      }
export type StartLessonWithExerciseImagesMutationHookResult = ReturnType<typeof useStartLessonWithExerciseImagesMutation>;
export type StartLessonWithExerciseImagesMutationResult = Apollo.MutationResult<StartLessonWithExerciseImagesMutation>;
export type StartLessonWithExerciseImagesMutationOptions = Apollo.BaseMutationOptions<StartLessonWithExerciseImagesMutation, StartLessonWithExerciseImagesMutationVariables>;
export const LoggedInDocument = gql`
    mutation loggedIn {
  loggedIn {
    ...UserFields
  }
}
    ${UserFieldsFragmentDoc}`;
export type LoggedInMutationFn = Apollo.MutationFunction<LoggedInMutation, LoggedInMutationVariables>;

/**
 * __useLoggedInMutation__
 *
 * To run a mutation, you first call `useLoggedInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoggedInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loggedInMutation, { data, loading, error }] = useLoggedInMutation({
 *   variables: {
 *   },
 * });
 */
export function useLoggedInMutation(baseOptions?: Apollo.MutationHookOptions<LoggedInMutation, LoggedInMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoggedInMutation, LoggedInMutationVariables>(LoggedInDocument, options);
      }
export type LoggedInMutationHookResult = ReturnType<typeof useLoggedInMutation>;
export type LoggedInMutationResult = Apollo.MutationResult<LoggedInMutation>;
export type LoggedInMutationOptions = Apollo.BaseMutationOptions<LoggedInMutation, LoggedInMutationVariables>;
export const UpdateUserProfileDocument = gql`
    mutation updateUserProfile($profile: UpdateUserProfileInput!) {
  updateUserProfile(input: $profile) {
    user {
      ...UserFields
    }
  }
}
    ${UserFieldsFragmentDoc}`;
export type UpdateUserProfileMutationFn = Apollo.MutationFunction<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>;

/**
 * __useUpdateUserProfileMutation__
 *
 * To run a mutation, you first call `useUpdateUserProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserProfileMutation, { data, loading, error }] = useUpdateUserProfileMutation({
 *   variables: {
 *      profile: // value for 'profile'
 *   },
 * });
 */
export function useUpdateUserProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>(UpdateUserProfileDocument, options);
      }
export type UpdateUserProfileMutationHookResult = ReturnType<typeof useUpdateUserProfileMutation>;
export type UpdateUserProfileMutationResult = Apollo.MutationResult<UpdateUserProfileMutation>;
export type UpdateUserProfileMutationOptions = Apollo.BaseMutationOptions<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>;
export const UserOnboardedDocument = gql`
    mutation userOnboarded {
  onBoarded
}
    `;
export type UserOnboardedMutationFn = Apollo.MutationFunction<UserOnboardedMutation, UserOnboardedMutationVariables>;

/**
 * __useUserOnboardedMutation__
 *
 * To run a mutation, you first call `useUserOnboardedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserOnboardedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userOnboardedMutation, { data, loading, error }] = useUserOnboardedMutation({
 *   variables: {
 *   },
 * });
 */
export function useUserOnboardedMutation(baseOptions?: Apollo.MutationHookOptions<UserOnboardedMutation, UserOnboardedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserOnboardedMutation, UserOnboardedMutationVariables>(UserOnboardedDocument, options);
      }
export type UserOnboardedMutationHookResult = ReturnType<typeof useUserOnboardedMutation>;
export type UserOnboardedMutationResult = Apollo.MutationResult<UserOnboardedMutation>;
export type UserOnboardedMutationOptions = Apollo.BaseMutationOptions<UserOnboardedMutation, UserOnboardedMutationVariables>;
export const DeleteUserDocument = gql`
    mutation deleteUser {
  deleteCurrentUser
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const VerifyEmailDocument = gql`
    mutation verifyEmail($clientId: String!) {
  sendEmailVerification(clientId: $clientId)
}
    `;
export type VerifyEmailMutationFn = Apollo.MutationFunction<VerifyEmailMutation, VerifyEmailMutationVariables>;

/**
 * __useVerifyEmailMutation__
 *
 * To run a mutation, you first call `useVerifyEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyEmailMutation, { data, loading, error }] = useVerifyEmailMutation({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useVerifyEmailMutation(baseOptions?: Apollo.MutationHookOptions<VerifyEmailMutation, VerifyEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyEmailMutation, VerifyEmailMutationVariables>(VerifyEmailDocument, options);
      }
export type VerifyEmailMutationHookResult = ReturnType<typeof useVerifyEmailMutation>;
export type VerifyEmailMutationResult = Apollo.MutationResult<VerifyEmailMutation>;
export type VerifyEmailMutationOptions = Apollo.BaseMutationOptions<VerifyEmailMutation, VerifyEmailMutationVariables>;
export const AchievementsDocument = gql`
    query achievements($timezoneOffset: String!) {
  achievements(timezoneOffset: $timezoneOffset) {
    ...AchievementsFields
  }
}
    ${AchievementsFieldsFragmentDoc}`;

/**
 * __useAchievementsQuery__
 *
 * To run a query within a React component, call `useAchievementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAchievementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAchievementsQuery({
 *   variables: {
 *      timezoneOffset: // value for 'timezoneOffset'
 *   },
 * });
 */
export function useAchievementsQuery(baseOptions: Apollo.QueryHookOptions<AchievementsQuery, AchievementsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AchievementsQuery, AchievementsQueryVariables>(AchievementsDocument, options);
      }
export function useAchievementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AchievementsQuery, AchievementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AchievementsQuery, AchievementsQueryVariables>(AchievementsDocument, options);
        }
export type AchievementsQueryHookResult = ReturnType<typeof useAchievementsQuery>;
export type AchievementsLazyQueryHookResult = ReturnType<typeof useAchievementsLazyQuery>;
export type AchievementsQueryResult = Apollo.QueryResult<AchievementsQuery, AchievementsQueryVariables>;
export const NextExerciseDocument = gql`
    query nextExercise($unitId: Int!, $topicId: Int!, $lessonId: String!) {
  nextExercise(unitId: $unitId, topicId: $topicId, lessonId: $lessonId) {
    exercise {
      ...ExerciseFields
    }
    nextExerciseImages
  }
}
    ${ExerciseFieldsFragmentDoc}`;

/**
 * __useNextExerciseQuery__
 *
 * To run a query within a React component, call `useNextExerciseQuery` and pass it any options that fit your needs.
 * When your component renders, `useNextExerciseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNextExerciseQuery({
 *   variables: {
 *      unitId: // value for 'unitId'
 *      topicId: // value for 'topicId'
 *      lessonId: // value for 'lessonId'
 *   },
 * });
 */
export function useNextExerciseQuery(baseOptions: Apollo.QueryHookOptions<NextExerciseQuery, NextExerciseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NextExerciseQuery, NextExerciseQueryVariables>(NextExerciseDocument, options);
      }
export function useNextExerciseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NextExerciseQuery, NextExerciseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NextExerciseQuery, NextExerciseQueryVariables>(NextExerciseDocument, options);
        }
export type NextExerciseQueryHookResult = ReturnType<typeof useNextExerciseQuery>;
export type NextExerciseLazyQueryHookResult = ReturnType<typeof useNextExerciseLazyQuery>;
export type NextExerciseQueryResult = Apollo.QueryResult<NextExerciseQuery, NextExerciseQueryVariables>;
export const GameProgressDocument = gql`
    query gameProgress {
  gameProgress {
    ...GameProgressFields
  }
}
    ${GameProgressFieldsFragmentDoc}`;

/**
 * __useGameProgressQuery__
 *
 * To run a query within a React component, call `useGameProgressQuery` and pass it any options that fit your needs.
 * When your component renders, `useGameProgressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGameProgressQuery({
 *   variables: {
 *   },
 * });
 */
export function useGameProgressQuery(baseOptions?: Apollo.QueryHookOptions<GameProgressQuery, GameProgressQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GameProgressQuery, GameProgressQueryVariables>(GameProgressDocument, options);
      }
export function useGameProgressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GameProgressQuery, GameProgressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GameProgressQuery, GameProgressQueryVariables>(GameProgressDocument, options);
        }
export type GameProgressQueryHookResult = ReturnType<typeof useGameProgressQuery>;
export type GameProgressLazyQueryHookResult = ReturnType<typeof useGameProgressLazyQuery>;
export type GameProgressQueryResult = Apollo.QueryResult<GameProgressQuery, GameProgressQueryVariables>;
export const DailyLeaderboardDocument = gql`
    query dailyLeaderboard($limit: Int!) {
  dailyLeaderboard(limit: $limit) {
    ...DailyLeaderboardFields
  }
}
    ${DailyLeaderboardFieldsFragmentDoc}`;

/**
 * __useDailyLeaderboardQuery__
 *
 * To run a query within a React component, call `useDailyLeaderboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useDailyLeaderboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDailyLeaderboardQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useDailyLeaderboardQuery(baseOptions: Apollo.QueryHookOptions<DailyLeaderboardQuery, DailyLeaderboardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DailyLeaderboardQuery, DailyLeaderboardQueryVariables>(DailyLeaderboardDocument, options);
      }
export function useDailyLeaderboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DailyLeaderboardQuery, DailyLeaderboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DailyLeaderboardQuery, DailyLeaderboardQueryVariables>(DailyLeaderboardDocument, options);
        }
export type DailyLeaderboardQueryHookResult = ReturnType<typeof useDailyLeaderboardQuery>;
export type DailyLeaderboardLazyQueryHookResult = ReturnType<typeof useDailyLeaderboardLazyQuery>;
export type DailyLeaderboardQueryResult = Apollo.QueryResult<DailyLeaderboardQuery, DailyLeaderboardQueryVariables>;
export const DailyLeaderboardRankingDocument = gql`
    query dailyLeaderboardRanking {
  dailyLeaderboardRanking {
    ...PlayerRankFields
  }
}
    ${PlayerRankFieldsFragmentDoc}`;

/**
 * __useDailyLeaderboardRankingQuery__
 *
 * To run a query within a React component, call `useDailyLeaderboardRankingQuery` and pass it any options that fit your needs.
 * When your component renders, `useDailyLeaderboardRankingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDailyLeaderboardRankingQuery({
 *   variables: {
 *   },
 * });
 */
export function useDailyLeaderboardRankingQuery(baseOptions?: Apollo.QueryHookOptions<DailyLeaderboardRankingQuery, DailyLeaderboardRankingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DailyLeaderboardRankingQuery, DailyLeaderboardRankingQueryVariables>(DailyLeaderboardRankingDocument, options);
      }
export function useDailyLeaderboardRankingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DailyLeaderboardRankingQuery, DailyLeaderboardRankingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DailyLeaderboardRankingQuery, DailyLeaderboardRankingQueryVariables>(DailyLeaderboardRankingDocument, options);
        }
export type DailyLeaderboardRankingQueryHookResult = ReturnType<typeof useDailyLeaderboardRankingQuery>;
export type DailyLeaderboardRankingLazyQueryHookResult = ReturnType<typeof useDailyLeaderboardRankingLazyQuery>;
export type DailyLeaderboardRankingQueryResult = Apollo.QueryResult<DailyLeaderboardRankingQuery, DailyLeaderboardRankingQueryVariables>;
export const LessonDocument = gql`
    query lesson($unitId: Int!, $topicId: Int!, $lessonId: String!) {
  lesson(unitId: $unitId, topicId: $topicId, lessonId: $lessonId) {
    ...LessonFields
  }
}
    ${LessonFieldsFragmentDoc}`;

/**
 * __useLessonQuery__
 *
 * To run a query within a React component, call `useLessonQuery` and pass it any options that fit your needs.
 * When your component renders, `useLessonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLessonQuery({
 *   variables: {
 *      unitId: // value for 'unitId'
 *      topicId: // value for 'topicId'
 *      lessonId: // value for 'lessonId'
 *   },
 * });
 */
export function useLessonQuery(baseOptions: Apollo.QueryHookOptions<LessonQuery, LessonQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LessonQuery, LessonQueryVariables>(LessonDocument, options);
      }
export function useLessonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LessonQuery, LessonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LessonQuery, LessonQueryVariables>(LessonDocument, options);
        }
export type LessonQueryHookResult = ReturnType<typeof useLessonQuery>;
export type LessonLazyQueryHookResult = ReturnType<typeof useLessonLazyQuery>;
export type LessonQueryResult = Apollo.QueryResult<LessonQuery, LessonQueryVariables>;
export const UserByIdDocument = gql`
    query userById($userId: ID!) {
  userById(id: $userId) {
    ...UserFields
  }
}
    ${UserFieldsFragmentDoc}`;

/**
 * __useUserByIdQuery__
 *
 * To run a query within a React component, call `useUserByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserByIdQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserByIdQuery(baseOptions: Apollo.QueryHookOptions<UserByIdQuery, UserByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserByIdQuery, UserByIdQueryVariables>(UserByIdDocument, options);
      }
export function useUserByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserByIdQuery, UserByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserByIdQuery, UserByIdQueryVariables>(UserByIdDocument, options);
        }
export type UserByIdQueryHookResult = ReturnType<typeof useUserByIdQuery>;
export type UserByIdLazyQueryHookResult = ReturnType<typeof useUserByIdLazyQuery>;
export type UserByIdQueryResult = Apollo.QueryResult<UserByIdQuery, UserByIdQueryVariables>;
export const WhoAmIDocument = gql`
    query whoAmI {
  whoAmI {
    id
  }
}
    `;

/**
 * __useWhoAmIQuery__
 *
 * To run a query within a React component, call `useWhoAmIQuery` and pass it any options that fit your needs.
 * When your component renders, `useWhoAmIQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWhoAmIQuery({
 *   variables: {
 *   },
 * });
 */
export function useWhoAmIQuery(baseOptions?: Apollo.QueryHookOptions<WhoAmIQuery, WhoAmIQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WhoAmIQuery, WhoAmIQueryVariables>(WhoAmIDocument, options);
      }
export function useWhoAmILazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WhoAmIQuery, WhoAmIQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WhoAmIQuery, WhoAmIQueryVariables>(WhoAmIDocument, options);
        }
export type WhoAmIQueryHookResult = ReturnType<typeof useWhoAmIQuery>;
export type WhoAmILazyQueryHookResult = ReturnType<typeof useWhoAmILazyQuery>;
export type WhoAmIQueryResult = Apollo.QueryResult<WhoAmIQuery, WhoAmIQueryVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "Exercise": [
      "CriteriaSelectionExercise",
      "ImageMatchExercise",
      "ImageSelectionExercise",
      "LabelSelectionExercise"
    ],
    "ExerciseAnswer": [
      "CriteriaSelectionExerciseAnswer",
      "ImageMatchExerciseAnswer",
      "ImageSelectionExerciseAnswer",
      "LabelSelectionExerciseAnswer"
    ],
    "Node": [
      "GameFlowDefinition",
      "User"
    ]
  }
};
      export default result;
    