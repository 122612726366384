import { Theme } from '@emotion/react';
import { SxProps, Typography } from '@mui/material';
import { LESSON_TEXTS } from 'Constants/keys';

export interface UnitTitleBarProps {
  unitName: string;
  unitNumber: number;
  style?: SxProps<Theme>;
}

const UnitTitleBar = ({ unitName, unitNumber, style }: UnitTitleBarProps) => {
  const text = LESSON_TEXTS.UNIT + unitNumber + ': ' + unitName;
  return (
    <Typography
      variant="title"
      sx={{
        pl: 5,
        backgroundColor: 'primary.dark',
        borderRadius: 4,
        fontWeight: 'medium',
        fontSize: '1.25rem',
        width: { xs: '65vw', mob: '60vw', tab: '50vw', lap: '50vw', xl: '25vw' },
        lineHeight: 2.1,
        ...style,
      }}
    >
      {text}
    </Typography>
  );
};

export default UnitTitleBar;
