import { Box, Paper, Stack, SxProps, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import { LESSON_TEXTS } from 'Constants/keys';
import { ReactComponent as GameComplete } from 'Assets/Icons/SVG/game-complete.svg';
import { ReactComponent as Star } from 'Assets/Icons/SVG/game-incomplete.svg';

export interface GameEndProps {
  isComplete: boolean;
  style?: SxProps<Theme>;
}

export default function GameEnd({ isComplete, style }: GameEndProps) {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('mob'));
  return isComplete ? (
    <Paper
      sx={{
        alignItems: 'center',
        backgroundColor: 'primary.dark',
        width: { xs: '96vw', mob: '96vw', tab: '67.5vw' },
        height: 375,
        ...style,
      }}
    >
      <Stack spacing={{ xs: 5, mb: 10 }} sx={{ mx: { xs: 8, mob: 16 } }}>
        <GameComplete
          fill={theme.palette.white.main}
          style={{
            alignSelf: 'center',
            marginTop: 12,
            width: isSmall ? 170 : '100%',
          }}
        />
        <Stack spacing={4} sx={{ pb: 4 }}>
          <Typography variant="h2" color="white.main" textAlign="center" fontWeight="bold">
            {LESSON_TEXTS.CONGRATULATIONS}
          </Typography>
          <Typography variant="body1" textAlign="center" color="white.main">
            {LESSON_TEXTS.CONGRATULATIONS_SUBTEXT}
          </Typography>
        </Stack>
      </Stack>
    </Paper>
  ) : (
    <Box display={'flex'} alignItems={'center'} justifyContent={'top'} sx={{ height: 300, ...style }}>
      <Star fill={theme.palette.lesson.gameEnd} />
    </Box>
  );
}
