import { useState } from 'react';
import { AvatarVariant } from 'graphql/generated/graphql';
import SelectableAvatar from './SelectableAvatar';
import Grid from '@mui/material/Unstable_Grid2';
import { AvatarObject } from 'Components/EditProfile';
import { EMPTY_JSON_STRING, GAME_ELEMENT } from 'Constants/keys';
import { LoggedInUser } from 'Screens/Home/Tabs/Profile/ProfilePage';
import Avatar1 from 'Assets/Icons/PNG/avatar-1.png';
import Avatar2 from 'Assets/Icons/PNG/avatar-2.png';
import Avatar3 from 'Assets/Icons/PNG/avatar-3.png';
import Avatar4 from 'Assets/Icons/PNG/avatar-4.png';
import Avatar5 from 'Assets/Icons/PNG/avatar-5.png';
import Avatar6 from 'Assets/Icons/PNG/avatar-6.png';
import Avatar7 from 'Assets/Icons/PNG/avatar-7.png';
import Avatar8 from 'Assets/Icons/PNG/avatar-8.png';
import Avatar9 from 'Assets/Icons/PNG/avatar-9.png';
import Avatar10 from 'Assets/Icons/PNG/avatar-10.png';

export interface AvatarSelectionProps {
  username: string;
  onAvatarSelected: (avatar: AvatarObject) => void;
}

export function AvatarSelections({ username, onAvatarSelected }: AvatarSelectionProps) {
  const loggedInUser = JSON.parse(
    window.localStorage.getItem(GAME_ELEMENT.LOGGED_IN_USER) ?? EMPTY_JSON_STRING
  ) as LoggedInUser;

  const avatarUrl = loggedInUser?.profile?.avatarUrl;
  const avatarVariant = loggedInUser?.profile?.avatarVariant;
  const avatars: AvatarObject[] = [];

  avatars.push({ type: AvatarVariant.LightInitial });
  avatars.push({ type: AvatarVariant.DarkInitial });
  avatars.push({ type: AvatarVariant.Url, image: Avatar1 });
  avatars.push({ type: AvatarVariant.Url, image: Avatar2 });
  avatars.push({ type: AvatarVariant.Url, image: Avatar3 });
  avatars.push({ type: AvatarVariant.Url, image: Avatar4 });
  avatars.push({ type: AvatarVariant.Url, image: Avatar5 });
  avatars.push({ type: AvatarVariant.Url, image: Avatar6 });
  avatars.push({ type: AvatarVariant.Url, image: Avatar7 });
  avatars.push({ type: AvatarVariant.Url, image: Avatar8 });
  avatars.push({ type: AvatarVariant.Url, image: Avatar9 });
  avatars.push({ type: AvatarVariant.Url, image: Avatar10 });

  // Find the index of the user's current saved avatar, before they choose a new avatar.
  // Set this as the initial state of the selection.
  let currentAvatarIndex = 0;
  if (avatarVariant !== AvatarVariant.Url) {
    const currentAvatar = avatars.filter((item) => item.type === avatarVariant)[0];
    currentAvatarIndex = avatars.indexOf(currentAvatar);
  } else {
    if (avatarUrl) {
      const currentAvatar = avatars.filter((item) => item.image === avatarUrl)[0];
      currentAvatarIndex = avatars.indexOf(currentAvatar);
    }
  }

  const [selectedIndex, setSelectedIndex] = useState(currentAvatarIndex);

  return (
    <Grid container rowSpacing={{ xs: 4, mob: 2, tab: 1 }} sx={{ overflowY: 'auto' }}>
      {avatars.map((item, index: number) => (
        <Grid
          key={index}
          xs={6}
          mob={6}
          tab={4}
          lap={4}
          des={4}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <SelectableAvatar
            username={username}
            avatarVariant={item.type}
            image={item.image}
            onClick={() => {
              onAvatarSelected(avatars[index]);
              setSelectedIndex(index);
            }}
            isSelected={selectedIndex === index}
          />
        </Grid>
      ))}
    </Grid>
  );
}
