import { Stack } from '@mui/material';
import BoxedContainer from 'Containers/BoxedContainer';
import CustomButton from 'Components/General/CustomButton';
import TitleBar from 'Components/General/TitleBar';
import { SETTINGS } from 'Constants/keys';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { ROUTE_PATHS } from 'Routes/RouteKeys';
import { useState } from 'react';
import AppModal, { AppModalType, UserDeleteCall } from 'Components/General/AppModal';
import { useAuth0 } from '@auth0/auth0-react';
import { DeleteUserResult, useDeleteUserMutation } from 'graphql/generated/graphql';

const SettingsPage = () => {
  const navigate = useNavigate();
  const { logout } = useAuth0();

  const [deleteUser] = useDeleteUserMutation();

  //Control to show/hide quit delete user confirmation
  const [open, setOpen] = useState(false);
  const [deleteState, setDeleteState] = useState(UserDeleteCall.NoAttempt);

  const makeDeletion = async () => {
    setDeleteState(UserDeleteCall.InProgress);
    await deleteUser()
      .then((response) => {
        switch (response?.data?.deleteCurrentUser as DeleteUserResult) {
          case DeleteUserResult.OnlyDeidentifyDeleted:
            setDeleteState(UserDeleteCall.PartialSuccess);
            break;
          case DeleteUserResult.Success:
            setDeleteState(UserDeleteCall.Success);
            break;
          default:
            setDeleteState(UserDeleteCall.Failure);
        }
      })
      .catch((error) => {
        console.log(error);
        setDeleteState(UserDeleteCall.Failure);
      });
  };

  function navigateToRoute(route: string) {
    navigate(ROUTE_PATHS.ROOT + route);
  }
  return (
    <Routes>
      <Route
        path={ROUTE_PATHS.BLANK}
        element={
          <BoxedContainer justifyTop={true}>
            <TitleBar title={SETTINGS.TITLE} />
            <Stack mx={4} mt={10} mb={3}>
              <Stack mx={7} spacing={6}>
                <CustomButton
                  style={{ width: '100%', mt: 2 }}
                  type="outlined"
                  title={SETTINGS.CONTACT}
                  onPress={() => {
                    navigateToRoute(ROUTE_PATHS.CONTACT);
                  }}
                />
                <CustomButton
                  style={{ width: '100%', mt: 2 }}
                  type="outlined"
                  title={SETTINGS.TERMS}
                  onPress={() => {
                    navigateToRoute(ROUTE_PATHS.TERMS);
                  }}
                />
                <CustomButton
                  style={{ width: '100%', mt: 2 }}
                  type="outlined"
                  title={SETTINGS.PRIVACY}
                  onPress={() => {
                    navigateToRoute(ROUTE_PATHS.PRIVACY);
                  }}
                />
                <CustomButton
                  style={{ width: '100%', mt: 2 }}
                  type="outlined"
                  title={SETTINGS.LICENSE}
                  onPress={() => {
                    navigateToRoute(ROUTE_PATHS.LICENSE);
                  }}
                />
                <CustomButton
                  style={{ width: '100%', mt: 2 }}
                  type="outlined"
                  title={SETTINGS.DELETE}
                  onPress={() => {
                    setOpen(true);
                  }}
                />
              </Stack>
            </Stack>
            <AppModal
              open={open}
              onPositiveAction={() => {
                setOpen(false);
              }}
              onNegativeAction={() => {
                deleteState == UserDeleteCall.Success ? logout({ returnTo: window.location.origin }) : makeDeletion();
              }}
              onClose={() => {
                if (deleteState == UserDeleteCall.NoAttempt) setOpen(false);
              }}
              deleteState={deleteState}
              modalType={AppModalType.DeleteConfirmation}
            />
          </BoxedContainer>
        }
      />
    </Routes>
  );
};

export default SettingsPage;
