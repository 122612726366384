import { Stack, Typography } from '@mui/material';
import { ReactComponent as SadMascot } from 'Assets/Mascots/Lesson/ExitMascot.svg';
import CustomButton from 'Components/General/CustomButton';
import TitleBar from 'Components/General/TitleBar';
import { ERROR_PAGE } from 'Constants/keys';
import BoxedContainer from 'Containers/BoxedContainer';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATHS } from 'Routes/RouteKeys';

// Shows an error screen. Return it when a component encounters an error.
// By default, clicking the retry button will reload the whole app.
// To instead refetch specific queries, you should:
//  - Get the refetch functions for those queries and call them in onRetry.
//  - Get the networkStatus parameter of each query, and show a loading screen if
//       this status is NetworkStatus.refetch
//  - Pass the argument notifyOnNetworkStatusChange: true to each query.
//  For more info see https://www.apollographql.com/docs/react/data/queries#refetching

export interface ErrorScreenProps {
  // Function to call when the retry button is clicked. If not defined,
  // entire page will be reloaded.
  onRetry?: () => void;
  pageTitle?: string;
  message?: string;
}

const ErrorScreen = ({ onRetry, pageTitle, message }: ErrorScreenProps) => {
  const navigate = useNavigate();

  if (message) console.log(message);

  useEffect(() => {
    // After the component renders, prevent the user from scrolling.
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'scroll';
    };
  });

  //Put the error screen above everything else in the app except the nav bar.
  return (
    <BoxedContainer
      bgColor="primary.appBackground"
      justifyTop
      style={{
        flexDirection: 'column',
        display: 'flex',
        justifySelf: 'center',
        zIndex: 60,
      }}
    >
      {pageTitle && <TitleBar title={pageTitle} hideIcon />}
      <Stack mt={{ xs: 0, mob: 10 }} mb="auto" maxWidth={640} spacing={6} alignItems="center">
        <SadMascot width="50vw" height="20vh" style={{ maxWidth: 260, maxHeight: 200 }} />

        <Typography variant="h1" fontWeight="bold" mt={4} color="white.main">
          {ERROR_PAGE.ERROR_TEXT}
        </Typography>
        <Typography fontSize="1.125rem" lineHeight="1.5rem" fontWeight="regular" textAlign="center">
          {ERROR_PAGE.ERROR_SUBTEXT}
        </Typography>
        {message && (
          <Typography
            fontSize="1.125rem"
            lineHeight="1.5rem"
            fontWeight="regular"
            maxWidth={640}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {message}
          </Typography>
        )}

        <CustomButton
          type="outlined"
          onPress={() => {
            if (onRetry) {
              onRetry();
            } else {
              window.location.reload();
            }
          }}
          title={ERROR_PAGE.TRY_AGAIN}
        />
        <CustomButton
          type="outlined"
          onPress={() => {
            navigate(ROUTE_PATHS.ROOT + ROUTE_PATHS.CONTACT);
          }}
          title={ERROR_PAGE.CONTACT_SUPPORT}
        />
      </Stack>
    </BoxedContainer>
  );
};

export default ErrorScreen;

