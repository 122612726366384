import { Box, Paper, Stack, SxProps, Theme, Typography } from '@mui/material';
import { Badge } from 'graphql/generated/graphql';
import AchievementBadge from './AchievementBadge';
import BadgeData from './BadgeData';

export interface AchievementListItemProps {
  badge: Badge;
  style?: SxProps<Theme>;
}

const AchievementListItem = ({ badge, style }: AchievementListItemProps) => {
  let achievementDescription;

  if (badge.level === 0) {
    // If user is at level 0, display what is required to unlock the first level.
    achievementDescription =
      BadgeData[badge.type].descriptions.locked +
      badge.levelThresholds[0] +
      BadgeData[badge.type].descriptions.lockedSuffix;
  } else if (badge.level < badge.levelThresholds.length) {
    // If user has unlocked a level below the max level, display the text for that level.
    achievementDescription =
      BadgeData[badge.type].descriptions.unlocked +
      badge.levelThresholds[badge.level - 1] +
      BadgeData[badge.type].descriptions.unlockedSuffix;
  } else {
    // If user is at max level, display the max level text.
    achievementDescription =
      BadgeData[badge.type].descriptions.maxUnlocked +
      badge.levelThresholds[badge.levelThresholds.length - 1] +
      BadgeData[badge.type].descriptions.maxUnlockedSuffix;
  }

  return (
    <Paper
      sx={{
        height: '18vh',
        maxHeight: 150,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'lesson.achievementPanelBackground',
        ...style,
      }}
    >
      <Box width={1} height={1} flexDirection="column" display="flex" justifyContent={'center'} alignItems={'center'}>
        <Stack
          width={1}
          direction={'row'}
          display={'flex'}
          spacing={{ xs: 5, mob: 5, tab: 10 }}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <AchievementBadge
            badge={badge}
            isSmall
            style={{
              ml: { xs: 2, mob: 4, tab: 0 },
              height: { xs: 75, mob: 110 },
              width: { xs: 75, mob: 110 },
              alignSelf: 'left',
            }}
          />
          <Stack
            direction={'column'}
            spacing={{ xs: 1, mob: 4 }}
            sx={{ alignSelf: 'left', width: { xs: 0.575, mob: 0.6 } }}
          >
            <Typography variant="lessonEndTitle" color="text.primary" fontSize="1.125rem">
              {BadgeData[badge.type].displayName}
            </Typography>
            <Typography variant="body1" color="text.primary" maxWidth={{ xs: 1, mob: 0.9 }}>
              {achievementDescription}
            </Typography>
          </Stack>
        </Stack>
      </Box>
    </Paper>
  );
};

export default AchievementListItem;
