import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import HomePage from 'Screens/Home/HomePage';
import ExercisePage from 'Screens/Home/Tabs/Lessons/ExercisePage';
import EditProfilePage from 'Screens/Home/Tabs/Profile/EditProfilePage';
import SetupProfilePage from 'Screens/Home/Tabs/Profile/SetupProfilePage';
import { ROUTE_PATHS } from './RouteKeys';
import { GAME_ELEMENT } from 'Constants/keys';

export const PrivateRoutes = () => {
  const { isAuthenticated } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) {
      window.localStorage.removeItem(GAME_ELEMENT.LOGGED_IN_USER);
      navigate(ROUTE_PATHS.ROOT + ROUTE_PATHS.LANDING);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return (
    <Routes>
      <Route path={ROUTE_PATHS.WILDCARD} element={<HomePage />} />
      <Route path={ROUTE_PATHS.ROOT + ROUTE_PATHS.HOME + ROUTE_PATHS.WILDCARD} element={<HomePage />} />
      <Route path={ROUTE_PATHS.EDIT_PROFILE} element={<EditProfilePage />} />
      <Route path={ROUTE_PATHS.SETUP_PROFILE} element={<SetupProfilePage />} />
      <Route path={ROUTE_PATHS.EXERCISE} element={<ExercisePage />} />
    </Routes>
  );
};
