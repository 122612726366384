import { Box } from '@mui/material';
import { DragCriteriaContent, ItemTypes } from './CriteriaItem';
import { SxProps, Theme } from '@mui/material/styles';
import { useDrop } from 'react-dnd';

interface DropZoneAreaProps {
  onDropped: (item: string) => void;
  style?: SxProps<Theme>;
}

export const DropZone = ({ onDropped, style }: DropZoneAreaProps) => {
  //Setup drop area
  const [collectedProps, drop] = useDrop<DragCriteriaContent, void, { isOver: boolean; canDrop: boolean }>({
    accept: ItemTypes.Criteria,
    drop: (item) => onDropped(item.criteria),
  });

  return <Box ref={drop} sx={{ ...style }}></Box>;
};
