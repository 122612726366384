import { Box, SxProps, Theme } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import GamePath from './GamePath';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATHS } from 'Routes/RouteKeys';
import {
  Achievements,
  Badge,
  GameProgress,
  useAchievementsLazyQuery,
  useGameProgressQuery,
  useStartLessonWithExerciseImagesMutation,
} from 'graphql/generated/graphql';
import ShowSnack from 'Util/SnackbarUtilsConfigurator';
import AppModal, { AppModalType } from 'Components/General/AppModal';
import { EMPTY_STRING, GAME_ELEMENT } from 'Constants/keys';
import AchievementSound from 'Assets/Sounds/Achievement.wav';
import useSound from 'use-sound';

export interface GameState {
  unitId?: number;
  topicId?: number;
  lessonId?: string;
  exerciseId?: string;
  exerciseImages?: string;
}

import SplashContext from 'Context/SplashContext';
import LoadingView from 'Components/General/LoadingView';
import { useAuth0 } from '@auth0/auth0-react';
import { presignUrl } from 'Util/presigner';
import LessonCompletedToday from 'Util/DateFunctions';
import ErrorScreen from 'Screens/ErrorScreen';
import { NetworkStatus } from '@apollo/client';

export interface GameflowProps {
  style?: SxProps<Theme>;
  // The zIndex to apply to the first topic circle.
  firstTopicZIndex?: number;
  // Whether to show the onboarding arrow below the first topic circle.
  showFirstTopicArrow?: boolean;
}

export default function Gameflow({ style, firstTopicZIndex, showFirstTopicArrow }: GameflowProps) {
  const { getAccessTokenSilently } = useAuth0();
  const timezoneIdentifier = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const navigate = useNavigate();
  const [startLesson] = useStartLessonWithExerciseImagesMutation({});

  const [achievements, setAchievements] = useState<Achievements>();
  const [unseenBadges, setUnseenBadges] = useState<Badge[]>([]);
  const [unlockedBadge, setUnlockedBadge] = useState<Badge>();

  // Control to show/hide badge unlocked modal
  const [badgeModalOpen, setBadgeModalOpen] = useState(false);

  // Control to show/hide streak continued modal
  const [streakModalOpen, setStreakModalOpen] = useState(false);

  // Tracks if an error has occurred in a useEffect hook.
  const [gameflowError, setGameflowError] = useState<string>(EMPTY_STRING);

  const splashContext = useContext(SplashContext);

  const {
    data: gameProgressData,
    loading: gameLoading,
    error: gameError,
    refetch: refetchGame,
    networkStatus: gameNetworkStatus,
  } = useGameProgressQuery({ fetchPolicy: 'network-only', notifyOnNetworkStatusChange: true });

  const [achievementsQuery, { data: achievementsData, error: achievementsError, refetch: refetchAchievements }] =
    useAchievementsLazyQuery({
      fetchPolicy: 'network-only',
      variables: { timezoneOffset: timezoneIdentifier },
      notifyOnNetworkStatusChange: true,
    });

  const [gameProgress, setGameProgress] = useState<GameProgress>();

  //Get Game Progress, followed by Achievements
  useEffect(() => {
    if (!gameProgressData) return;
    if (gameProgressData.gameProgress) {
      setGameProgress(gameProgressData.gameProgress);
    }
    achievementsQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameProgressData]);

  //When Achievements Query runs to completion, set Achievements
  useEffect(() => {
    if (!achievementsData) return;
    if (!achievementsData.achievements) return;
    console.log(achievementsData);
    setAchievements(achievementsData.achievements);
  }, [achievementsData]);

  //When achievements set,
  useEffect(() => {
    if (!achievements) return;

    const newBadges = achievements.badges.filter((x) => x.seen === false);
    if (newBadges.length > 0 && newBadges.length !== unseenBadges?.length) {
      setUnseenBadges(newBadges);
      setUnlockedBadge(newBadges[0]);
    }

    try {
      if (
        achievements.shouldShowStreakPopUp &&
        achievements.latestLessonCompletionTime &&
        LessonCompletedToday(achievements.latestLessonCompletionTime)
      ) {
        // If the user hasn't seen the streak popup, and they completed the latest lesson today,
        // show the popup.
        console.log('Opening streak modal');
        setStreakModalOpen(true);
      } else {
        // Otherwise, close the popup.
        setStreakModalOpen(false);
      }
    } catch (error) {
      setGameflowError('Failed to parse date.');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [achievements]);

  useEffect(() => {
    if (!unlockedBadge) return;
    playAchievementSound();
    if (!badgeModalOpen) {
      setBadgeModalOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unlockedBadge]);

  const generatePresignedImageUrls = async (images: string[]): Promise<Map<string, string>> => {
    const token = await getAccessTokenSilently();
    const exImagesMap = new Map<string, string>();
    for (const image of images) {
      const signedUrl = await presignUrl(image, token);
      exImagesMap.set(image, signedUrl);
    }

    return exImagesMap;
  };

  const [playAchievementSound] = useSound(AchievementSound);

  const handleBadgeSeen = () => {
    if (unseenBadges?.length !== 1) {
      unseenBadges?.shift();
      console.log(`Showing next badge ${unseenBadges[0]}`);
      setUnlockedBadge(unseenBadges[0]);
    } else {
      setBadgeModalOpen(false);
    }
  };

  if (gameError || achievementsError || gameflowError) {
    const errorMessage = gameError?.message || achievementsError?.message || gameflowError;

    return (
      <ErrorScreen
        message={errorMessage}
        onRetry={() => {
          setGameflowError(EMPTY_STRING);
          refetchGame();
          refetchAchievements();
        }}
      />
    );
  }

  return (
    <Box
      sx={{
        width: 1,
        alignItems: 'center',
        overflow: 'auto',
        ...style,
      }}
      key="{exm-gameflow-path-box}"
    >
      {gameLoading || gameNetworkStatus === NetworkStatus.refetch ? (
        <LoadingView />
      ) : (
        <>
          <GamePath
            key="exm-gameflow-path"
            gameProgress={gameProgress}
            onTopicClicked={async (unitId, topicId) => {
              splashContext?.setShowSplash(true);
              await startLesson({
                variables: {
                  unitId: unitId,
                  topicId: topicId,
                },
              })
                .then(async (response) => {
                  if (response?.data?.startLessonWithExerciseImages) {
                    const responseObj = response?.data?.startLessonWithExerciseImages;
                    const lessonId = responseObj.lessonId;
                    const gameState = {} as GameState;
                    gameState.unitId = unitId;
                    gameState.topicId = topicId;
                    gameState.lessonId = lessonId;
                    const presignedImages = await generatePresignedImageUrls(responseObj.exerciseImages);
                    gameState.exerciseImages = JSON.stringify(Array.from(presignedImages.entries()));
                    window.localStorage.setItem(GAME_ELEMENT.GAME_STATE, JSON.stringify(gameState));
                    splashContext?.setShowSplash(false);
                    navigate(ROUTE_PATHS.PRIVATE_ROOT + ROUTE_PATHS.EXERCISE);
                  }
                })
                .catch((error) => {
                  splashContext?.setShowSplash(false);
                  console.log(error.message);
                  ShowSnack.error(error.message);
                });
            }}
            firstTopicZIndex={firstTopicZIndex}
            showFirstTopicArrow={showFirstTopicArrow}
          />
          <AppModal
            disableDismiss
            open={badgeModalOpen}
            badge={unlockedBadge}
            onPositiveAction={() => {
              handleBadgeSeen();
            }}
            onClose={() => {
              setBadgeModalOpen(false);
            }}
            modalType={AppModalType.Achievement}
          />
          <AppModal
            disableDismiss
            open={streakModalOpen}
            currentStreakDays={achievements?.currentStreak}
            onPositiveAction={() => {
              setStreakModalOpen(false);
            }}
            onClose={() => {
              setStreakModalOpen(false);
            }}
            modalType={AppModalType.Streak}
          />
        </>
      )}
    </Box>
  );
}
