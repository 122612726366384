import { Paper, Stack, Typography, useTheme } from '@mui/material';
import { ACHIEVEMENT_TEXTS } from 'Constants/keys';
import PointsDisplay from './PointsDisplay';
import StreakDisplay from './StreakDisplay';

export interface AchievementPanelProps {
  achievementType: 'points' | 'streak';
  achievementCount: number;
  // If achievementType is streak, lessonCompletedToday must be provided.
  lessonCompletedToday?: boolean;
}

// The achievement panel shows a number indicating the user's current progress in a particular
// achievement type.
const AchievementPanel = ({
  achievementType: type,
  achievementCount: count,
  lessonCompletedToday,
}: AchievementPanelProps) => {
  if (type === 'streak' && lessonCompletedToday === undefined) {
    throw Error('Streak panel must define lessonCompletedToday');
  }

  let countDisplay;

  if (type === 'points') {
    countDisplay = <PointsDisplay points={count} />;
  } else {
    if (lessonCompletedToday === undefined) {
      throw Error('Streak panel must define lessonCompletedToday');
    }
    countDisplay = <StreakDisplay days={count} lessonCompletedToday={lessonCompletedToday} />;
  }

  return (
    <Paper
      variant="outlined"
      sx={{
        border: 'none',
        borderRadius: 2,
        backgroundColor: 'lesson.achievementPanelBackground',
        width: 170,
        height: 220,
      }}
    >
      <Typography mt={2.5} ml={4} variant="h3">
        {type === 'points' ? ACHIEVEMENT_TEXTS.POINTS : ACHIEVEMENT_TEXTS.STREAK}
      </Typography>
      <Stack mt={6} mb={4} spacing={5} alignItems={'center'}>
        {countDisplay}
        <Typography
          width={143}
          height={54}
          variant="caption"
          align="center"
          letterSpacing="0.96px"
          lineHeight="14px"
          textTransform="uppercase"
        >
          {type === 'points'
            ? ACHIEVEMENT_TEXTS.POINTS_DESCRIPTION
            : count === 0
            ? ACHIEVEMENT_TEXTS.NO_STREAK_DESCRIPTION
            : ACHIEVEMENT_TEXTS.ONGOING_STREAK_DESCRIPTION}
        </Typography>
      </Stack>
    </Paper>
  );
};

export default AchievementPanel;

