import { GAME_ELEMENT } from 'Constants/keys';
import { Profile } from 'graphql/generated/graphql';
import { LoggedInUser } from 'Screens/Home/Tabs/Profile/ProfilePage';

// Updates the user stored in the local storage.
const UpdateLoggedInUser = (id?: string, profile?: Profile, email?: string) => {
  console.log(`UpdateLoggedInUser(${id}, ${profile}, ${email})`);
  const loggedInUser = {} as LoggedInUser;

  loggedInUser.userId = id;
  loggedInUser.profile = profile;
  loggedInUser.email = email;

  window.localStorage.setItem(GAME_ELEMENT.LOGGED_IN_USER, JSON.stringify(loggedInUser));
};

export default UpdateLoggedInUser;
