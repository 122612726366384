import { Stack, Typography } from '@mui/material';
import StyledAvatar from 'Components/Avatar/StyledAvatar';
import { BLANK_URL, DELETED_USER, DELETED_USER_DISPLAY_NAME, EMPTY_STRING, LEADERBOARD } from 'Constants/keys';
import { AvatarVariant } from 'graphql/generated/graphql';

export interface LeaderboardItemProps {
  rank: number;
  username: string;
  institution: string | null | undefined;
  points: number;
  avatarVariant: AvatarVariant;
  url?: string;
}

// A LeaderboardItem is a single row in the leaderboard.
const LeaderboardItem = ({ rank, username, institution, points, avatarVariant, url }: LeaderboardItemProps) => {
  return (
    <Stack mt={4} mb={3} ml={6} mr={7} justifyContent={'center'} display={'flex'} flexDirection={'row'} direction="row">
      <Typography
        mr={6}
        letterSpacing={'0.58px'}
        lineHeight={'2.6875rem'}
        variant="title"
        sx={{ textAlign: 'center' }}
        color="primary.main"
      >
        {rank}
      </Typography>
      <StyledAvatar
        username={username}
        avatarVariant={avatarVariant}
        url={url ?? BLANK_URL}
        style={{ width: '40', height: '40' }}
        size="sm"
      />
      {/* Create a stack for the username and institution.
      Setting the minWidth here seems to be necessary to cause an ellipsis to be shown if the text is too long.*/}
      <Stack minWidth={'10%'} spacing={1} ml={3}>
        <Typography variant="h3" fontWeight="medium" color="white.main" noWrap sx={{ textAlign: 'left' }}>
          {username.includes(DELETED_USER) ? DELETED_USER_DISPLAY_NAME : username}
        </Typography>
        <Typography
          variant="caption"
          fontStyle={'italic'}
          color="white.main"
          textTransform={'uppercase'}
          noWrap
          sx={{ textAlign: 'left' }}
        >
          {institution ?? EMPTY_STRING}
        </Typography>
      </Stack>

      <Typography
        letterSpacing={'0.18px'}
        lineHeight={'1.5rem'}
        fontSize={'1.125rem'}
        fontWeight="medium"
        color="primary.light"
        // Set minWidth here to ensure there is enough space to display the points text on a single line.
        minWidth={90}
        mt={2}
        ml={'auto'}
        sx={{ textAlign: 'right' }}
      >
        {points + LEADERBOARD.POINTS}
      </Typography>
    </Stack>
  );
};

export default LeaderboardItem;
