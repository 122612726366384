import { Box } from '@mui/material';
import OoliGIF from 'Assets/Mascots/Mascot-Loading.gif';
import { SxProps, Theme } from '@mui/material/styles';
export interface LoadingViewProps {
  style?: SxProps<Theme>;
}
const LoadingView = ({ style }: LoadingViewProps) => {
  return (
    <Box
      display="flex"
      sx={{
        height: '80vh',
        width: 1,
        alignSelf: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        ...style,
      }}
    >
      <img src={OoliGIF} style={{ alignSelf: 'center', width: 150 }} />
    </Box>
  );
};
export default LoadingView;
